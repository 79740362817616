import React, { useState, useEffect } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Nav } from "../../components/Navbar/NavbarElements";
import logo from "../../images/UA_Logo_WHT_RGB.png";
import "./access.css";
import "../../App.css";
import axios from "axios";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

export default function SignIn(props) {
	const { setToken, disabled, setDisabled, loginError } = props;
	var [isError, setIsError] = useState(false);
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");

	async function postLogin() {
		const lowerCaseUsername = username.toLowerCase();
		axios
			.post(process.env.REACT_APP_BACKEND_HOST.concat("/auth/login"), {
				username: lowerCaseUsername,
				password,
			})
			.catch(function (error) {
				console.log("Invalid User");
				console.log(error.response.data);
			})
			.then((result) => {
				if (result != null) {
					setIsError(false);
					setToken(result.data);
				} else {
					setIsError(true);
					setDisabled(false);
				}
			});
	}

	async function handleKeypress(e) {
		if (e.key === "Enter") {
			setDisabled(true);
			await postLogin();
		}
	}

	async function handleLogin() {
		setDisabled(true);
		await postLogin();
	}

	return (
		<div className="app">
			<Nav
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img src={logo} alt="UofA logo" height={50} width={183} />
			</Nav>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Container
					sx={{
						paddingTop: "100px",
						width: "500px",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							fontSize={16}
							fontWeight={600}
							fontFamily={"Roboto, sans-serif"}
						>
							Campus Computing ID
						</Typography>
						<Typography
							variant="h6"
							component="div"
							fontSize={32}
							fontWeight={700}
							fontFamily={"Roboto, sans-serif"}
						>
							UAlberta Login
						</Typography>
						<br />

						<form onKeyDown={handleKeypress}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									marginBottom: "10px",
									width: "450px",
								}}
							>
								<TextField
									type="text"
									size="small"
									autoComplete="username"
									placeholder="CCID"
									inputProps={{ style: { textAlign: "center" } }}
									InputProps={{ sx: { borderRadius: 0 } }}
									value={username}
									onChange={(e) => {
										setUserName(e.target.value);
									}}
								/>

								<TextField
									size="small"
									type="password"
									autoComplete="current-password"
									inputProps={{ style: { textAlign: "center" } }}
									InputProps={{ sx: { borderRadius: 0 } }}
									placeholder="Password"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
							</Box>

							<Button
								disabled={disabled}
								variant="contained"
								sx={{
									width: "100%",
									fontWeight: 600,
									borderRadius: 0,
								}}
								onClick={handleLogin}
							>
								{!disabled && "LOGIN"}
								{disabled && <CircularProgress size={22} />}
							</Button>
						</form>

						<br />
						<br />
						{isError && (
							<p style={{ color: "red", textAlign: "center" }}>
								The username or password provided were incorrect.
							</p>
						)}
						{loginError && loginError.length > 0 && (
							<p style={{ color: "red", textAlign: "center" }}>{loginError}</p>
						)}
						<a
							href="https://ist.ualberta.ca/content/reset-forgotten-password"
							target="_"
							className="forgot-password"
						>
							Forgot your password?
						</a>
					</Box>
					<Container className="message-box">
						<p>
							If you have any questions about your reports or how to find your information, please
							email us at{" "}
							<a href="mailto:Web.Surveys@ualberta.ca" target="_" className="ua-link">
								Web.Surveys@ualberta.ca
							</a>
						</p>
						<p>
							Access to online SPOT information is restricted in accordance with the{" "}
							<a
								href="https://policiesonline.ualberta.ca/PoliciesProcedures/Procedures/Student-Input-to-the%20Evaluation-of-Teaching-and-Learning-Procedure.pdf"
								className="ua-link"
								target="_"
							>
								UAPPOL Policy
							</a>
						</p>
					</Container>
				</Container>
			</Box>
		</div>
	);
}

SignIn.propTypes = {
	setToken: PropTypes.func.isRequired,
};
