import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import BlockItemDisplay from "../BlockItemDisplay";
import ListSelect from "../../../../../../components/Utils/ListSelect";
import { getBlockIds } from "../../../../../../components/Api/Getters";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { useContext } from "react";
import { ClassesContext } from "../../../../../../context/RequisitionContext";
import ErrorIcon from "@mui/icons-material/Error";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";
import GroupedSelect from "../GroupedListSelect";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	height: "90vh",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 4,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
};

export default function RequisitionModalBlock(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const { chosenClasses, setChosenClasses } = useContext(ClassesContext);
	const [blockIds, setBlockIds] = useState([]);
	const [checked, setChecked] = useState([]);

	useEffect(() => {
		const blockIdsArray = [];
		async function fetchBlockIds() {
			if (token) {
				const blockIds = await getBlockIds(token);
				blockIds.forEach((blockid) => {
					blockIdsArray.push(
						`${blockid.blkid}:${blockid.ctype}:${blockid.blkid3}:${blockid.baddr1} `
					);
				});
				//sort blockIdsArray by first character descending
				const blockIds3 = blockIdsArray.filter((blockid) => blockid.startsWith("3"));
				const blockIds1 = blockIdsArray.filter((blockid) => blockid.startsWith("1"));
				//sort blockIds3 desc
				blockIds3.sort((a, b) => {
					if (a[1] < b[1]) {
						return 1;
					}
					if (a[1] > b[1]) {
						return -1;
					}
					return 0;
				});
				/* blockIds3.sort(); */
				blockIds1.sort();
				blockIdsArray.length = 0;
				blockIdsArray.push(...blockIds3, ...blockIds1);
				setBlockIds(blockIdsArray);
			}
		}
		fetchBlockIds();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const handleOpen = () => {
		if (props.modifiedItems) {
			props.setOpenConfirmItemsModifiedDialog(true);
			props.setModifiedItemsOrBlocks("blocks");
		} else {
			props.setOpen(true);
			props.setBlockItems([]);
			props.setListValue("");
		}
	};

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") return;
		props.setOpen(false);
	};

	const handleChange = (event) => {
		props.setListValue(event.target.value);
		setChosenClasses([]);
		setChecked([]);
		if (
			props.chosenItems.some((item) => item.itemno >= 1 && item.itemno <= 18) &&
			event.target.value.split(":")[2] !== "3SPOT"
		) {
			props.setChosenItems([]);
			const blockid = event.target.value.split(":")[0];
			props.setBlockItems(props.allBlockItems[blockid]);
			if (event.target.value.startsWith("3")) {
				props.setBlkId3(event.target.value.split(":")[0]);
				props.setBlkId1(null);
			}
			if (event.target.value.startsWith("1")) {
				props.setBlkId1(event.target.value.split(":")[0]);
				props.setBlkId3(event.target.value.split(":")[2]);
			}
		} else if (
			props.chosenItems.some((item) => item.itemno >= 105 && item.itemno <= 122) &&
			event.target.value.split(":")[2] !== "3CSJ"
		) {
			props.setChosenItems([]);
			const blockid = event.target.value.split(":")[0];
			props.setBlockItems(props.allBlockItems[blockid]);
			if (event.target.value.startsWith("3")) {
				props.setBlkId3(event.target.value.split(":")[0]);
				props.setBlkId1(null);
			}
			if (event.target.value.startsWith("1")) {
				props.setBlkId1(event.target.value.split(":")[0]);
				props.setBlkId3(event.target.value.split(":")[2]);
			}
		} else {
			if (event.target.value.startsWith("3")) {
				props.setBlkId3(event.target.value.split(":")[0]);
				props.setBlkId1(null);
			}
			if (event.target.value.startsWith("1")) {
				props.setBlkId1(event.target.value.split(":")[0]);
				props.setBlkId3(event.target.value.split(":")[2]);
			}
			const blockid = event.target.value.split(":")[0];
			props.setBlockItems(props.allBlockItems[blockid]);
		}
	};

	return (
		<div>
			{props.blocksDisabled ? (
				<Tooltip
					title="Survey dates must be chosen before choosing blocks"
					placement="top"
					arrow
					sx={{ display: props.blocksDisabled ? "inline" : "none" }}
				>
					<span>
						<Button variant="outlined" onClick={handleOpen} disabled sx={{ width: "245px" }}>
							Choose Item Block
						</Button>
					</span>
				</Tooltip>
			) : props.modifiedItems ? (
				<Button
					variant="outlined"
					onClick={handleOpen}
					sx={{ width: "245px" }}
					color={props.modifiedItems ? "warning" : "primary"}
				>
					Choose Item Block <ErrorIcon />
				</Button>
			) : (
				<Button
					variant="outlined"
					onClick={handleOpen}
					sx={{ width: "245px" }}
					color={props.modifiedItems ? "warning" : "primary"}
				>
					Choose Item Block
				</Button>
			)}

			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							height: "10vh",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<GroupedSelect
							disabled={props.allBlockItemsLoading}
							list={blockIds}
							value={props.listValue || ""}
							onChange={handleChange}
							label="Choose Your Block"
							width="400px"
							marginTop="0px"
							marginBottom="0px"
							blockItemsStatus={props.blockItemsStatus}
						/>
					</Box>
					<Box
						sx={{
							marginTop: "5px",
							width: "100%",
							height: "80vh",
							display: "flex",
							justifyContent: "center",
							alignContent: "center",
						}}
					>
						<BlockItemDisplay
							listValue={props.listValue}
							list={props.blockItems}
							chosenBlock={props.listValue}
							setChosenItems={props.setChosenItems}
							handleClose={handleClose}
							chosenItems={props.chosenItems}
							items={props.items}
							checked={checked}
							setChecked={setChecked}
							allBlockItemsLoading={props.allBlockItemsLoading}
							refetchBlockItems={props.refetchBlockItems}
						/>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
