import styles from "../styles/info.module.css";
import Box from "@mui/material/Box";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormHelperText, FormLabel, FormControl, Switch, Stack, Typography, InputLabel } from "@mui/material";
//9 answer keys
//there are cases where multiple keys but C=0. Factor into advanced. E with weights

function ClassDropDown(props) {
	if (props.listValues.length <= 1) {
		return (
			<div>
				<Box
					sx={{
						// border: "1px dotted grey",
						// borderRadius: "25px",
						display: "flex",
						justifyContent: "center",
						alignItems: "baseline",
					}}
				>
					<div className={props.className}>
						<Typography sx={{ marginLeft: "20px" }}>{props.question}</Typography>
						<Stack direction="row" alignItems="center" sx={{ marginLeft: "0px" }}>
							{/* <Typography>One Class</Typography> */}
							<Switch checked={props.checked} onChange={props.onChangeSwitch} disabled error />
							<Typography>Multiple Classes</Typography>
						</Stack>
						<div>
							<FormControl>
								<InputLabel sx={{ marginLeft: "10px" }}>{props.inputlabel}</InputLabel>
								<Select
									disabled
									value={props.value || ""}
									name={props.name}
									error
									label={props.inputlabel}
									multiple={props.multiple}
									onChange={props.onChange}
									variant="outlined" //set width
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										minWidth: "150px",
										width: "300px",
									}}
								>
									{props.listValues.map((option) => (
										<MenuItem key={option.key} value={option.value}>
											{option.label} : {option.name}: {option.value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error sx={{ marginLeft: "30px" }}>
									No classes found. Please write class below.
								</FormHelperText>
							</FormControl>
						</div>
					</div>
				</Box>
			</div>
		);
	} else if (props.listValues.length >= 1) {
		props.listValues.forEach((element) => {
			if (element.key == "" || element.key == null) {
				props.listValues.splice(element, 1);
			}
		});
		return (
			<div>
				<Box
					sx={{
						// border: "1px dotted grey",
						// borderRadius: "25px",
						display: "flex",
						justifyContent: "center",
						alignItems: "baseline",
					}}
				>
					<div className={props.className}>
						<Typography sx={{ marginLeft: "20px" }}>{props.question}</Typography>
						<Stack direction="row" alignItems="center" sx={{ marginLeft: "0px" }}>
							{/* <Typography>One Class</Typography> */}
							<Switch checked={props.checked} onChange={props.onChangeSwitch} disabled={props.disabled} />
							<Typography>Multiple Classes</Typography>
						</Stack>
						<div>
							<FormControl>
								<InputLabel sx={{ marginLeft: "10px" }}>{props.inputlabel}</InputLabel>
								<Select
									value={props.value || ""}
									name={props.name}
									error={props.error}
									label={props.inputlabel}
									multiple={props.multiple}
									onChange={props.onChange}
									variant="outlined" //set width
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										minWidth: "150px",
										width: "300px",
									}}
								>
									{props.listValues.map((option) => (
										<MenuItem key={option.key} value={option.value}>
											{option.label} : {option.name}: {option.value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error sx={{ marginLeft: "30px" }}>
									{props.helperText}
								</FormHelperText>
							</FormControl>
						</div>
					</div>
				</Box>
			</div>
		);
	}
}

export default ClassDropDown;
