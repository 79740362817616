import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Card, CardContent } from "@mui/material";
import { TextField, Box } from "@mui/material";
import { FormControl } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { Typography } from "@mui/material";
import { TokenContext } from "../../../../../context/TokenContext";
import { getFacultyRankByEmpid, getFacultyRankByCCID } from "../../../../../components/Api/Getters";
import { postFacultyRank } from "../../../../../components/Api/Post";
import CheckIcon from "@mui/icons-material/Check";
import ListSelect from "../../../../../components/Utils/ListSelect";

export default function AddInstructorDialog(props) {
	const token = useContext(TokenContext);
	const [empid, setEmpid] = useState("");
	const [ccid, setCcid] = useState("");
	const [instructor, setInstructor] = useState([]);
	const [noInstructorFound, setNoInstructorFound] = useState(false);
	const [disableAdd, setDisableAdd] = useState(false);
	const [alreadyInClass, setAlreadyInClass] = useState(false);
	const [displaySuccess, setDisplaySuccess] = useState(false);
	const [addNewInstructor, setAddNewInstructor] = useState(false);
	const [addNewInstructorState, setAddNewInstructorState] = useState({
		ccid: "",
		empid: "",
		dept_id: "",
		name: "",
		rank: "",
	});
	const [validAddNewInstructor, setValidAddNewInstructor] = useState(false);

	const [ccidError, setCcidError] = useState(false);
	const [empidError, setEmpidError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const ccidHelperText = "CCID must contain only letters and numbers, with no spaces.";

	const [empidHelperText, setEmpidHelperText] = useState("Employee ID must be 7 digits");
	const nameHelperText = "Name must be formatted as 'Last Name, First Name'";

	const [deptId, setDeptId] = useState("");
	const errorMessage = "";

	const [instructorRole, setInstructorRole] = useState("");

	const roles = ["PI", "TA", "CC", "CO", "LA", "GL", "AI"];

	useEffect(() => {
		if (addNewInstructorState.empid.length === 7) {
			getFacultyRankByEmpid(token, addNewInstructorState.empid).then((response) => {
				if (response.length > 0) {
					setValidAddNewInstructor(false);
					setEmpidError(true);
					setEmpidHelperText("Employee ID already exists");
				} else {
					setValidAddNewInstructor(true);
				}
			});
		}
	}, [addNewInstructorState.empid, token]);

	useEffect(() => {
		if (validAddNewInstructor && addNewInstructorState.dept_id !== "") {
			postFacultyRank(token, addNewInstructorState).then((response) => {
				if (response.status === 201) {
					let tempInstructor = [];
					tempInstructor.push(response.data);
					setInstructor(tempInstructor);
					setAddNewInstructor(false);
					setNoInstructorFound(false);
					setAddNewInstructorState({
						ccid: "",
						empid: "",
						dept_id: "",
						name: "",
						rank: "",
					});
				}
			});
		}
	}, [validAddNewInstructor, addNewInstructorState, token]);

	useEffect(() => {
		if (empid.length === 7) {
			getFacultyRankByEmpid(token, empid).then((response) => {
				if (response.length > 0) {
					setInstructor(response);

					setNoInstructorFound(false);
				} else {
					setNoInstructorFound(true);
					setInstructor([]);
				}
			});
		}
		if (empid.length !== 7) {
			setInstructor([]);
		}
	}, [empid, token]);

	useEffect(() => {
		if (empid.length === 7) {
			setAddNewInstructorState((a) => ({ ...a, empid: empid }));
		}
	}, [empid]);

	useEffect(() => {
		if (ccid.length > 0) {
			setAddNewInstructorState((a) => ({ ...a, ccid: ccid }));
		}
	}, [ccid]);

	useEffect(() => {
		if (props.currentRow && props.currentRow.dept) setDeptId(props.currentRow.dept);
	}, [props.currentRow]);

	useEffect(() => {
		if (props.currentRow && props.currentRow.classid) {
			if (instructor.length > 0) {
				let tempClassIds = props.goodClassIds;
				tempClassIds.forEach((classObj) => {
					if (classObj.classid === props.currentRow.classid) {
						if (classObj.teamsize === 0) {
							setDisableAdd(false);
							setAlreadyInClass(false);
						} else {
							let empidArray = [];
							if (classObj.roles.length > 0) {
								classObj.roles.forEach((role) => {
									empidArray.push(role.empid);
								});
							}
							if (classObj.empid !== null) {
								empidArray.push(classObj.empid);
							}
							if (empidArray.includes(instructor[0].empid)) {
								setDisableAdd(true);
								setAlreadyInClass(true);
							} else {
								setDisableAdd(false);
								setAlreadyInClass(false);
							}
						}
					}
				});
			}
		}
	}, [instructor, props.classIds, props.currentRow]);

	const handleChangeInstructor = (event) => {
		setAddNewInstructorState({
			...addNewInstructorState,
			[event.target.name]: event.target.value,
		});
		//set error to false if user types in field
		if (event.target.name === "ccid") {
			setCcidError(false);
		}
		if (event.target.name === "empid") {
			setEmpidError(false);
			setEmpidHelperText("Employee ID must be 7 digits");
		}
		if (event.target.name === "name") {
			setNameError(false);
		}
	};

	const handleClose = () => {
		setNoInstructorFound(false);
		setAddNewInstructor(false);
		setEmpid("");
		setInstructor([]);
		setAddNewInstructorState({
			ccid: "",
			empid: "",
			dept_id: "",
			name: "",
			rank: "",
		});
		props.setOpen(false);
	};

	const onChangeEmpid = (event) => {
		setEmpid(event.target.value);
		setAddNewInstructor(false);
		setNoInstructorFound(false);
		setDisableAdd(false);
		setDisplaySuccess(false);
	};

	const onChangeCCID = (event) => {
		setCcid(event.target.value);
		setAddNewInstructor(false);
		setNoInstructorFound(false);
		setDisableAdd(false);
		setDisplaySuccess(false);
	};

	const handleSubmit = () => {
		if (addNewInstructorState.ccid.length === 0) {
			setCcidError(true);
		} else if (addNewInstructorState.ccid.match(/[^a-zA-Z0-9]/g)) {
			setCcidError(true);
		}
		if (addNewInstructorState.empid.length === 0 || addNewInstructorState.empid.length !== 7) {
			setEmpidError(true);
		} else if (addNewInstructorState.empid.match(/[^0-9]/g)) {
			setEmpidError(true);
		}
		if (addNewInstructorState.name.length === 0) {
			setNameError(true);
		} else if (!addNewInstructorState.name.match(/^[a-zA-Z]+([-']?[a-zA-Z]+)*,\s[a-zA-Z]+([-']?[a-zA-Z]+)*$/)) {
			setNameError(true);
		}
		if (
			addNewInstructorState.ccid.length > 0 &&
			!addNewInstructorState.ccid.match(/[^a-zA-Z0-9]/g) &&
			addNewInstructorState.empid.length === 7 &&
			!addNewInstructorState.empid.match(/[^0-9]/g) &&
			addNewInstructorState.name.length > 0 &&
			addNewInstructorState.name.match(/^[a-zA-Z'-.]+, [a-zA-Z'-.]+$/g)
		) {
			setValidAddNewInstructor(true);
			setAddNewInstructorState({
				...addNewInstructorState,
				rank: "INSTRUCTOR",
				dept_id: deptId,
				name: addNewInstructorState.name
					.split(", ")
					.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
					.join(", "),
			});
		}
	};

	const searchByCCID = () => {
		if (ccid.length === 0) {
		} else {
			getFacultyRankByCCID(token, ccid).then((response) => {
				if (response.length > 0) {
					setInstructor(response);
					setNoInstructorFound(false);
				} else {
					setNoInstructorFound(true);
					setInstructor([]);
				}
			});
		}
	};

	const handleCancelNewInstructor = () => {
		setAddNewInstructor(false);
		setAddNewInstructorState({
			ccid: "",
			empid: "",
			dept_id: "",
			name: "",
			rank: "",
		});
	};

	const handleInstructorAddToClassIDs = () => {
		let tempGoodClassIds = [...props.goodClassIds];
		let tempNoInstructorClassIds = [...props.noInstructorClassIds];
		let tempInstructor = instructor;
		let tempNoEndDates = [...props.noEndDates];
		let tempNoExamDates = [...props.noExamDates];

		tempNoInstructorClassIds.forEach((classObj) => {
			if (classObj.classid === props.currentRow.classid) {
				if (classObj.teamsize === 0 || (classObj.teamsize !== 0 && classObj.roles.length === 0)) {
					classObj.name = tempInstructor[0].name;
					classObj.empid = tempInstructor[0].empid;
					classObj.rank = instructorRole;
					classObj.teamsize = classObj.teamsize += 1;
					classObj.comb_classes = [];
					classObj.roles = [
						{
							ccid: tempInstructor[0].ccid,
							class_role: instructorRole,
							classid: props.currentRow.classid,
							empid: tempInstructor[0].empid,
							name: tempInstructor[0].name,
							term: classObj.term,
							import_status: "MANUAL",
						},
					];
					classObj.noInstructor = false;
					if (classObj.c_edate === null || classObj.c_edate === "") {
						tempNoEndDates.push(classObj);
					}
					if (classObj.examdate === null || classObj.examdate === "") {
						tempNoExamDates.push(classObj);
					}
					tempGoodClassIds.push(classObj);
				} else {
					classObj.roles.push({
						ccid: tempInstructor[0].ccid,
						class_role: instructorRole,
						classid: props.currentRow.classid,
						empid: tempInstructor[0].empid,
						name: tempInstructor[0].name,
						term: classObj.term,
						import_status: "MANUAL",
					});
					classObj.teamsize = classObj.teamsize += 1;
					classObj.noInstructor = false;

					let index = tempGoodClassIds.findIndex((r) => r.classid === props.currentRow.classid);
					if (classObj.noEndDate) {
						tempNoEndDates.push(classObj);
					}
					if (classObj.noExamDate) {
						tempNoExamDates.push(classObj);
					}
					tempGoodClassIds[index].roles = classObj.roles;
				}
			}
		});
		props.setGoodClassIds(tempGoodClassIds);
		props.setNoEndDates(tempNoEndDates);
		props.setNoExamDates(tempNoExamDates);
		props.setNoInstructorClassIds(tempNoInstructorClassIds);
		setDisableAdd(true);
		setDisplaySuccess(true);
	};

	const handleChangeRole = (event) => {
		setInstructorRole(event.target.value);
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="md">
				<FormControl component="fieldset" sx={{ p: 2 }}>
					<Typography textAlign={"center"} fontSize={18} fontWeight={500} sx={{ marginBottom: "10px" }}>
						Add Instructor
					</Typography>
				</FormControl>
				<Box
					sx={{
						display: "flex",
						p: 2,
						justifyContent: "center",
						alignContent: "baseline",
					}}
				>
					<TextField label="Enter Employee ID" onChange={onChangeEmpid} inputProps={{ maxLength: "7" }} sx={{ width: "300px" }} />
					<Typography sx={{ mx: 2, alignSelf: "center", marginBottom: "30px" }}>OR</Typography>
					<Box sx={{ width: "300px" }}>
						<TextField label="Enter Employee CCID" onChange={onChangeCCID} />
						<Button variant="outlined" sx={{ width: "100%" }} onClick={searchByCCID}>
							Search
						</Button>
					</Box>
				</Box>
				<Card
					sx={{
						minWidth: "400px",
						height: "80px",
						m: 2,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					{instructor.length > 0 && (
						<CardContent
							sx={{
								/* display: "flex", margin: "auto", gap: "20px"  */ display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<Typography variant="h6">{instructor[0].name}</Typography>
							{instructor.length > 0 && (
								<ListSelect width="100px" marginBottom="0px" marginTop="0px" value={instructorRole || ""} name="class_role" label="Role" onChange={handleChangeRole} list={roles} />
							)}
							<Button disabled={disableAdd || instructorRole === ""} variant="contained" onClick={handleInstructorAddToClassIDs} sx={{ maxHeight: "50px" }}>
								{" "}
								{alreadyInClass ? "Already in class" : "Add Instructor"}
								<Box
									sx={{
										display: displaySuccess ? displaySuccess : "none",
										height: "20px",
									}}
								>
									<CheckIcon />
								</Box>
							</Button>
						</CardContent>
					)}
					{(empid.length === 7 && noInstructorFound && (
						<CardContent
							sx={{
								display: "flex",
								margin: "auto",
								gap: "20px",
								alignItems: "baseline",
							}}
						>
							<Typography variant="h6">No instructor found</Typography>
							<Button variant="contained" onClick={() => setAddNewInstructor(!addNewInstructor)}>
								Add Instructor?
							</Button>
						</CardContent>
					)) ||
						(ccid.length > 0 && noInstructorFound && (
							<CardContent sx={{ display: "flex", gap: "20px", justifyContent: "row" }}>
								<Typography variant="h6">No instructor found</Typography>
								<Button variant="contained" onClick={() => setAddNewInstructor(!addNewInstructor)}>
									Add Instructor?
								</Button>
							</CardContent>
						))}
				</Card>
				{addNewInstructor && (
					<Card sx={{ minWidth: "400px", height: "350px", m: 2 }}>
						<CardContent
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "10px",
							}}
						>
							<TextField
								required
								error={ccidError}
								helperText={ccidHelperText}
								label="CCID"
								name="ccid"
								inputProps={{ maxLength: "15" }}
								onChange={handleChangeInstructor}
								value={addNewInstructorState.ccid || ""}
								autoComplete="off"
							/>
							<TextField
								required
								error={empidError}
								helperText={empidHelperText}
								label="Employee ID"
								inputProps={{ maxLength: "7" }}
								name="empid"
								onChange={handleChangeInstructor}
								value={addNewInstructorState.empid || ""}
								autoComplete="off"
							/>
							<TextField
								required
								error={nameError}
								helperText={nameHelperText}
								label="Name (Last Name, First Name)"
								name="name"
								onChange={handleChangeInstructor}
								value={addNewInstructorState.name || ""}
								autoComplete="off"
							/>

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									gap: "10px",
									marginTop: "13px",
								}}
							>
								<Button variant="outlined" onClick={handleSubmit}>
									Submit New Instructor
								</Button>
								<Button variant="outlined" color="error" onClick={handleCancelNewInstructor}>
									Cancel New Instructor
								</Button>
							</Box>
						</CardContent>
					</Card>
				)}

				<DialogActions>
					<Typography sx={{ color: "red" }}>{errorMessage}</Typography>
					<Button onClick={handleClose} variant="outlined" sx={{ marginRight: "8px" }}>
						Done
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
