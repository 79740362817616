import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {
	GridFooterContainer,
	GridToolbarContainer,
	GridFooter,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useContext, useState, useEffect } from "react";
import {
	RequisitionsContext,
	ClassIdsContext,
} from "../../../context/RequisitionContext";

export default function ClassSummaryTable(props) {
	const { requisitions } = useContext(RequisitionsContext);
	const { classIds } = useContext(ClassIdsContext);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		setRows(classIds);
	}, [classIds]);

	useEffect(() => {
		requisitions.forEach((requisition) => {
			let itemListString = "";
			requisition.itemList.forEach((item) => {
				itemListString += item.itemno + ",";
			});
			requisition.itemListString = itemListString.slice(0, -1);
		});
	}, [props.itemListSet, requisitions]);

	const CustomFooter = () => {
		return (
			<GridFooterContainer
				sx={{ display: "flex", justifyContent: "flex-start" }}
			>
				<Button
					variant="outlined"
					sx={{ marginLeft: "5px" }}
					onClick={handleRemoveEmptyClasses}
				>
					Remove Empty Classes
				</Button>
				<Button
					variant="outlined"
					sx={{ marginLeft: "5px" }}
					onClick={handleResetClassIDs}
				>
					Revert
				</Button>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const handleRemoveEmptyClasses = () => {
		const newClassIds = classIds.filter((classId) => {
			return classId.csize > 0;
		});
		setRows(newClassIds);
	};

	const handleResetClassIDs = () => {
		setRows(classIds);
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class Id",
			width: 70,
		},
		{
			field: "c_sdate",
			headerName: "Start Date",
			renderCell: (params) => {
				return dayjs(params.row.c_sdate).format("DD-MMM-YYYY");
			},
			width: 92,
		},
		{
			field: "c_edate",
			headerName: "End Date",
			renderCell: (params) => {
				return dayjs(params.row.c_edate).format("DD-MMM-YYYY");
			},
			width: 92,
		},
		{
			field: "examdate",
			headerName: "Exam Date",
			renderCell: (params) => {
				if (params.row.examdate === null) {
					return null;
				}
				return dayjs(params.row.examdate).format("DD-MMM-YYYY");
			},
			width: 92,
		},
		{
			field: "ctitle",
			headerName: "Title",
			flex: 1,
			width: 120,
		},
		{
			field: "term",
			headerName: "Term",
			width: 60,
		},

		{
			field: "dept",
			headerName: "Dept",
			width: 40,
		},
		{
			field: "faculty",
			headerName: "Fac",
			width: 30,
		},
		{
			field: "program",
			headerName: "Prgm",
			width: 60,
		},
		{
			field: "teamsize",
			headerName: "Tsize",
			width: 60,
		},
		{
			field: "csize",
			headerName: "CSize",
			width: 40,
		},
		{
			field: "reqnum",
			headerName: "Reqnum",
			width: 80,
		},
		{
			field: "survey_start",
			headerName: "Start",
			renderCell: (params) => {
				if (params.row.survey_start === null) {
					return null;
				}
				return dayjs(params.row.survey_start).format("DD-MMM-YYYY");
			},
			width: 92,
		},
		{
			field: "survey_end",
			headerName: "End",
			renderCell: (params) => {
				if (params.row.survey_end === null) {
					return null;
				}
				return dayjs(params.row.survey_end).format("DD-MMM-YYYY");
			},
			width: 92,
		},
		{
			field: "cls_rel",
			headerName: "Rel",
			align: "center",
			renderCell: (params) => {
				if (params.row.cls_rel === "Y") {
					return "Yes";
				} else {
					return null;
				}
			},
			width: 30,
		},
		{
			field: "cls_rel_date",
			headerName: "Rel Date",
			renderCell: (params) => {
				if (params.row.cls_rel_date) {
					if (params.row.cls_rel_date === null) {
						return null;
					} else {
						return dayjs(params.row.cls_rel_date).format("DD-MMM-YYYY");
					}
				} else {
					return null;
				}
			},
			width: 92,
		},
	];

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
			</GridToolbarContainer>
		);
	}

	return (
		<div style={{ display: "flex", height: "100%", width: "100%" }}>
			<DataGrid
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "Nne !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					borderRadius: "1px 1px 4px 4px",
				}}
				disableSelectionOnClick
				components={{ Footer: CustomFooter, Toolbar: CustomToolbar }}
				rows={rows}
				density="compact"
				slots={{}}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 100, page: 0 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.classid}
			/>
		</div>
	);
}
