import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Button  } from '@mui/material';
import { createSearchParams, useNavigate } from "react-router-dom";
// import {BsSearch} from "react-icons/bs";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';



export default function SPOTddcSearch() {
    const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
    const [loaded,setLoaded] = useState(false)
    const [terms, setTerms] = useState([])
    const [depts, setDepts] = useState([])
    const [insts, setInsts] = useState([])
    const [selectedTerms, setSelectedTerms] = useState([])
    const [selectedDepts, setSelectedDepts] = useState([])
    const [selectedInsts, setSelectedInsts] = useState([])
    const [apiFetched, setApiFetched] = useState(false)
    const [isError, setIsError] = useState(false)
    const navigate = useNavigate()


  
    function searchReports() {
        if(selectedTerms.length > 0){
            setIsError(false)
            navigate({
                pathname: "/DDCreports",
                search: createSearchParams({
                term: selectedTerms.toString(),
                dept: selectedDepts.toString(),
                empid: selectedInsts.toString()
                }).toString()
            })
        } else{
            setIsError(true)
        }
	}



    useEffect(() => {
        let url = process.env.REACT_APP_BACKEND_HOST
        async function ddc_info() {
            const response = await fetch(url.concat('/spot/ddc_info/',sessionStorage.ccid,'/'), {
                headers: { Authorization: `Token ${sessionStorage.token}` },
            });
            const apiData = await response.json();
        setApiFetched(true)
        return apiData
        }

    
        
        if (!apiFetched) {
            let apiData = [ddc_info()];
              Promise.all(apiData).then((values) => {
                let data = values[0]
                
                let term_info = []
                let dept_info = []
                let inst_info = []
                for (let i = 0; i < data.terms.length; i++){
                    term_info.push({term:data.terms[i][0],desc_term: data.terms[i][1]})
                }

                for (let i = 0; i < data.depts.length; i++){
                    dept_info.push({dept_id:data.depts[i][0],dept_name: data.depts[i][1]})
                }

                for (let i = 0; i < data.insts.length; i++){
                    inst_info.push({id:i+1, empid:data.insts[i][0], name:data.insts[i][1]})
                }
                
                setTerms(term_info.sort((a, b) => b.term - a.term))
                setDepts(dept_info.sort((a, b) => a.dept_name.localeCompare(b.dept_name)))
                setInsts(inst_info.sort((a, b) => a.name.localeCompare(b.name)))
                // setSelectedDepts(dept_info.map(item => item.dept_id))
                // setSelectedInsts(inst_info.map(item => item.empid))
              });
              setLoaded(true)
              
        }

        if (loaded && loadingBar) {
            setTimeout(() => {
                setLoadingBar(false);
            }, 3000);
        }
    },[apiFetched,loaded,loadingBar, terms, depts, insts]);

    
    const handleTermChange = (event, newValue) => {
        if (newValue.length > 0){
            const selections = newValue.map(desc_term => {
                    const matchingObject = terms.find(obj => obj.desc_term === desc_term);
                    return matchingObject ? matchingObject.term : null;
                })
            setSelectedTerms(selections)
            
        } else {
            setSelectedTerms([])
        }
    };

    const handleDeptChange = (event, newValue) => {
        if (newValue.length > 0){
            const selections = newValue.map(dept_name => {
                    const matchingObject = depts.find(obj => obj.dept_name === dept_name);
                    return matchingObject ? matchingObject.dept_id : null;
                })
            setSelectedDepts(selections)
            
        } else {
            setSelectedDepts(depts.map(item => item.dept_id))
        }
    };

    const handleInstChange = (event, newValue) => {
        if (newValue.length > 0){
            const selections = newValue.map(name => {
                    const matchingObject = insts.find(obj => obj.name.concat(' - ',obj.empid) === name);
                    return matchingObject ? matchingObject.empid : null;
                })
            setSelectedInsts(selections)
            
        } else {
            setSelectedInsts(insts.map(item => item.empid))
        }
    };

   
    return (
        <div style ={{ height: '100%', maxHeight: 'calc(100% - 160px)'}}>

        <div className='Bread Crumbs' style ={{paddingLeft: '10px',}}>
            <Breadcrumbs separator=">" style={{marginTop:'10px',marginBottom:'20px'}}>
                <Link underline="always" color='#275d38' href='/'>
                <Typography color="#275d38" sx={{display: 'flex', alignItems: 'center' }}> 
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Home
                </Typography>
                </Link>
                <Typography color="text.primary">Reporting</Typography>
                <Typography color="text.primary">Available SPOT Reports</Typography>
            </Breadcrumbs>
        </div>


        <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>

            <Typography variant='h2'>Available SPOT Reports</Typography>
            
            <Box 
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection:'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '15px',
                    }}
                    >
                    <Autocomplete freeSolo
                        multiple
                        limitTags={3}
                        onChange={handleTermChange}
                        size="small"
                        options={terms.map(item => item.desc_term)}
                        disableCloseOnSelect
                        sx={{ width: 600, paddingTop:'5px',paddingBottom:'10px'}}
                        renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="TERM" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                )}}
                        />
                        )}
                    />
                    <Autocomplete freeSolo
                        multiple
                        limitTags={2}
                        onChange={handleDeptChange}
                        size="small"
                        options={depts.map(item => item.dept_name)}
                        disableCloseOnSelect
                        sx={{ width: 600, paddingTop:'5px',paddingBottom:'10px'}}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                label="DEPARTMENT (OPTIONAL)" 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    )}}
                            />
                            )}
                    />

                    <Autocomplete freeSolo
                        multiple
                        limitTags={2}
                        onChange={handleInstChange}
                        size="small"
                        options={insts.map(item => item.name.concat(' - ',item.empid))}
                        disableCloseOnSelect
                        sx={{ width: 600,paddingTop:'5px',paddingBottom:'10px'}}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                label="INSTRUCTOR (OPTIONAL)" 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    )}}
                            />
                            )}
                    />
                    <Button variant="contained" sx = {{backgroundColor: "#275d38",fontSize: "16px", 
                        width: '100%', paddingTop:'5px', paddingBottom:'10px'}} onClick={searchReports}>
                            View Reports
                    </Button>
                    {isError && <p style={{ color: "red", textAlign: "center", paddingTop:'5px'}}>
                                    PLEASE SELECT AT LEAST ONE TERM FROM THE DROP DOWN
                                </p>}
                </Box>

        
        </div>
        </div>
    )
}