import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import LinearProgress from "@mui/material/LinearProgress";
import { Button, IconButton } from "@mui/material";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {BsSearch} from "react-icons/bs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import {rankingSorter,CustomPagination,CustomNoRowsOverlay} from "../../SecondaryFunctions";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { SearchablePDF } from "../Report Download/SearchablePDF";


const columns = [
    {field: "course", headerName: "Course", width: 400, align: "center", headerAlign: "center"},
    {field: "description", headerName: "Description", width: 500, align: "center", headerAlign: "center"},
    {field: "name", headerName: "Name", width: 250, align: "center", headerAlign: "center"},
    {field: "term", headerName: "Term", width: 150, align: "center", headerAlign: "center"},
];


export default function SPOTdeptReports() {
	const [loaded, setLoaded] = useState(false);
	const [loadingBar, setLoadingBar] = useState(true);
	const [apiFetched, setApiFetched] = useState(false);
    let [courseData, setCourseData] = useState([])
    let [filteredCourseData, setFilteredCourseData] = useState([])
    const [searchCategory,setSearchCategory] = useState("");
    let [filters,setFilters] = useState({faculty:[],department:[],acyear:[],term:[]})
    const [searchValue,setSearchValue] = useState("");
    const [searchParams] = useSearchParams();

    // const [courseName,setCourseName] = useState("");
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedIDs, setSelectedIDs] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])
    const navigate = useNavigate();
    const [openWindow, setOpenWindow] = useState(false); // Control the visibility of a dialog window
    const [checked, setChecked] = useState([true,true,true,true]);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [pdfGrouping, setPdfGrouping] = useState(1); 

    const handleCheck0 = (event) => {
        setChecked([event.target.checked, checked[1], checked[2],checked[3]]);
      };
    
    const handleCheck1 = (event) => {
        setChecked([checked[0], event.target.checked, checked[2],checked[3]]);
    };

    const handleCheck2 = (event) => {
        setChecked([checked[0], checked[1], event.target.checked,checked[3]]);
    }

    const handleCheck3 = (event) => {
        setChecked([checked[0], checked[1],checked[2], event.target.checked]);
    }


    const handlePDFgrouping = (event) => {
        setPdfGrouping(event.target.value);
    }


    // Function to open the dialog window for generating reports
    const handleClickOpen = () => {
        if (selectedCourses.length > 0){setOpenWindow(true);}
    };
    
    // Function to close the dialog window
    const handleClose = () => {
        setOpenWindow(false);
    };


    // If the the enter key is pressed
    const handleKeypress = e => {
        if (e.keyCode === 13) {
            searchBar();
        }
    }

    const ChangeSearchCategory = (event) => {
        setSearchCategory(event.target.value);
    }



/**
 * This function performs filtering on the 'courseData' array based on the 'searchValue' and 'searchCategory'.
 * The 'searchValue' is the text or value to search for, and 'searchCategory' specifies the search type (e.g., Course Title, Instructor Name, etc.).
 * The function uses conditional filtering based on the selected 'searchCategory' to perform different types of searches.
 *
 * Functionality:
 * - If 'searchCategory' is not an empty string, the function proceeds with filtering.
 * - The 'courseData' array is initially copied to the 'temp' variable for filtering.
 * - If the 'searchCategory' is "Course Title", it filters 'temp' based on items that include 'searchValue' in the 'course' property.
 * - If the 'searchCategory' is "Instructor Name", it filters 'temp' based on items that include 'searchValue' (case-insensitive) in the 'name' property.
 * - If the 'searchCategory' is "Instructor CCID", it filters 'temp' based on items that include 'searchValue' (case-insensitive) in the 'ccid' property.
 * - If the 'searchCategory' is "Instructor EMPID", it filters 'temp' based on items that have an 'empid' property equal to the numeric value of 'searchValue'.
 * - The filtered 'temp' is then set into the 'filteredCourseData' state to display the filtered results.
 *
 * Note:
 * - The function assumes that the 'courseData' array contains objects with properties like 'course', 'name', 'ccid', and 'empid'.
 * - The 'searchValue' and 'searchCategory' variables should be set appropriately to trigger the filtering process.
 * - Ensure that the 'setFilteredCourseData' state setter function is defined and passed as a prop or imported correctly.
 */
    function searchBar(){
        if (searchCategory !== ''){
            let temp = courseData
            if (searchCategory === "Course Title"){
                temp = temp.filter(item => item.course.includes(searchValue))
            } 
            if (searchCategory === "Instructor Name"){
                temp = temp.filter(item => item.name.toUpperCase().includes(searchValue))
            } 
            setFilteredCourseData(temp)
        }
    }



    // Function that is used to clear the fitlered data and put back the original data
    function handleClear(){
        setSearchValue("")
        let temp = courseData.filter(item => item.course.includes(""))
        setFilteredCourseData(temp)
    }



/**
 * This function updates the filters based on the provided parameters and then applies the updated filters.
 *
 * Functionality:
 * - The function starts by creating a shallow copy of the 'filters' object into 'updatedFilters'.
 * - If 'checked' is true, it adds the 'value' to the 'fgroup' filter group if it is not already included.
 * - If 'checked' is false, it removes the 'value' from the 'fgroup' filter group.
 * - The 'updatedFilters' object now contains the changes based on the provided parameters.
 * - The function then sets the 'updatedFilters' into the 'filters' state, updating the filters in the component's state.
 * - Finally, the function calls the 'applyFilters' function to apply the updated filters as required by the application.
 *
 * Note:
 * - The function assumes that the 'filters' state and 'setFilters' state setter function are correctly defined and initialized in the component.
 * - The 'applyFilters' function is called after updating the filters to apply the filtering logic based on the updated filters.
 * - The 'fgroup' parameter represents the filter group that the 'value' belongs to, and 'checked' indicates whether the filter is being added (true) or removed (false).
 * - Ensure that the 'applyFilters' function is defined and implemented correctly to handle the filtering logic based on the updated filters.
 */
    function updateFilters(fgroup, value, checked) {
       
        let updatedFilters = filters;
    
        if (checked) { if (!updatedFilters[fgroup].includes(value)) { 
                        updatedFilters[fgroup].push(value)}} 

        else { updatedFilters[fgroup] = updatedFilters[fgroup].filter((item) => item !== value);}
      
        setFilters(updatedFilters);
        applyFilters();
      }



/**
 * This function applies filters to the 'courseData' array based on the selected filter categories stored in the 'filters' object.
 * The function filters the data based on multiple filter categories (faculty, department, acyear, term) and then sorts the filtered data using a custom sorting function.
 *
 * Functionality:
 * - The function defines the filter categories as an array: ['faculty', 'department', 'acyear', 'term'].
 * - It initializes an empty 'filteredData' array to store the results after filtering.
 * - The function iterates through each filter category in 'filterCategories'.
 * - If a filter category has selected filter values (filters[category].length !== 0), it applies filtering to 'courseData'.
 * - If 'filteredData' is empty, it sets it to the result of filtering 'courseData' based on the selected filter values for the current category.
 * - If 'filteredData' is not empty, it further filters 'filteredData' to retain only the items that match the selected filter values for the current category.
 * - If a filter category has no selected filter values (filters[category].length === 0), it keeps the 'filteredData' unchanged for that category.
 * - After iterating through all filter categories, the 'filteredData' array contains the results of filtering based on the selected filter values in each category.
 * - If 'filteredData' is not empty, it is sorted using the 'rankingSorter' function, which sorts first by 'termNum' and then by 'ctitle'.
 * - The sorted 'filteredData' is then set into the 'filteredCourseData' state, updating the filtered data in the component.
 *
 * Note:
 * - The function assumes that the 'courseData', 'filters', 'filteredCourseData', and 'rankingSorter' (custom sorting function) are correctly defined and initialized in the component.
 * - The 'filters' object holds selected filter values for each filter category.
 * - The 'filteredCourseData' state stores the filtered data after applying filters.
 * - Ensure that the 'rankingSorter' function is correctly implemented to provide the desired sorting logic.
 * - The 'applyFilters' function should be called appropriately to apply filtering and sorting when needed.
 */
    function applyFilters(){
        const filterCategories = ['department','acyear','term']
        
        let filteredData = []
        filterCategories.forEach(category => {
            if (filters[category].length !== 0){
                let temp = courseData.filter(item => filters[category].includes(item[category]))

                if (filteredData.length === 0){filteredData = temp} 
                else {filteredData = filteredData.filter((value) => temp.includes(value))}

            } else {
             
                if (filteredData.length === 0){filteredData = courseData} 
                else {filteredData = filteredData.filter((value) => courseData.includes(value))}
            }
        })
     
        if (filteredData.length !== 0 ){
            filteredData.sort(rankingSorter("termNum", "ctitle"));
            setFilteredCourseData(filteredData)
        }
    }



	useEffect(() => {
        let url = process.env.REACT_APP_BACKEND_HOST
        if(sessionStorage.getItem("access").includes("ADMIN")) {
            url = url.concat("/spot/admin_dept_classes/")
        }else if (sessionStorage.getItem("access").includes("STD")){
            url = url.concat("/spot/std_dept_classes/")
        }else{
            url = url.concat("/spot/dept_classes/")
        }
        async function fetch_results_by_depts() {
          const response = await fetch(url.concat('(', searchParams.get('depts'), ')', "/"), {
            headers: { Authorization: `Token ${sessionStorage.token}` },
          });
          const json = await response.json();
      
          // Process the JSON data and create an array of objects with specific properties
          let apiData = [];
          let idValue = 0;
          for (let i = 0; i < json.length; i++) {
            let entry = {
              id: idValue,
              acyear: json[i].ACYEAR.trim(),
              term: json[i].DESC_TERM.trim(),
              termNum: json[i].TERM,
              faculty: json[i].FNAME.trim(),
              department: json[i].DISPLAY_NAME.trim(),
              reqnum: json[i].REQNUM,
              clsnum: json[i].CLSNUM,
              classid: json[i].CLASSID,
              course: json[i].CTITLE.trim(),
              description: json[i].DESCRIPTION,
              ctype: json[i].CTYPE,
              empid: json[i].EMPID,
              name: json[i].NAME.trim(),
            };
            apiData.push(entry);
            idValue++;
          }

          // Set a state variable to indicate that data has been fetched from the API
          setApiFetched(true);
          return apiData;
        }
      
        // Fetch data if not loaded
        if (!loaded) {
          let apiData = [fetch_results_by_depts()];
          if (apiFetched) {
            Promise.all(apiData).then((values) => {
              let data = values[0];
              data.sort(rankingSorter("termNum", "ctitle"));
              setCourseData(data);
              setFilteredCourseData(data);
            //   updateFilters("term",data[0].term,true)
              
            });
            // Mark the data as loaded to prevent repeated fetching
            setLoaded(true);
          }
        }
      
        // Show loading bar if loaded and still loading
        if (loaded && loadingBar) {
            setTimeout(() => {
                setLoadingBar(false);
            }, 3000);
        }
      }, [loaded, courseData, filteredCourseData, loadingBar, apiFetched, searchParams]);



   // function that iterates over selected rows and stores reqnum and clsnum from row data
	function getItems(items) {
        let selected_items = []
		let selected_ids = []
        let selected_courses = []
        // Iterate over selected row data
		for (let i = 0; i < items.length; i++) {

            // Filter filteredCourseData to find the entry with matching id
			let entry = filteredCourseData.filter((x) => x.id === items[i]);
            selected_items.push({
                            id: entry[0].reqnum.toString().concat('_',entry[0].clsnum.toString()),
                            term: entry[0].term,
                            course: entry[0].course,
                            empid: entry[0].empid,
                            name: entry[0].name
                        })
            selected_ids.push(entry[0].reqnum.toString().concat('_',entry[0].clsnum.toString()))
            selected_courses.push(entry[0].course)
		}
        setSelectedItems(selected_items)
        setSelectedIDs(selected_ids)
        setSelectedCourses(selected_courses)
	}


    //temporary function that will be used to generate and print repoorts
	function generateReport() {
        navigate({
            pathname: "/ReportView",
            search: createSearchParams({
              reqID: selectedIDs.toString(),
              cname: selectedCourses.toString()
            }).toString()
          })
	}


    async function PrintReport(){
        function allFalse(arr) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i] === true) {
                return false;
              }
            }
            return true;
        }

        if (!allFalse(checked)){
            if (pdfGrouping === 1){
                setPdfLoading(true)
                // console.log(selectedIDs)
                // console.log(checked)
                await SearchablePDF(selectedIDs,checked,'SPOT_Report.pdf')
                setPdfLoading(false)
            } 
            else if (pdfGrouping ===2) {
                setPdfLoading(true)
                for (let item of selectedItems) {
                   let selected_data = [item.id]
                   let filename = item.term.concat(' - ',item.course,' - ',item.name,'.pdf');
                   await SearchablePDF(selected_data, checked, filename);
                }
                setPdfLoading(false)
            }
            else if (pdfGrouping === 3){
                let courses = [...new Set(Object.values(selectedItems).map(item => item.course))];
                setPdfLoading(true)
                for (let course of courses) {
                    let selected_data = selectedItems.filter(item => item.course === course).map(item => item.id);
                    let filename = course.concat('.pdf');
                    await SearchablePDF(selected_data, checked, filename);
                  }
                setPdfLoading(false)
            }
            else if (pdfGrouping === 4){
                let empids = [...new Set(Object.values(selectedItems).map(item => item.empid))];
                setPdfLoading(true)
                for (let empid of empids) {
                    let selected_data = selectedItems.filter(item => item.empid === empid).map(item => item.id);
                    let filename = selectedItems.find(item => item.empid === empid).name.concat('.pdf');
                    await SearchablePDF(selected_data, checked, filename);
                  }
                setPdfLoading(false)
            }
        // handleClickOpen();
        }
    }



	return (
        <div style = {{ display: "flex",
                    flexDirection: "row",
                    height: '100%',
                    maxHeight: 'calc(100% - 160px)'
                    }}>
            
            <Box sx={{width: '21%', maxWidth: 400,
                // height:'auto',
                borderRight: '2px solid rgba(0, 0, 0, .5)',
                borderColor: 'rgba(0, 0, 0, 0.2)',
                paddingLeft: '10px',
                paddingRight: '10px',
                display: "flex",
                flexDirection: "column"
                }}>

                <Breadcrumbs separator=">" style={{marginTop:'10px',marginBottom:'20px'}}>
                    <Link underline="always" color='#275d38' href='/'>
                        <Typography color="#275d38" sx={{display: 'flex', alignItems: 'center' }}> 
                            <HomeIcon sx={{ mr: 0.5 }} />
                            Home
                        </Typography>
                    </Link>
                    <Typography color="text.primary">Reporting</Typography>
                    <Typography color="text.primary">SPOT Reports by Faculty/Department</Typography>
                </Breadcrumbs>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center',marginBottom:'10px'}}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-label">Search Category</InputLabel>
                        <Select
        
                        value={searchCategory}
                        label="Search Category"
                        onChange={ChangeSearchCategory}
                        >
                        <MenuItem value={"Course Title"}>Course Title</MenuItem>
                        <MenuItem value={"Instructor Name"}>Instructor Name</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center',marginBottom:'20px'}}>
                    <TextField label={searchCategory} size="small"
                        value = {searchValue}
                        onChange={(e) => {setSearchValue(e.target.value.toUpperCase())}}
                        onKeyUp = {handleKeypress}
                        sx={{width:"322px"}}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={handleClear}>
                                    {searchValue.length > 0 ? <ClearIcon/> : ''}
                                </IconButton>
                            )
                        }}
                        />
            
                    <Button 
                        variant="contained" 
                        style = {{
                            backgroundColor: "#275d38",
                            fontSize: "1.2rem",
                            marginLeft: "2px",
                        }}
                        onClick={searchBar}>
                            <BsSearch/>
                    </Button>
                </Box>

                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Department</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.department))].map((dept, index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {updateFilters("department",e.target.value,e.target.checked)}}/>} 
                            value={dept} label={dept} />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Academic Year</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.acyear))].map((acyear, index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {updateFilters("acyear",e.target.value,e.target.checked)}}/>} 
                            value={acyear} label={acyear} />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Term</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.term))].map((term, index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {updateFilters("term",e.target.value,e.target.checked)}}
                            />} 
                            value={term} label={term} />
                        ))}
                    </AccordionDetails>
                </Accordion>
                

            </Box>
            <div style={{
                paddingTop: '10px',
                width: '77%',maxWidth:'1155px',
                margin: '0 auto',
                }}>

                <DataGrid
                    getRowHeight={() => 'auto'}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    style={{ textAlign: "center" }}
                    columns={columns}
                    rows={filteredCourseData}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    checkboxSelection
                    onSelectionModelChange={(itm) => getItems(itm.sort())}
                    density={"compact"}
                    slots={{
                        Pagination: CustomPagination,
                        // LoadingOverlay: LinearProgress,
                        LoadingOverlay: CircularProgress,
                        NoRowsOverlay: CustomNoRowsOverlay
                    }}
                    // loading={loadingBar} sx={{height: '635px',width: "1375px",}}/>
                    loading={loadingBar} sx={{height: '82vh',width:'100%',zoom:.85}}/>
                    
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    marginTop: '15px'
                    // outline: '2px solid black'
                    }}>
                    <Button
                        style={{ backgroundColor: "#275d38",marginBottom: "5px",
                                width:'45%',maxWidth:'400px'}}
                        variant="contained"
                        onClick={generateReport}
                    >
                        <Typography style ={{fontSize: '16px'}}>Display Reports</Typography>
                    </Button>
                    {(!sessionStorage.getItem("access").includes("STD")) && (
                        <Button
                            style={{ backgroundColor: "#275d38",width:'45%',maxWidth:'400px'}}
                            variant="contained"
                            endIcon={<DownloadIcon />}
                            onClick={handleClickOpen}>
                            <Typography style ={{fontSize: '16px'}}>PDF Download</Typography>
                        </Button>
                    )}
                    <Dialog open={openWindow} onClose={handleClose} 
                        fullWidth={true} maxWidth={'lg'}>
                        <DialogTitle sx={{ textAlign:'center',fontWeight: 'bold'}}>
                            SPOT REPORT
                        </DialogTitle>
                        {pdfLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', 
                            alignItems: 'center', paddingBottom: '20px'}}>
                                <CircularProgress size={80}/>
                            </Box>
                        ):(
                            <div>
                            <DialogContent>
                                <Box sx={{ display: 'flex', flexDirection:'column',
                                        justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>
                                            Select which elements you would like included in the PDF report:
                                    </Typography>
                                    <br></br>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox 
                                                                        checked={checked[0]} 
                                                                        onChange={handleCheck0}
                                                                        />} 
                                            label="Standard SPOT Questions" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[1]} 
                                                                    onChange={handleCheck1}
                                                                    />} 
                                        label="Supplementary Questions" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[2]} 
                                                                    onChange={handleCheck2}
                                                                    />} 
                                        label="Summary Data" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[3]} 
                                                                    onChange={handleCheck3}
                                                                    />} 
                                        label="Comments" />
                                        <br></br>
                                        <FormControl variant="filled">
                                            <InputLabel>PDF Format</InputLabel>
                                            <Select
                                                value={pdfGrouping}
                                                label="PDF Format"
                                                onChange={handlePDFgrouping}
                                                >
                                                <MenuItem value={1}>Single PDF</MenuItem>
                                                <MenuItem value={2}>Seperate PDF's</MenuItem>
                                                <MenuItem value={3}>PDF per Course</MenuItem>
                                                <MenuItem value={4}>PDF per Instructor</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Box>
                            </DialogContent>
                        
                            <DialogActions sx={{justifyContent:'center'}}>
                                <Button onClick={handleClose}>Close</Button>
                                <Button onClick={PrintReport}>Generate</Button>
                            </DialogActions>
                            </div>
                        )}
                    </Dialog>
                </div>
            </div>
        </div>
	);
}
