import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from '@mui/material/CircularProgress';
import { Button, IconButton } from "@mui/material";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {BsSearch} from "react-icons/bs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { PDFDocument, } from 'pdf-lib';
import {rankingSorter,CustomPagination,CustomNoRowsOverlay} from "../../SecondaryFunctions";


const Row = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: theme.palette.mode === 'dark' ? '0px 2px 4px rgba(0, 0, 0, 0.4)' : '0px 2px 4px rgba(0, 0, 0, 0.2)',
    marginBottom: '3px', marginTop:'3px'
  }));

const columns = [
    {field: "course", headerName: "Course", width: 400, align: "center", headerAlign: "center"},
    {field: "description", headerName: "Description", width: 500, align: "center", headerAlign: "center"},
    {field: "term", headerName: "Term", width: 200, align: "center", headerAlign: "center"},
];


// mappings for different reference groups 
const ref_options = {'C':'All Courses', 'CS':'All Courses Similar Class Size',
                    'CL':'All Courses Similar Course Level',
                    'CSL':'All Courses Similar Course Level/Class Size', 
                    'F':'Courses in Faculty','FS':'Courses in Faculty Similar Class Size',
                    'FL':'Courses in Faculty Similar Course Level',
                    'FSL':'Courses in Faculty Similar Course Level/Class Size',
                    'D':'Courses in Department','DS':'Course in Department Similar Class Size',
                    'DL':'Courses in Department Similar Class Level',
                    'DSL':'Courses in Department Similar Class Level/Class Size'}

const empid = sessionStorage.id;
let idValue = 1;



export default function MyUsriReports() {
	// Define various state variables using the useState hook
    const [loaded, setLoaded] = useState(false); // To track if data is loaded
    const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
    const [apiFetched, setApiFetched] = useState(false); // To track API data fetching
    const [courseData, setCourseData] = useState([]); // Store fetched course data
    const [filteredCourseData, setFilteredCourseData] = useState([]); // Store filtered course data
    const [filters, setFilters] = useState({ faculty: [], department: [], acyear: [], term: [] }); // Store filter values
    const [courseName, setCourseName] = useState(""); // Store course name for search
    const [selectedData, setSelectedData] = useState([]); // Store selected row data
    const [openWindow, setOpenWindow] = useState(false); // Control the visibility of a dialog window
    const [pdfLoading, setPdfLoading] = useState(false);
   

    // Function to open the dialog window for generating reports
    const handleClickOpen = () => {
        if (selectedData.length > 0){
            setOpenWindow(true);
        }
      };
    
    // Function to close the dialog window
    const handleClose = () => {
        setOpenWindow(false);
    };


    // Function to generate and download merged PDF report
    const generateReport = async () => {
        setPdfLoading(true)
        try {
          const mergedPdf = await PDFDocument.create();
      
          for (let i = 0; i < selectedData.length; i++) {
            const url = process.env.REACT_APP_BACKEND_HOST.concat(
              "/usri/pdfreport/?req_num=",
              selectedData[i].reqnum,
              "&class_number=",
              selectedData[i].clsnum,
              "&ref_group=",
              selectedData[i].ref_group,
              "&report_type=indiv"
            );
      
            const response = await fetch(url, {
              headers: {
                Authorization: `Token ${sessionStorage.token}`,
              },
            });
      
            const data = await response.json();
            const pdfBytes = Uint8Array.from(atob(data.data), (c) => c.charCodeAt(0));
      
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
          }
      
          const mergedPdfBytes = await mergedPdf.save();
      
          const dl = document.createElement("a");
          const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
      
          dl.href = url;
          dl.download = 'USRI_Report.pdf';
          dl.click();
      
          URL.revokeObjectURL(url);
          handleClose();
        } catch (error) {
          console.error('Error merging PDFs:', error);
        }
        setPdfLoading(false)
    };
      

    // Function to handle Enter key press for course search
    const handleKeypress = e => {
        if (e.keyCode === 13) {
          searchCourse();
        }
    }


    // Function to search for courses based on course name
    function searchCourse(){
        let temp = courseData.filter(item => item.course.includes(courseName))
        setFilteredCourseData(temp)
    }


    // Function to clear search and reset filteredCourseData
    function handleClear(){
        setCourseName("")
        let temp = courseData.filter(item => item.course.includes(""))
        setFilteredCourseData(temp)
    }


    // Function to update filter values
    function updateFilters(fgroup, value, checked) {
        // Create a copy of the filters object
        let updatedFilters = filters;
      
        // Check if the item should be added to the filters
        if (checked) {
          // Add the value to the filter group if it doesn't already exist
          if (!updatedFilters[fgroup].includes(value)) {
            updatedFilters[fgroup].push(value)
          }
        } else {
          // Remove the value from the filter group if it exists
          updatedFilters[fgroup] = updatedFilters[fgroup].filter((item) => item !== value);
        }
      
        // Update the filters state with the updated filters object
        setFilters(updatedFilters);
        // Apply filters to update filteredCourseData
        applyFilters();
    }


    // Function to apply selected filters and update filteredCourseData
    function applyFilters(){
        // Array of filter categories
        const filterCategories = ['faculty','department','acyear','term']
        
        // Initialize filteredData as an empty array
        let filteredData = []

        // Iterate over each filter category
        filterCategories.forEach(category => {

            // Check if the current filter category has selected values
            if (filters[category].length !== 0){

                // Filter courseData based on the selected values of the current category
                let temp = courseData.filter(item => filters[category].includes(item[category]))

                // If filteredData is empty, assign temp to it
                if (filteredData.length === 0){
                    filteredData = temp
                } else {
                    // Intersect filteredData with temp to get common elements
                    filteredData = filteredData.filter((value) => temp.includes(value))
                }
            } else {
                // If no selected values for the current category, retain filteredData as it is 
                // or intersect it with courseData
                if (filteredData.length === 0){
                    filteredData = courseData
                } else {
                    filteredData = filteredData.filter((value) => courseData.includes(value))
                }
            }
        })
        // Check if filteredData is not empty
        if (filteredData.length !== 0 ){
            // Sort filteredData based on 'termNum' and 'ctitle' properties using the 
            // 'rankingSorter' function
            filteredData.sort(rankingSorter("termNum", "ctitle"));

            // Set the filtered course data using the updated filteredData
            setFilteredCourseData(filteredData)
        }
    }


    // useEffect hook to fetch and process data from the API
	useEffect(() => {
		let url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/usri_classes/");
		async function fetch_results_by_empid(empid) {
			const response = await fetch(url.concat(empid, "/"), 
            {headers: {Authorization: `Token ${sessionStorage.token}`},});
			const json = await response.json();

            // Process the fetched data and create an array of objects
			let apiData = [];
			for (let i = 0; i < json.length; i++) {
				let entry = {id: idValue, reqnum: json[i].REQNUM, clsnum: json[i].CLSNUM,
                            faculty: json[i].FNAME.trim(), department: json[i].DNAME.trim(),
                            description: json[i].DESCRIPTION, acyear: json[i].ACYEAR.trim(), 
                            term: json[i].DESC_TERM.trim(),termNum: json[i].TERM,
                            classid: json[i].CLASSID,course: json[i].CTITLE.trim(), 
                            ctype: json[i].CTYPE.trim(),empid: json[i].EMPID, 
                            name: json[i].NAME.trim(), report_id :json[i].REPORT_ID, 
                            ref1: json[i].REF1.trim(), ref2: json[i].REF2.trim(),  
                            ref3: json[i].REF3.trim(), ref_group: json[i].REF1.trim()};

				apiData.push(entry);
				idValue++;
			}
            // Set the state to indicate API data has been fetched
			setApiFetched(true);
			return apiData;
		}
        // Fetch data if not loaded
		if (!loaded) {
			let apiData= [fetch_results_by_empid(empid)];
			if (apiFetched) {
				Promise.all(apiData).then((values) => {
					let data = values[0];
                    // Sort the fetched data based on 'termNum' and 'ctitle'
					data.sort(rankingSorter("termNum", "ctitle"));
                    setCourseData(data)
                    setFilteredCourseData(data)
            
                    
				});
				setLoaded(true);
			}
		}
        // Show loading bar if loaded and still loading
		if (loaded && loadingBar) {
			setTimeout(() => {
				setLoadingBar(false);
			}, 3000);
		}
	}, [loaded, courseData, filteredCourseData, loadingBar, apiFetched]);


    // function that iterates over selected rows and stores row data
	function getItems(items) {
        let selected = []

        // Iterate over selected row data
		for (let i = 0; i < items.length; i++) {

            // Filter filteredCourseData to find the entry with matching id
			let entry = filteredCourseData.filter((x) => x.id === items[i]);

            // Push the entire row data to the selected array
            selected.push(entry[0])
			
		}
        // Set the selectedData state with the array of selected row data
        setSelectedData(selected)
	}

     // Function to update the 'ref_group' value for a selected row
    function setRefGroup(index,value){
        const updatedSelectedData = [...selectedData];
        updatedSelectedData[index].ref_group = value;
        setSelectedData(updatedSelectedData);
        
    }

	return (
        <div style = {{ display: "flex",
                    flexDirection: "row",
                    height: '100%',
                    maxHeight: 'calc(100% - 160px)'
                    }}>
            
            <Box sx={{width: '21%', maxWidth: 400,
                // height:'auto',
                borderRight: '2px solid rgba(0, 0, 0, .5)',
                borderColor: 'rgba(0, 0, 0, 0.2)',
                paddingLeft: '10px',
                paddingRight: '10px',
                display: "flex",
                flexDirection: "column"
                }}>

                <Breadcrumbs separator=">" style={{marginTop:'10px',marginBottom:'20px'}}>
                    <Link underline="always" color='#275d38' href='/'>
                        <Typography color="#275d38" sx={{display: 'flex', alignItems: 'center' }}> 
                            <HomeIcon sx={{ mr: 0.5 }} />
                            Home
                        </Typography>
                    </Link>
                    <Typography color="text.primary">Reporting</Typography>
                    <Typography color="text.primary">My USRI Reports</Typography>
                </Breadcrumbs>

                <Box sx={{display: 'flex', flexDirection: 'row', 
                            justifyContent: 'center',marginBottom:'20px'}}>
                    <TextField label="Course Title" size="small"
                        value = {courseName}
                        onChange={(e) => {setCourseName(e.target.value.toUpperCase())}}
                        onKeyUp = {handleKeypress}
                        sx={{width:"322px"}}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={handleClear}>
                                    {courseName.length > 0 ? <ClearIcon/> : ''}
                                </IconButton>
                            )
                        }}
                        />
            
                    <Button 
                        variant="contained" 
                        style = {{
                            backgroundColor: "#275d38",
                            fontSize: "1.2rem",
                            marginLeft: "2px",
                        }}
                        onClick={searchCourse}>
                            <BsSearch/>
                    </Button>
                </Box>

          
                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Faculty</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.faculty))].map((faculty,index) => (
                            <FormControlLabel key={index} 
                            control={<Checkbox 
                                onChange={(e) => {
                                    updateFilters("faculty",e.target.value,e.target.checked)
                                }}
                            />} 
                            value={faculty} label={faculty} />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Department</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.department))].map((dept,index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {
                                    updateFilters("department",e.target.value,e.target.checked)
                                }}
                            />} 
                            value={dept} label={dept} />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Academic Year</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.acyear))].map((acyear, index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {
                                    updateFilters("acyear",e.target.value,e.target.checked)
                                }}
                            />} 
                            value={acyear} label={acyear} />
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Term</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...new Set(courseData.map(item => item.term))].map((term, index) => (
                            <FormControlLabel key={index} control={<Checkbox 
                                onChange={(e) => {
                                    updateFilters("term",e.target.value,e.target.checked)
                                }}
                            />} 
                            value={term} label={term} />
                        ))}
                    </AccordionDetails>
                </Accordion>
                

            </Box>
            <div style={{
                paddingTop: '15px',
                width: '77%',maxWidth:'995px',
                margin: '0 auto',
                }}>

                <DataGrid
                    getRowHeight={() => 'auto'}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    style={{ textAlign: "center" }}
                    columns={columns}
                    rows={filteredCourseData}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    checkboxSelection
                    onSelectionModelChange={(itm) => getItems(itm.sort())}
                    density={"compact"}
                    slots={{
                        Pagination: CustomPagination,
                        // LoadingOverlay: LinearProgress,
                        LoadingOverlay: CircularProgress,
                        NoRowsOverlay: CustomNoRowsOverlay
                    }}

                    loading={loadingBar} 
                    sx={{height: '82vh', width:'100%',zoom:0.85}}/>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    marginTop: '30px',
                    }}>
                    <Button
                        style={{ backgroundColor: "#275d38",width:'45%',maxWidth:'400px'}}
                        variant="contained"
                        endIcon={<DownloadIcon />}
                        onClick={handleClickOpen}>
                        <Typography style ={{fontSize: '16px'}}>PDF Download</Typography>
                    </Button>
                    <Dialog open={openWindow} onClose={handleClose} 
                        fullWidth={true} maxWidth={'xl'}>
                         <DialogTitle sx={{ textAlign:'center',fontWeight: 'bold'}}>
                            USRI REPORT
                        </DialogTitle>
                        {pdfLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', 
                            alignItems: 'center', paddingBottom: '20px'}}>
                                <CircularProgress size={80}/>
                            </Box>
                        ):(
                        <div>
                            <DialogContent>
                                <Grid container direction={'row'} textAlign={'center'}>
                                    <Grid item xl={2}>
                                        <Typography variant="body1" fontWeight="bold" >
                                            TERM
                                        </Typography>
                                    </Grid >
                                    <Grid item xl={4}>
                                        <Typography variant="body1" fontWeight="bold" >
                                            COURSE
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6}>
                                        <Typography variant="body1" fontWeight="bold" >
                                            REFERENCE GROUP
                                        </Typography>
                                    </Grid>      
                                </Grid>
                                {selectedData.map((item, index) => (
                                    <Row key={index}>
                                        <Grid container direction={'row'} 
                                            textAlign={'center'} alignItems={'center'}>
                                            <Grid item xl={2}>
                                                {item.term}
                                            </Grid>
                                            <Grid item xl={4}>
                                                {item.course}
                                            </Grid>
                                            <Grid item xl={6}>
                                                <FormControl sx={{width: '80%'}} size="small">
                                                    <Select
                                                        value={item.ref_group}
                                                        onChange={(e) => {setRefGroup(index,e.target.value)}}>
                                                        {/* onChange={setRefGroup(index,value)}> */}
                                                            <MenuItem value={item.ref1}>
                                                                {ref_options[item.ref1]}
                                                            </MenuItem>
                                                            {item.ref2 !== "" && (
                                                            <MenuItem value={item.ref2}>
                                                                {ref_options[item.ref2]}
                                                            </MenuItem>
                                                            )}
                                                            {item.ref3 !== "" && (
                                                            <MenuItem value={item.ref3}>
                                                                {ref_options[item.ref3]}
                                                            </MenuItem>
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Row>
                                ))}
                            </DialogContent>
                        
                            <DialogActions sx={{justifyContent:'center'}}>
                                <Button onClick={handleClose}>Close</Button>
                                <Button onClick={generateReport}>Generate</Button>
                            </DialogActions>
                        </div>
                         )}
                    </Dialog>
                </div>
            </div>
        </div>
	);
}