//All imports happen at the top of the file
import React, { useContext, useEffect, useState } from "react";
import { TokenContext } from "../../../context/TokenContext";
import ListSelect from "../../../components/Utils/ListSelect";
import AutocompleteComponent from "../../../components/Utils/AutocompleteComponent";
import { Typography, Button, Box } from "@mui/material";
import ClassPDF from "./components/ClassPDF";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import { useTermsQuery } from "../../../Hooks/useTermsQuery";
import { useClassIdObjectsByDeptAndTerm } from "../../../Hooks/useClassIdsQuery";
import { useWebClasses } from "../../../Hooks/useClassIdsQuery";

export default function DepartmentSummary() {
	const token = useContext(TokenContext);
	const [termValue, setTermValue] = useState("");
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [webClasses, setWebClasses] = useState([]);
	const [nonWebClasses, setNonWebClasses] = useState([]);
	const [departmentInfo, setDepartmentInfo] = useState({});
	const [classesClassIdsArray, setClassesClassIdsArray] = useState([]);

	const { data: departments } = useDepartmentsQuery(token);

	const { data: terms } = useTermsQuery(token);

	const { data: classIds } = useClassIdObjectsByDeptAndTerm(
		token,
		autocompleteValue?.key,
		termValue
	);

	const { data: classesClassIdsObjects } = useWebClasses(token, termValue);

	useEffect(() => {
		if (classesClassIdsObjects) {
			const classesClassIdsArray = classesClassIdsObjects.map((classid) => classid.classid);
			setClassesClassIdsArray(classesClassIdsArray);
		}
	}, [classesClassIdsObjects]);

	useEffect(() => {
		if (autocompleteValue && autocompleteValue !== "" && termValue && termValue !== "") {
			const deptInfo = {
				deptId: autocompleteValue.key,
				deptName: autocompleteValue.display_name,
				term: termValue,
			};
			setDepartmentInfo(deptInfo);
		}
	}, [autocompleteValue, termValue]);

	useEffect(() => {
		async function processClasses() {
			classIds &&
				classIds.forEach((classId) => {
					if (classesClassIdsArray && classesClassIdsArray.length > 0) {
						if (classesClassIdsArray.includes(classId.classid)) {
							classId.webClass = true;
						} else {
							classId.webClass = false;
						}
					}
				});
			const withWebClasses = classIds
				? classIds.filter((classId) => classId.webClass === true)
				: [];
			//sort by ctitle
			withWebClasses &&
				withWebClasses.sort((a, b) => {
					if (a.ctitle < b.ctitle) {
						return -1;
					}
					if (a.ctitle > b.ctitle) {
						return 1;
					}
					return 0;
				});
			setWebClasses(withWebClasses);
			const withoutWebClasses = classIds
				? classIds.filter((classId) => classId.webClass === false)
				: [];
			withoutWebClasses &&
				withoutWebClasses.sort((a, b) => {
					if (a.ctitle < b.ctitle) {
						return -1;
					}
					if (a.ctitle > b.ctitle) {
						return 1;
					}
					return 0;
				});
			setNonWebClasses(withoutWebClasses);
		}
		processClasses();
	}, [classesClassIdsArray, classIds]);

	const handleChangeListSelect = (event) => {
		setTermValue(event.target.value);
	};

	const handlePDFInReq = () => {
		ClassPDF(webClasses, departmentInfo, "web");
	};

	const handlePDFNotInReq = () => {
		ClassPDF(nonWebClasses, departmentInfo, "not");
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "100vw",
			}}
		>
			<Typography
				variant="h6"
				textAlign={"center"}
				sx={{ marginTop: "3%", marginBottom: "3%", width: "80%" }}
			>
				Department Class Summary
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					alignItems: "center",
					marginBottom: "3%",
				}}
			>
				<AutocompleteComponent
					label="Department"
					options={departments ? departments : []}
					variant="standard"
					marginTop="0px"
					marginBottom="18px"
					value={autocompleteValue}
					setValue={setAutocompleteValue}
					inputValue={autocompleteInputValue}
					setInputValue={setAutocompleteInputValue}
				/>
				<ListSelect
					label="Term"
					list={terms}
					variant="standard"
					marginTop="0px"
					width="300px"
					onChange={handleChangeListSelect}
					value={termValue ? termValue : ""}
				/>
			</Box>
			<Box sx={{ display: "flex", gap: "10px" }}>
				<Button variant="contained" fullWidth onClick={handlePDFInReq}>
					Already In Requisition
				</Button>
				<Button variant="contained" fullWidth onClick={handlePDFNotInReq}>
					Not In Requisition
				</Button>
			</Box>
		</Box>
	);
}
