import { Modal, Box, Button } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import InstanceDialog from "./InstanceDialog";
import { TokenContext } from "../../../../context/TokenContext";
import { postClassIdsAndRoles } from "../../../../components/Api/Post";
import AddInstructorDialog from "../../../Requisition/Manual/AddRequisition/components/Dialogs/AddInstructorDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography } from "@mui/material";
import { useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "61%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
};

const prependValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function InstancedModal(props) {
	const [rows, setRows] = useState([]);
	const token = useContext(TokenContext);
	const [open, setOpen] = useState(false);
	const [openInstructorDialog, setOpenInstructorDialog] = useState(false);
	const [classForInstance, setClassForInstance] = useState({});

	useEffect(() => {
		let tempRows = [];
		tempRows.push(props.selectedRow);
		props.instancedClasses &&
			props.instancedClasses.length > 0 &&
			props.instancedClasses.forEach((instancedClass) => tempRows.push(instancedClass));
		setRows(tempRows);
	}, [props.selectedRow, props.instancedClasses, props.open]);

	const handleOpenAddInstantClass = () => {
		setOpen(true);
	};

	function getFirstDigit(classId) {
		classId = classId.toString();
		const classId0 = classId[0];
		return Number(classId0);
	}

	function getLast4DigitsOfClassId(classId) {
		classId = classId.toString();
		const classId1 = classId[1];
		const classId2 = classId[2];
		const classId3 = classId[3];
		const classId4 = classId[4];
		return Number(classId1 + classId2 + classId3 + classId4);
	}

	function getNextAvailableInstanceId(classid, existingClassIds) {
		const listOfBadDigits = [];
		const firstDigit = getFirstDigit(classid);
		listOfBadDigits.push(firstDigit);
		existingClassIds?.forEach((existingClassId) => {
			const last4DigitsExisting = getLast4DigitsOfClassId(existingClassId);
			const last4DigitsCurrent = getLast4DigitsOfClassId(classid);
			if (last4DigitsExisting === last4DigitsCurrent) {
				const firstDigit = getFirstDigit(existingClassId);
				listOfBadDigits.push(firstDigit);
			}
		});
		listOfBadDigits.sort((a, b) => a - b);
		const diff = prependValues.filter((x) => !listOfBadDigits.includes(x));
		const last4Digits = getLast4DigitsOfClassId(classid);
		const nextAvailableId = diff[0] * 10000 + last4Digits;
		return nextAvailableId;
	}

	const handleAddInstantClassSame = () => {
		const classid = props.selectedRow.classid;
		const newClassidInt = getNextAvailableInstanceId(
			props.selectedRow.classid,
			props.instancedClasses?.map((c) => c.classid)
		);
		const newClass = {
			acyear: props.selectedRow.acyear,
			c_sdate: props.selectedRow.c_sdate,
			catalog: props.selectedRow.catalog,
			cnumber: props.selectedRow.cnumber,
			comb_classes: props.selectedRow.comb_classes,
			ctype: props.selectedRow.ctype,
			dept: props.selectedRow.dept,
			description: props.selectedRow.description,
			classid: newClassidInt,
			ctitle: props.selectedRow.ctitle,
			csize: props.selectedRow.csize,
			exclude_class: props.selectedRow.exclude_class,
			faculty: props.selectedRow.faculty,
			original: classid,
			program: props.selectedRow.program,
			schedule: props.selectedRow.schedule,
			section: props.selectedRow.section,
			subject_id: props.selectedRow.subject_id,
			term: props.selectedRow.term,
			c_edate: props.selectedRow.c_edate,
			examdate: props.selectedRow.examdate,
			c_location: props.selectedRow.c_location,
			teamsize: props.selectedRow.teamsize,
			roles: [],
			new: true,
		};

		const newRoles = [];

		props.roles.forEach((role) => {
			const newRole = {
				ccid: role.ccid,
				class_role: role.class_role,
				classid: newClassidInt,
				empid: role.empid,
				name: role.name,
				term: role.term,
				import_status: "MANUAL",
			};
			newRoles.push(newRole);
		});
		newClass.roles = newRoles;
		newClass.name = newRoles[0].name;

		props.setInstancedClasses([...props.instancedClasses, newClass]);

		setRows([...rows, newClass]);
	};

	const handleAddInstantClassDifferent = () => {
		const classid = props.selectedRow.classid;
		const newClassidInt = getNextAvailableInstanceId(
			props.selectedRow.classid,
			props.instancedClasses.map((c) => c.classid)
		);

		const newClass = {
			acyear: props.selectedRow.acyear,
			c_sdate: props.selectedRow.c_sdate,
			catalog: props.selectedRow.catalog,
			cnumber: props.selectedRow.cnumber,
			comb_classes: props.selectedRow.comb_classes,
			ctype: props.selectedRow.ctype,
			dept: props.selectedRow.dept,
			description: props.selectedRow.description,
			classid: newClassidInt,
			ctitle: props.selectedRow.ctitle,
			csize: props.selectedRow.csize,
			exclude_class: props.selectedRow.exclude_class,
			faculty: props.selectedRow.faculty,
			original: classid,
			program: props.selectedRow.program,
			schedule: props.selectedRow.schedule,
			section: props.selectedRow.section,
			subject_id: props.selectedRow.subject_id,
			term: props.selectedRow.term,
			c_edate: props.selectedRow.c_edate,
			examdate: props.selectedRow.examdate,
			c_location: props.selectedRow.c_location,
			teamsize: 0,
			roles: [],
			new: true,
		};

		const oldRows = [...rows];

		setRows([...oldRows, newClass]);
		props.setInstancedClasses([...props.instancedClasses, newClass]);

		setClassForInstance(newClass);
		setOpenInstructorDialog(true);
	};

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start" }}>
				{rows && rows.length === 9 ? (
					<Button variant="contained" color="error" sx={{ marginLeft: "10px" }}>
						Max Instances Reached
					</Button>
				) : (
					<Button
						variant="contained"
						onClick={handleOpenAddInstantClass}
						sx={{ marginLeft: "10px" }}
					>
						Add Instanced Class
					</Button>
				)}
			</GridFooterContainer>
		);
	};

	async function getNewRows() {
		let listOfNewRows = [];
		props.instancedClasses.forEach((row) => {
			if (row.new) {
				listOfNewRows.push(row);
			}
		});
		return listOfNewRows;
	}
	const postClassIdsRolesMutation = useMutation(({ token, listOfNewRows }) =>
		postClassIdsAndRoles({ token, listOfNewRows })
	);

	const handleSubmit = async () => {
		const listOfNewRows = await getNewRows();

		postClassIdsRolesMutation.mutateAsync(
			{ token, listOfNewRows },
			{
				onSuccess: (data, error) => {
					props.setInstancedClasses([]);
					props.setOpen(false);
				},
			}
		);
	};

	const handleClose = (event, reason) => {
		if (reason && reason == "backdropClick") {
			return;
		} else {
			if (props.instancedClasses && props.instancedClasses.length > 0) {
				//delete all instancedClasses
				const tempInstancedClasses = [...props.instancedClasses];
				tempInstancedClasses.forEach((instancedClass) => {
					const index = rows.indexOf(instancedClass);
					const tempRows = [...rows];
					tempRows.splice(index, 1);
					setRows(tempRows);
				});
				props.setInstancedClasses([]);
			}
			props.setOpen(false);
		}
	};

	const handleDelete = (params) => {
		const index = rows.indexOf(params.row);
		const classId = params.row.classid;
		const tempInstancedClasses = [...props.instancedClasses];
		const instancedClass = tempInstancedClasses.find((c) => c.classid === classId);
		const instancedClassIndex = tempInstancedClasses.indexOf(instancedClass);
		tempInstancedClasses.splice(instancedClassIndex, 1);
		const tempRows = [...rows];
		tempRows.splice(index, 1);
		setRows(tempRows);
		props.setInstancedClasses(tempInstancedClasses);
	};

	const renderDeleteButton = (params) => {
		if (params.row.new) {
			return (
				<div>
					<Button
						variant="contained"
						color="error"
						size="small"
						onClick={() => handleDelete(params)}
					>
						Delete
					</Button>
				</div>
			);
		} else {
			return <div></div>;
		}
	};

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => "'" + role.name + "'");
		const teamName = teamNames.join(", ");
		const updatedRows = [...rows];
		const index = updatedRows.findIndex((r) => r.id === row.id);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = true;
		setRows(updatedRows);
	};

	const renderInstructorName = (params) => {
		const { row } = params;
		if (row.roles && row.roles.length === 1) {
			return (
				<strong>
					<Button>
						<Typography sx={{ marginRight: "10px" }} color="black">
							{row.name}
						</Typography>
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
			return (
				<strong>
					<Button onClick={() => handleTeamVisibility(row)}>
						<Typography sx={{ marginRight: "10px" }}>(Several)</Typography>
						<VisibilityIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
			return (
				<strong>
					<Button>
						<Typography sx={{ marginRight: "10px" }} onClick={() => handleTeamVisibility(row)}>
							{row.teamName}
						</Typography>
					</Button>
				</strong>
			);
		} else {
			return <strong></strong>;
		}
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 100,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			width: 180,
		},

		{
			field: "csize",
			headerName: "Size",
			width: 60,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 180,
			flex: 1,
			renderCell: renderInstructorName,
		},
		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "c_location",
			headerName: "Location",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "delete",
			headerName: "",
			width: 80,
			renderCell: renderDeleteButton,
		},
	];

	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box container sx={style}>
					<Box
						sx={{
							height: "100%",
							width: "100%",
							p: 2,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Box sx={{ display: "flex", width: "100%", height: "80%" }}>
							<DataGrid
								sx={{
									"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
										outline: "None !important",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
										width: "0.4em",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
										background: "#f1f1f1",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
										backgroundColor: "#888",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
										background: "#555",
									},

									borderRadius: "1px 1px 4px 4px",
								}}
								disableSelectionOnClick
								components={{
									Footer: CustomFooter /* Toolbar: CustomToolbar */,
								}}
								rows={rows}
								density="compact"
								columns={columns}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 100, page: 0 },
									},
								}}
								pageSizeOptions={[5, 10]}
								getRowId={(row) => row.classid}
							/>
						</Box>

						<Box
							sx={{
								width: "100%",
								height: "20%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{postClassIdsRolesMutation.isLoading ? (
								<Button
									variant="contained"
									sx={{
										marginTop: "5px",
										width: "60%",
										display: "flex",
									}}
								>
									<CircularProgress size={20} />
								</Button>
							) : (
								<Button
									variant="contained"
									disabled={
										props.instancedClasses?.length === 0 || postClassIdsRolesMutation.isLoading
									}
									sx={{
										marginTop: "5px",
										width: "60%",
										display: "flex",
									}}
									onClick={handleSubmit}
								>
									Confirm
								</Button>
							)}

							<Button
								variant="contained"
								color="error"
								disabled={postClassIdsRolesMutation.isLoading}
								sx={{
									marginTop: "5px",
									width: "60%",
									display: "flex",
								}}
								onClick={handleClose}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
			<InstanceDialog
				open={open}
				setOpen={setOpen}
				handleAddInstantClassSame={handleAddInstantClassSame}
				handleAddInstantClassDifferent={handleAddInstantClassDifferent}
				selectedRow={props.selectedRow}
			/>
			<AddInstructorDialog
				inInstance
				open={openInstructorDialog}
				setOpen={setOpenInstructorDialog}
				currentRow={classForInstance}
				setCurrentRow={setClassForInstance}
				instanced={true}
				rows={rows}
				setRows={setRows}
			/>
			{/*Add instructor to the role, not push right away.*/}
		</div>
	);
}
