import React from "react";
import { useEffect } from "react";

import {
	Button,
	TextField,
	Box,
	Tooltip,
	Zoom,
	Switch,
	FormControlLabel,
	Typography,
	Select,
	MenuItem,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import axios from "axios";
import { jsPDF } from "jspdf";
import _, { pull } from "lodash";
import { useNavigate } from "react-router-dom";

import styles from "./styles/info.module.css";
import Footer from "./components/Footer.js";
import QuestionText from "./components/QuestionText.js";
import QuestionDropDown from "./components/QuestionDropDown.js";
import NumKeysDropDown from "./components/NumKeysDropDown.js";

import ClassDropDown from "./components/ClassDropDown.js";
import Assign2ResultsRadio from "./components/Assign2ResultsRadio";
import Assign2InstructorInstructions from "./components/Assign2InstructorInstructions";
import AutoCompleteComponent from "./components/AutoCompleteComponent";
import AdvancedChecked from "./components/AdvancedChecked";

import { img1, img2, img3 } from "./components/images.js";

import isAuthenticated from "../../components/Utils/isAuthenticated.js";

//Save reorder sheet in O drive => NOT GOING TO BE EASY
//Create an admin panel where we can change status to "done", print out sheet, make requisitions for profs, etc.
//finish mrscor and advanced functions
//implement pickup or delivery

const doc = new jsPDF();

const courierDepts = [22, 23, 52, 100, 64, 31, 91, 35, 95];

const listOfAnswerOptions = [
	{
		value: "1",
		label: "1",
	},
	{
		value: "2",
		label: "2",
	},
	{
		value: "3",
		label: "3",
	},
	{
		value: "4",
		label: "4",
	},
	{
		value: "5",
		label: "5",
	},
	{
		value: "6",
		label: "6",
	},
	{
		value: "7",
		label: "7",
	},
	{
		value: "8",
		label: "8",
	},
	{
		value: "9",
		label: "9",
	},
	{
		value: "10",
		label: "10",
	},
	{
		value: "11",
		label: "11",
	},
	{
		value: "12",
		label: "12",
	},
	{
		value: "13",
		label: "13",
	},
	{
		value: "14",
		label: "14",
	},
	{
		value: "15",
		label: "15",
	},
	{
		value: "16",
		label: "16",
	},
	{
		value: "17",
		label: "17",
	},
	{
		value: "18",
		label: "18",
	},
	{
		value: "19",
		label: "19",
	},
	{
		value: "20",
		label: "20",
	},
];

const listOfKeys = [
	{
		value: "1",
		label: "1",
	},
	{
		value: "2",
		label: "2",
	},
	{
		value: "3",
		label: "3",
	},
	{
		value: "4",
		label: "4",
	},
	{
		value: "5",
		label: "5",
	},
	{
		value: "6",
		label: "6",
	},
	{
		value: "7",
		label: "7",
	},
	{
		value: "8",
		label: "8",
	},
	{
		value: "9",
		label: "9",
	},
];

let listClassNum = [
	{
		key: "",
		name: "",
		value: "",
		label: "",
	},
];

const inputProps = {
	variant: "outlined",
	minRows: 8,
	maxRows: 8,
	maxLength: 1000,
};

const singleOrDoubleSided = [
	{
		value: "Single sided",
		label: "Single sided",
	},
	{
		value: "Double sided",
		label: "Double sided",
	},
];

const yesOrNo = [
	{
		value: "Yes",
		label: "Yes",
	},
	{
		value: "No",
		label: "No",
	},
];

export default function Request() {
	const navigate = useNavigate();
	const auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const firstName = auth.firstName;
	const lastName = auth.lastName;
	const userDept = auth.dept_ids;
	const [state, setState] = React.useState({
		reqnum: "",
		courseTitle: "",
		deptID: "",
		program: "1",
		lqNum: "1",
		hqNum: "",
		qLen: "",
		nKeys: "",
		a: "",
		b: "",
		c: "",
		d: "",
		e: "",
		f: "",
		k: "",
		l: "",
		m: "",
		n: "",
		numOpen: "",
		docSize: "",
		instructorName: "",
		department: "",
		email1: "",
		email2: "",
		phone: "",
		address: "",
		singleSided: "",
		mrScor: "",
		assign2: "",
		formulaScoringChecked: false,
		classFromDatabase: "1",
		notes: "",
	});

	const [gpScorState, setgpScorState] = React.useState({
		valueNumKeys: "1",
		valueNumQuestions: "",
		valueNumAnswers: "5",
		valueClassNum: [],
	});

	const [assign2State, setAssign2State] = React.useState({
		valueNumBooklets: "",
		valueNumPages: "",
		valueClassNum: [],
		tsqsLoadOrNot: "",
		singleOrDouble: "Single sided",
	});

	const [mrScorState, setMrScorState] = React.useState({
		numQuestions: "",
		yesOrNo: "",
		notMultipleResponse: "",
		weightingMultiple: "",
		weightingSingle: "",
		partial: "",
		overanswering: "",
		penalties: "",
	});
	const [gpScor, setGpScor] = React.useState(false);
	const [assign2, setAssign2] = React.useState(false);
	const [mrScor, setMrScor] = React.useState(false);

	const [advancedChecked, setAdvancedChecked] = React.useState(false);
	const [examWeightsChecked, setExamWeightsChecked] = React.useState(false);

	const [gotDepartments, setGotDepartments] = React.useState(false);
	//const [gotClasses, setGotClasses] = React.useState(false);
	const [dataFilled, setDataFilled] = React.useState(false);
	//const [dataFetched, setDataFetched] = React.useState(false);
	const [gotReqNum, setGotReqnum] = React.useState(false);
	const [greaterThan, setGreaterThan] = React.useState(false);

	const [pulledReqnum, setPulledReqnum] = React.useState("");
	const [classNumList, setClassNumList] = React.useState([{}]);
	const [departments, setDepartments] = React.useState([]);
	const [departmentsDict, setDepartmentsDict] = React.useState([{ key: "", value: "", label: "" }]);

	const [weightingError, setWeightingError] = React.useState(false);
	const [assign2Error, setAssign2Error] = React.useState(false);
	const [nameError, setNameError] = React.useState(false);
	const [emailError, setEmailError] = React.useState(false);
	const [departmentError, setDepartmentError] = React.useState(false);
	const [addressError, setAddressError] = React.useState(false);
	const [numQuestionsError, setNumQuestionsError] = React.useState(false);
	const [numBookletsError, setNumBookletsError] = React.useState(false);
	const [numPagesError, setNumPagesError] = React.useState(false);
	const [numAnswersError, setNumAnswersError] = React.useState(false);
	const [numKeysError, setNumKeysError] = React.useState(false);
	const [classNumError, setClassNumError] = React.useState(false);
	const [notesError, setNotesError] = React.useState(false);
	const [yesOrNoError, setYesOrNoError] = React.useState(false);
	const [notMultipleResponseError, setNotMultipleResponseError] = React.useState(false);
	const [weightingSingleError, setWeightingSingleError] = React.useState(false);
	const [weightingMultipleError, setWeightingMultipleError] = React.useState(false);
	const [partialError, setPartialError] = React.useState(false);
	const [overansweringError, setOveransweringError] = React.useState(false);
	const [penaltiesError, setPenaltiesError] = React.useState(false);

	const [assign2HelpText, setAssign2HelpText] = React.useState("");
	const [mrScorHelpText, setMrScorHelpText] = React.useState("");
	const [nameHelpText, setNameHelpText] = React.useState(" ");
	const [emailHelpText, setEmailHelpText] = React.useState(" ");
	const [email2HelpText, setEmail2HelpText] = React.useState(" ");
	const [departmentHelpText, setDepartmentHelpText] = React.useState(" ");
	const [addressHelpText, setAddressHelpText] = React.useState(" ");
	const [numQuestionsHelpText, setNumQuestionsHelpText] = React.useState(" ");
	const [numBookletsHelpText, setNumBookletsHelpText] = React.useState(" ");
	const [numPagesHelpText, setNumPagesHelpText] = React.useState(" ");
	const [numAnswersHelpText, setNumAnswersHelpText] = React.useState(" ");
	const [numKeysHelpText, setNumKeysHelpText] = React.useState(" ");
	const [classNumHelpText, setClassNumHelpText] = React.useState(" ");
	const [notesText, setNotesText] = React.useState(
		"Enter your comments here: Omitted questions, multiple answers accepted, etc."
	);
	const [notesHelpText, setNotesHelpText] = React.useState(" ");
	const [yesOrNoHelpText, setYesOrNoHelpText] = React.useState(" ");
	const [notMultipleResponseHelpText, setNotMultipleResponseHelpText] = React.useState(" ");
	const [weightingHelpText, setWeightingHelpText] = React.useState(" ");
	const [partialHelpText, setPartialHelpText] = React.useState(" ");
	const [overansweringHelpText, setOveransweringHelpText] = React.useState(" ");
	const [penaltiesHelpText, setPenaltiesHelpText] = React.useState(" ");
	const [dropdownMultipleDisabled, setDropdownMultipleDisabled] = React.useState(false);

	useEffect(() => {
		if (token !== "") {
			getResponse(token);
			getDepartments(token);
		}
		//
		async function getResponse(token) {
			let url = process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/list_by_ccid/".concat(`${ccid}`)
			);
			const response = await fetch(url, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Token ${token}`,
				},
			});

			let output = await response.json();
			if (!dataFilled && output.length > 0) {
				Promise.all(output).then((result) => {
					setState({
						...state,
						instructorName: result[0].instructor_name.trim(),
						department: result[0].caddr2.trim(),
						deptID: result[0].dept,
						email1: result[0].email1,
						phone: result[0].phone,
						address: result[0].caddr3,
						c: "0",
						o: "",
						p: "",
						pocketflag: "",
						numOpen: 1,
						docSize: 1,
						program: 1,
						singleSided: true,
					});
				});
				setDataFilled(true);
			} else if (!dataFilled && output.length === 0) {
				setState({
					...state,
					instructorName: firstName.concat(" ").concat(lastName),
					deptID: userDept,
					email1: ccid.concat("@ualberta.ca"),
				});
				setDataFilled(true);
			}
		}
		async function getDepartments(token) {
			if (!gotDepartments) {
				let url = process.env.REACT_APP_BACKEND_HOST.concat("/scanner/departments/");
				const response = await fetch(url, {
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Token ${token}`,
					},
				});

				let output = await response.json();
				if (output.length > 0) {
					Promise.all(output).then((result) => {
						result.forEach((department) => {
							department.DNAME = department.DNAME.replace("Department of", "");
						});
						//sort by dname
						result.sort((a, b) => (a.DNAME > b.DNAME ? 1 : -1));
						setDepartments(result);
					});
					setGotDepartments(true);
				}
			} else {
				let i = 1;
				let departmentsDict = [];

				departments.forEach((department) => {
					//remove "Department of" from the beginning of each dname

					departmentsDict.push({
						key: i,
						value: department.DEPT_ID,
						label: department.DNAME.trim() + " (" + department.DEPT_ID + ")",
					});
					i++;
				});
				setDepartmentsDict(departmentsDict);
			}
		}

		if (gpScorState.valueNumKeys >= "2") {
			setState({ ...state, c: "1" });
			setGreaterThan(true);
		} else if (gpScorState.valueNumKeys === "1") {
			setState({
				...state,
				c: "0",
			});
			setGreaterThan(false);
		}
	}, [token, gpScorState.valueNumKeys, gotReqNum, departments]);

	useEffect(() => {
		if (state.deptID && state.deptID !== "") {
			getClassesFromDepartments(token, state.deptID);
		}
	}, [state.deptID]);

	useEffect(() => {
		if (state.department && state.department !== "") {
			setDepartmentError(false);
			setDepartmentHelpText(" ");
			getDepartmentID(state.department);
		}
	}, [state.department]);

	const toggleGpScor = () => {
		if (assign2 || mrScor) {
			setAssign2(false);
			setMultiple(false);
			setMrScor(false);
			setGpScor((gpScor) => !gpScor);
		} else {
			if (state.instructorName === "") {
				setNameHelpText("Please enter your name");
				setNameError(true);
			}
			if (state.department === "") {
				setDepartmentHelpText("Please enter your department");
				setDepartmentError(true);
			}
			if (state.email1 === "") {
				setEmailHelpText("Please enter your email");
				setEmailError(true);
			}
			if (state.address === "") {
				setAddressHelpText("Please enter address or 'pickup'");
				setAddressError(true);
			} else if (
				state.instructorName !== "" &&
				state.department !== "" &&
				state.email1 !== "" &&
				state.address !== ""
			) {
				setGpScor((gpScor) => !gpScor);
				getClassesFromDepartments(token, state.deptID);
			}
		}
	};

	const toggleAssign2 = () => {
		if (gpScor || mrScor) {
			setGpScor(false);
			setMrScor(false);
			setAssign2((assign2) => !assign2);
			setMultiple(false);
		} else {
			if (state.instructorName === "") {
				setNameHelpText("Please enter your name");
				setNameError(true);
			}
			if (state.department === "") {
				setDepartmentHelpText("Please enter your department");
				setDepartmentError(true);
			}
			if (state.email1 === "") {
				setEmailHelpText("Please enter your email");
				setEmailError(true);
			} else if (
				state.instructorName !== "" &&
				state.department !== "" &&
				state.email1 !== "" &&
				state.address !== ""
			) {
				setAssign2((assign2) => !assign2);
			}
		}
	};

	const toggleMrScor = () => {
		if (gpScor || assign2) {
			setGpScor(false);
			setAssign2(false);
			setMultiple(false);
			setMrScor((mrScor) => !mrScor);
		} else {
			if (state.instructorName === "") {
				setNameHelpText("Please enter your name");
				setNameError(true);
			}
			if (state.department === "") {
				setDepartmentHelpText("Please enter your department");
				setDepartmentError(true);
			}
			if (state.email1 === "") {
				setEmailHelpText("Please enter your email");
				setEmailError(true);
			} else if (
				state.instructorName !== "" &&
				state.department !== "" &&
				state.email1 !== "" &&
				state.address !== ""
			) {
				setMrScor((mrScor) => !mrScor);
			}
		}
	};

	const toggleAdvanced = () => {
		setAdvancedChecked((advancedChecked) => !advancedChecked);
	};

	const toggleExamWeights = () => {
		setExamWeightsChecked((examWeightsChecked) => !examWeightsChecked);
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setState({
			...state,
			[event.target.name]: value,
		});
		if (event.target.name === "instructorName") {
			setNameError(false);
			setNameHelpText(" ");
		}
		if (event.target.name === "email1") {
			setEmailError(false);
			setEmailHelpText(" ");
		}

		if (event.target.name === "address") {
			setAddressError(false);
			setAddressHelpText(" ");
		}

		if (event.target.name === "notes") {
			setNotesError(false);
			setNotesHelpText(" ");
		}
	};
	useEffect(() => {
		if (courierDepts.includes(state.deptID)) {
			setState({
				...state,
				address: "Courier",
			});
		}
	}, [state.deptID]);

	const handleChangeAutoComplete = (event, value) => {
		setClassNumList([]);
		if (value) {
			setState({
				...state,
				department: value.label,
				deptID: value.value,
			});
			gpScorState.valueClassNum = [];

			setNotesError(false);
			setNotesHelpText(" ");
		}
	};
	const handleGPScorChange = (event) => {
		const value = event.target.value;
		setgpScorState({ ...gpScorState, [event.target.name]: value });
		if (event.target.name === "valueNumQuestions" && value <= 250) {
			setNumQuestionsError(false);
			setNumQuestionsHelpText(" ");
		}
		if (event.target.name === "valueClassNum") {
			setClassNumError(false);
			setClassNumHelpText(" ");
		}
	};

	const [multiple, setMultiple] = React.useState(false);
	const handleChangeClassDropdown = (event) => {
		setMultiple(event.target.checked);
		setgpScorState({ ...gpScorState, valueClassNum: [] });
		setAssign2State({ ...assign2State, valueClassNum: [] });
	};

	const handleAssign2Change = (event) => {
		const value = event.target.value;
		setAssign2State({ ...assign2State, [event.target.name]: value });
		setAssign2Error(false);
		setAssign2HelpText("");
		if (event.target.name === "valueNumBooklets") {
			setNumBookletsError(false);
			setNumBookletsHelpText(" ");
		}
		if (event.target.name === "valueNumPages") {
			setNumPagesError(false);
			setNumPagesHelpText(" ");
		}
	};
	const [toggleExtraQuestion, setToggleExtraQuestion] = React.useState(false);
	const handleMrScorChange = (event) => {
		const value = event.target.value;
		setMrScorState({ ...mrScorState, [event.target.name]: value });
		if (event.target.name === "yesOrNo") {
			setYesOrNoError(false);
			/* setYesOrNoHelpText(" "); */
		}
		if (event.target.name === "notMultipleResponse") {
			setNotMultipleResponseError(false);
			/* setNotMultipleResponseHelpText(" "); */
		}
		if (event.target.name === "weighting") {
			setWeightingError(false);
			/* setWeightingHelpText(" "); */
		}
		if (event.target.name === "partial") {
			setPartialError(false);
			/* setPartialHelpText(" "); */
		}
		if (event.target.name === "overanswering") {
			setOveransweringError(false);
			/* setOveransweringHelpText(" "); */
		}
		if (event.target.name === "penalties") {
			setPenaltiesError(false);
			/* setPenaltiesHelpText(" "); */
		}
	};

	function getReqNum() {
		return new Promise((resolve, reject) => {
			axios({
				headers: {
					Authorization: `Token ${sessionStorage.getItem("token")}`,
				},
				method: "get",
				url: process.env.REACT_APP_BACKEND_HOST.concat("/scanner/fetch_reqnum/"),
			})
				.catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
						console.log("status", error.response.status);
						console.log("headers", error.response.headers);
					} else if (error.request) {
						console.log("request", error.request);
					} else {
						console.log("Error", error.message);
					}
					console.log(error.config);
					console.log("json", error.toJSON());
				})
				.then((res) => {
					if (res.data.length > 0) {
						resolve(res.data[0]);
						/* let currentReq = res.data[0];
						setPulledReqnum(currentReq);
						setGotReqnum(true); */
					}
				});
		});
	}
	/* 
		if (!gotReqNum) {
			
		}
	} */

	function getDepartmentID(department) {
		let departmentID = "";
		let queriedDept = department;
		if (queriedDept.value > 0) {
			queriedDept = queriedDept.label;
		}
		departmentsDict.forEach((departmentItem) => {
			let selected = departmentItem.label;
			if (selected) {
				let selectedTrimmed = selected.replace(/ *\([^)]*\) */g, "");
				if (selectedTrimmed === queriedDept) {
					setState({
						...state,
						deptID: departmentItem.value,
					});
				}
			}
		});
		return departmentID;
	}

	const getClassesFromDepartments = (token, deptID) => {
		axios({
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Token ${token}`,
			},
			method: "get",
			url: process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/department_classes/".concat(`${deptID}`).concat("/")
			),
		}).then((res) => {
			listClassNum = [
				{
					key: "",
					value: "",
					label: "",
					name: "",
				},
			];
			if (res.data.length > 0) {
				res.data.forEach((classFromDept) => {
					listClassNum.push({
						key: classFromDept.CLASSID,
						value: classFromDept.CLASSID,
						label: classFromDept.CTITLE,
						name: classFromDept.NAME,
					});
				});

				var labels = listClassNum.map(function (e) {
					return e.label;
				});

				labels.sort(function (a, b) {
					var aSplit = a.split(" ");
					var bSplit = b.split(" ");

					var s1 = aSplit[0];
					var s2 = bSplit[0];

					var n1 = aSplit.length === 5 ? aSplit[2] : aSplit[1];
					var n2 = bSplit.length === 5 ? bSplit[2] : bSplit[1];

					return s1 < s2 ? -1 : s1 > s2 ? 1 : n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
				});
				var sorted = [];
				labels.forEach(function (label) {
					sorted.push(
						listClassNum.find(function (e) {
							return e.label === label;
						})
					);
				});
				//remove duplicates
				sorted = sorted.filter(
					(item, index, self) => index === self.findIndex((t) => t.value === item.value)
				);

				setClassNumList(sorted);
			}
		});
	};

	const addLeadingZerosQuestions = (numQuestions) => {
		let numQuestionsString = numQuestions.toString();

		if (numQuestionsString.length === 1) {
			numQuestionsString = "00".concat(numQuestionsString);
		} else if (numQuestionsString.length === 2) {
			numQuestionsString = "0".concat(numQuestionsString);
		}
		return numQuestionsString;
	};

	const addLeadingZerosAnswers = (numAnswers) => {
		let numAnswersString = numAnswers.toString();
		if (numAnswersString.length === 1) {
			numAnswersString = "0".concat(numAnswersString);
		}
		return numAnswersString;
	};

	const getClassTitle = (classNum) => {
		let classTitle = "";
		classNumList.forEach((classItem) => {
			if (classItem.value === classNum) {
				classTitle = classItem.label;
			}
		});
		let splitTitle = classTitle.split(" ");
		if (splitTitle.length > 4) {
			splitTitle[0] = splitTitle[0].concat(splitTitle[1]);
			splitTitle.splice(1, 1);
			classTitle = splitTitle.join(" ");
		} else {
			classTitle = splitTitle.join(" ");
		}
		setState({
			...state,
			courseTitle: classTitle,
		});
		return classTitle;
	};

	const subStringCourseTitle = (courseTitle) => {
		let courseTitleString = "";
		if (courseTitle) {
			courseTitleString = courseTitle.substring(0, 16);
		} else {
			courseTitleString = "None";
		}
		return courseTitleString;
	};

	//Classes = none

	const postData = (pulledReqnum) => {
		let hqNum = addLeadingZerosQuestions(gpScorState.valueNumQuestions);
		let numAnswers = addLeadingZerosAnswers(gpScorState.valueNumAnswers);

		axios({
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			method: "post",
			url: process.env.REACT_APP_BACKEND_HOST.concat("/scanner/new_requisition/"),
			data: {
				reqnum: pulledReqnum,
				userid: ccid,
				caddr1: state.instructorName,
				accountnum: 502306,
				coursetitle: getClassTitle(gpScorState.valueClassNum[0]).substring(0, 16),
				dept: state.deptID,
				program: state.program,
				caddr2: state.department
					.trim()
					.replace(/ *\([^)]*\) */g, "")
					.substring(0, 32),
				caddr3: state.address,
				rqdate: setDate(),
				lqnum: "001",
				hqnum: hqNum,
				qlen: numAnswers,
				nkeys: gpScorState.valueNumKeys,
				a: "3",
				b: "1",
				c: state.c,
				d: "2",
				e: "0",
				f: "0",
				k: "7",
				l: "0",
				m: "0",
				n: "1",
				o: "0",
				p: "0",
				pocketflag: "1",
				initials: getInitials(state.instructorName),
				instructor_name: state.instructorName,
				email1: state.email1,
				email2: state.email2,
				phone: state.phone,
				speedcode: 84311,
				numopen: state.numOpen,
				docsize: state.docSize,
				notes: state.notes,
				status: "Received",
			},
		})
			.catch(function (error) {
				if (error.response) {
					console.log("data", error.response.data);
					console.log("status", error.response.status);
					console.log("headers", error.response.headers);
				}
				if (error.response.data.reqnum == "web request with this reqnum already exists.") {
					console.log("Web request with this reqnum already exists.");
				}
			})
			.then(postDataProcesses(pulledReqnum));
	};

	const postDataProcesses = (pulledReqnum) => {
		if (state.c === "0") {
			axios({
				headers: {
					Authorization: `Token ${token}`,
				},
				method: "post",
				url: process.env.REACT_APP_BACKEND_HOST.concat(
					"/scanner/processes/".concat(pulledReqnum).concat("/")
				),
				data: {
					reqnum: pulledReqnum,
					reportnum: 1,
					reporttype: "Itemanal",
					parameters: "",
					filename: "",
				},
			})
				.catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
						console.log("status", error.response.status);
						console.log("headers", error.response.headers);
					} else if (error.request) {
						console.log("request", error.request);
					} else {
						console.log("Error", error.message);
					}
					//console.log(error.config);
				})
				.then(
					axios({
						headers: {
							Authorization: `Token ${token}`,
						},
						method: "post",
						url: process.env.REACT_APP_BACKEND_HOST.concat(
							"/scanner/processes/".concat(pulledReqnum).concat("/")
						),
						data: {
							reqnum: pulledReqnum,
							reportnum: 2,
							reporttype: "Wrongs",
							parameters: "",
							filename: "",
						},
					})
						.catch(function (error) {
							if (error.response) {
								console.log("data", error.response.data);
								console.log("status", error.response.status);
								console.log("headers", error.response.headers);
							} else if (error.request) {
								console.log("request", error.request);
							} else {
								console.log("Error", error.message);
							}
							//console.log(error.config);
						})
						.then(
							axios({
								headers: {
									Authorization: `Token ${token}`,
								},
								method: "post",
								url: process.env.REACT_APP_BACKEND_HOST.concat(
									"/scanner/processes/".concat(pulledReqnum).concat("/")
								),
								data: {
									reqnum: pulledReqnum,
									reportnum: 3,
									reporttype: "eClass",
									parameters: "W,SR,".concat(gpScorState.valueClassNum),
									filename: "",
								},
							}).catch(function (error) {
								if (error.response) {
									console.log("data", error.response.data);
									console.log("status", error.response.status);
									console.log("headers", error.response.headers);
								} else if (error.request) {
									console.log("request", error.request);
								} else {
									console.log("Error", error.message);
								}
								console.log(error.config);
							})
						)
						.then(
							axios({
								headers: {
									Authorization: `Token ${token}`,
								},
								method: "post",
								url: process.env.REACT_APP_BACKEND_HOST.concat(
									"/scanner/processes/".concat(pulledReqnum).concat("/")
								),
								data: {
									reqnum: pulledReqnum,
									reportnum: 4,
									reporttype: "ClassList",
									parameters: "sn",
									filename: "",
								},
							}).catch(function (error) {
								if (error.response) {
									console.log("data", error.response.data);
									console.log("status", error.response.status);
									console.log("headers", error.response.headers);
								} else if (error.request) {
									console.log("request", error.request);
								} else {
									console.log("Error", error.message);
								}
								console.log(error.config);
							})
						)
						.then(navigate("/success"))
				);
		} else if (state.c === "1") {
			axios({
				headers: {
					Authorization: `Token ${token}`,
				},
				method: "post",
				url: process.env.REACT_APP_BACKEND_HOST.concat(
					"/scanner/processes/".concat(pulledReqnum).concat("/")
				),
				data: {
					reqnum: pulledReqnum,
					reportnum: "1",
					reporttype: "Itemanal",
					parameters: "",
					filename: "",
				},
			})
				.catch(function (error) {
					if (error.response) {
						console.log("data", error.response.data);
					}
				})
				.then(
					axios({
						headers: {
							Authorization: `Token ${token}`,
						},
						method: "post",
						url: process.env.REACT_APP_BACKEND_HOST.concat(
							"/scanner/processes/".concat(pulledReqnum).concat("/")
						),
						data: {
							reqnum: pulledReqnum,
							reportnum: "2",
							reporttype: "Examtab",
							parameters: "",
							filename: "",
						},
					}).catch(function (error) {
						if (error.response) {
							console.log("data", error.response.data);
						}
					})
				)
				.then(
					axios({
						headers: {
							Authorization: `Token ${token}`,
						},
						method: "post",
						url: process.env.REACT_APP_BACKEND_HOST.concat(
							"/scanner/processes/".concat(pulledReqnum).concat("/")
						),
						data: {
							reqnum: pulledReqnum,
							reportnum: "3",
							reporttype: "Wrongs",
							parameters: "",
							filename: "",
						},
					}).catch(function (error) {
						if (error.response) {
							console.log("data", error.response.data);
						}
					})
				)
				.then(
					axios({
						headers: {
							Authorization: `Token ${token}`,
						},
						method: "post",
						url: process.env.REACT_APP_BACKEND_HOST.concat(
							"/scanner/processes/".concat(pulledReqnum).concat("/")
						),
						data: {
							reqnum: pulledReqnum,
							reportnum: "4",
							reporttype: "eClass",
							parameters: "W,SR,".concat(gpScorState.valueClassNum),
							filename: "",
						},
					}).catch(function (error) {
						if (error.response) {
							console.log("data", error.response.data);
						}
					})
				)
				.then(
					axios({
						headers: {
							Authorization: `Token ${token}`,
						},
						method: "post",
						url: process.env.REACT_APP_BACKEND_HOST.concat(
							"/scanner/processes/".concat(pulledReqnum).concat("/")
						),
						data: {
							reqnum: pulledReqnum,
							reportnum: "5",
							reporttype: "ClassList",
							parameters: "sn",
							filename: "",
						},
					}).catch(function (error) {
						if (error.response) {
							console.log("data", error.response.data);
						}
					})
				)
				.then(navigate("/success"));
		}
	};

	const postAssign2Data = (pulledReqnum) => {
		let courseTitle = getClassTitle(assign2State.valueClassNum[0]);
		axios({
			headers: {
				Authorization: `Token ${token}`,
			},
			method: "post",
			url: process.env.REACT_APP_BACKEND_HOST.concat("/scanner/new_requisition/"),
			data: {
				reqnum: pulledReqnum,
				program: 4,
				rqdate: setDate(),
				coursetitle: subStringCourseTitle(courseTitle),
				instructor_name: state.instructorName,
				numopen: state.numOpen,
				docsize: state.docSize,
				singlesided: state.singleSided,
				userid: ccid,
				email1: state.email1,
				phone: state.phone,
				speedcode: 84311,
				caddr2: state.department.trim().replace(/ *\([^)]*\) */g, ""),
				caddr3: state.address,
				status: "Received",
				dept: state.deptID,
			},
		})
			.catch(function (error) {
				if (error.response) {
					console.log("data", error.response.data);
				}
			})
			.then(postAssign2DataProcesses(pulledReqnum))
			.then(navigate("/success"));
	};

	const postAssign2DataProcesses = (reqnum) => {
		let singleDouble = "";
		if (assign2State.singleOrDouble === "Single sided") {
			singleDouble = "single";
		} else if (assign2State.singleOrDouble === "Double sided") {
			singleDouble = "double";
		}
		let uploadVar = 0;
		if (assign2State.tsqsLoadOrNot === "upload") {
			uploadVar = 1;
		} else if (assign2State.tsqsLoadOrNot === "doNotUpload") {
			uploadVar = 0;
		}

		axios({
			headers: {
				Authorization: `Token ${token}`,
			},
			method: "post",

			url: process.env.REACT_APP_BACKEND_HOST.concat(
				"/scanner/processes/".concat(reqnum).concat("/")
			),
			data: {
				client: state.instructorName,
				booklets: assign2State.valueNumBooklets,
				pages: assign2State.valueNumPages,
				sided: singleDouble,
				upload: uploadVar,
				reqnum: reqnum,
				reportnum: 1,
			},
		}).catch(function (error) {
			if (error.response) {
				console.log("data", error.response.data);
			}
		});
	};

	const handleSubmit = () => {
		if (gpScor) {
			if (gpScorState.valueNumQuestions === "") {
				setNumQuestionsHelpText("Please enter a value");
				setNumQuestionsError(true);
			}
			if (gpScorState.valueNumQuestions > 250) {
				setNumQuestionsError(true);
				setNumQuestionsHelpText("Enter a number between 1 and 250");
			}
			if (
				(gpScorState.valueClassNum.length === 0 || gpScorState.valueClassNum == []) &&
				classNumList.length > 1
			) {
				setClassNumError(true);
				setClassNumHelpText("Please select a class.");
			} else if (
				(gpScorState.valueClassNum.length === 0 ||
					gpScorState.valueClassNum == [] ||
					!gpScorState.valueClassNum) &&
				classNumList.length <= 1
			) {
				setNotesError(true);
				setNotesHelpText("Please enter a class as a note.");
			} else if (
				gpScorState.valueNumQuestions > 1 &&
				gpScorState.valueNumQuestions <= 250 &&
				state.instructorName !== "" &&
				state.department !== "" &&
				state.email1 !== "" &&
				state.address !== "" &&
				(gpScorState.valueClassNum > 0 || gpScorState.valueClassNum.length > 0)
			) {
				getReqNum().then((pulledReqnum) => {
					postData(pulledReqnum);

					handlePrint(pulledReqnum);
				});
			}
		}
		if (assign2) {
			if (assign2State.tsqsLoadOrNot === "") {
				setAssign2HelpText("Please select a results delivery option");
				setAssign2Error(true);
			}
			if (assign2State.valueNumBooklets === "") {
				setNumBookletsHelpText("Please enter a value");
				setNumBookletsError(true);
			}
			if (assign2State.valueNumPages === "") {
				setNumPagesHelpText("Please enter a value");
				setNumPagesError(true);
			} else if (
				assign2State.tsqsLoadOrNot != "" &&
				state.instructorName !== "" &&
				state.department !== "" &&
				state.email1 !== ""
			) {
				setAssign2Error(false);
				setAssign2HelpText("");

				getReqNum().then((pulledReqnum) => {
					postAssign2Data(pulledReqnum);
					handlePrint(pulledReqnum);
				});

				/* setGotReqnum(false); */
			}
		}
		if (mrScor) {
			if (mrScorState.numQuestions === "") {
				setNumQuestionsHelpText("Please enter a value");
				setNumQuestionsError(true);
			}
			if (mrScorState.yesOrNo === "") {
				setYesOrNoHelpText("Please select a results delivery option");
				setYesOrNoError(true);
			}
			if (mrScorState.yesOrNo === "No" && mrScorState.notMultipleResponse === "") {
				setNotMultipleResponseHelpText("Please list single response questions");
				setNotMultipleResponseError(true);
			}
			if (mrScorState.weightingSingle === "") {
				setWeightingHelpText("Please select a weighting option");
				setWeightingSingleError(true);
			}
			if (mrScorState.weightingMultiple === "") {
				setWeightingHelpText("Please select a weighting option");
				setWeightingMultipleError(true);
			}
			if (mrScorState.partial === "") {
				setPartialHelpText("Please describe how you would like to handle partial answers.");
				setPartialError(true);
			}
			if (mrScorState.overanswering === "") {
				setOveransweringHelpText("Please describe how you would like to handle overanswering.");
				setOveransweringError(true);
			}
			if (mrScorState.penalties === "") {
				setPenaltiesHelpText("Please describe how you would like to handle penalties.");
				setPenaltiesError(true);
			}
		}
	};

	const handlePrint = (pulledReqnum) => {
		let courseTitle = "";
		if (typeof gpScorState.valueClassNum === "object" && gpScorState.valueClassNum.length > 1) {
			courseTitle = getClassTitle(gpScorState.valueClassNum[0]);
		} else if (typeof gpScorState.valueClassNum === "number") {
			courseTitle = getClassTitle(gpScorState.valueClassNum);
		}
		if (typeof assign2State.valueClassNum === "object" && assign2State.valueClassNum.length > 1) {
			courseTitle = getClassTitle(assign2State.valueClassNum[0]);
		} else if (typeof assign2State.valueClassNum === "number") {
			courseTitle = getClassTitle(assign2State.valueClassNum);
		}

		let notesPrint = "";
		if (state.notes && state.notes != "") {
			if (state.notes.length > 46) {
				if (state.notes.length > 330) {
					notesPrint = state.notes.substring(0, 250) + "...";
					notesPrint = notesPrint.replace(/(.{46})/g, "$1\n");
				} else {
					notesPrint = state.notes.replace(/(.{46})/g, "$1\n");
				}
			} else {
				notesPrint = state.notes;
			}
		} else {
			notesPrint = "None";
		}
		if (gpScor) {
			/* var img = new Image();*/
			/* img.src = "./components/ualogogreen.png";*/
			doc.addImage(img2, "PNG", 80, 275, 50, 14);
			/* var img2 = new Image();*/
			/* img2.src = "./components/tsslogogreen.png";*/
			doc.addImage(img1, "PNG", 10, 5, 25, 25);
			doc.setDrawColor("#275d38");
			doc.setFont(undefined, "bold");
			doc.text("Requisition number: " + pulledReqnum, 65, 34);
			doc.setFont(undefined, "normal");

			doc.rect(8, 37, doc.internal.pageSize.width - 16, 83);
			doc.text("Request Date: " + setDate(), 30, 50);
			doc.text("Client Name: " + state.instructorName, 30, 60);
			if (state.email2 && state.email2 != "") {
				doc.text("Emails: " + state.email1.toString() + ", " + state.email2.toString(), 30, 70);
			} else {
				doc.text("Email: " + state.email1.toString(), 30, 70);
			}

			doc.text("Phone: " + state.phone.toString(), 30, 80);

			doc.text("Department: " + state.department, 30, 90);

			doc.text("Address: " + state.address, 30, 100);
			doc.rect(8, 120, doc.internal.pageSize.width - 16, 65);
			doc.text("Number of questions: " + gpScorState.valueNumQuestions, 30, 132);
			doc.text("Number of answers per question: " + gpScorState.valueNumAnswers, 30, 143);
			doc.text("Number of answer keys: " + gpScorState.valueNumKeys, 30, 153);
			doc.text("Class: " + subStringCourseTitle(courseTitle), 30, 163);
			doc.text("ID(s): " + gpScorState.valueClassNum.toString(), 30, 173);

			doc.rect(8, 185, doc.internal.pageSize.width - 16, 50);
			doc.text("Notes: " + notesPrint, 30, 198);
			doc.rect(8, 235, doc.internal.pageSize.width - 16, 35);
			doc.text("If you have any questions or require assistance, please contact TSS", 15, 248);
			doc.text("by emailing opscan@ualberta.ca or by calling 780.492.2741.", 25, 258);
			doc.autoPrint();
			doc.save("Test Scoring Receipt " + pulledReqnum + ".pdf");
		} else if (assign2) {
			/* var img = new Image(); */
			/* img.src = "./components/ualogogreen.png"; */
			doc.addImage(img2, "PNG", 80, 275, 50, 14);
			/* var img2 = new Image(); */
			/* img2.src = "./components/tsslogogreen.png";*/
			doc.addImage(img1, "PNG", 10, 3, 25, 25);
			doc.setDrawColor("#275d38");
			doc.setFont(undefined, "bold");
			doc.text("Requisition number: " + pulledReqnum, 68, 27);
			doc.setFont(undefined, "normal");

			doc.rect(8, 32, doc.internal.pageSize.width - 16, 83);
			doc.text("Request Date: " + setDate(), 30, 45);
			doc.text("Client Name: " + state.instructorName, 30, 55);
			doc.text("Email: " + state.email1.toString(), 30, 65);
			doc.text("Phone: " + state.phone.toString(), 30, 75);

			doc.text("Department: " + state.department, 30, 85);

			doc.text("Address: " + state.address, 30, 95);
			doc.rect(8, 115, doc.internal.pageSize.width - 16, 75);
			doc.text("Number of booklets: " + assign2State.valueNumBooklets, 30, 128);
			doc.text("Number of pages per booklet: " + assign2State.valueNumPages, 30, 138);
			doc.text("Sided: " + assign2State.singleOrDouble, 30, 148);
			if (assign2State.tsqsLoadOrNot === "upload") {
				doc.text("Upload: TSS will load the exam. Please add one of us as", 30, 158);
				doc.text(" an instructor.", 30, 168);
			}
			if (assign2State.tsqsLoadOrNot === "doNotUpload") {
				doc.text("Send: TSS will email you the scanned .pdf files once", 30, 158);
				doc.text("the upload has finished", 30, 163);
			}
			doc.text("Class: " + subStringCourseTitle(courseTitle), 30, 178);
			doc.rect(8, 190, doc.internal.pageSize.width - 16, 65);
			doc.text("If you have any questions or require assistance, please contact TSS", 15, 213);
			doc.text("by emailing opscan@ualberta.ca or by calling 780.492.2741.", 25, 223);
			doc.autoPrint();
			doc.save("Test Scoring Receipt " + pulledReqnum + ".pdf");
		}
	};

	function setDate() {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + "-" + mm + "-" + dd;
		return today;
	}

	const getInitials = (name) => {
		var initials = name.match(/\b\w/g) || [];
		initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
		return initials;
	};

	const isFormFilled =
		state.instructorName &&
		state.instructorName.length > 0 &&
		state.department &&
		state.department.length > 0 &&
		state.email1 &&
		state.email1.length > 0 &&
		state.address &&
		state.address.length > 0
			? true
			: false;

	//Need to add checks for all text fields before next is pressed.
	return (
		<div className={styles.container}>
			<main className={styles.main}>
				<Box
					className={styles.mainbox}
					sx={{
						width: { sm: "100%", md: "80%", lg: "700px", xl: "800px" },
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						rowGap: "20px",
						columnGap: "10px",
						borderRadius: "25px",
						backgroundColor: "white",
						outline: "2px solid #275d38",
					}}
				>
					<Box
						sx={{
							marginTop: "20px",
						}}
					>
						<Typography component={"span"} variant="h5" sx={{ py: 20, px: 12 }}>
							Please enter your information and select your service.
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: "10px",
						}}
					>
						<Box
							sx={{
								display: "grid",
								gridTemplateColumns: "repeat(3, 1fr)",
								gap: "10px",
							}}
						>
							<TextField
								id="outlined-basic"
								required
								helperText={nameHelpText}
								label="Full Name"
								name="instructorName"
								error={nameError}
								variant="outlined"
								onChange={handleChange}
								value={state.instructorName}
							/>
							<TextField
								id="outlined-basic"
								required
								label="Primary Email"
								name="email1"
								error={emailError}
								helperText={emailHelpText}
								variant="outlined"
								onChange={handleChange}
								value={state.email1}
							/>
							<AutoCompleteComponent
								id="outlined-basic"
								required
								label="Department"
								helperText={departmentHelpText}
								error={departmentError}
								name="department"
								variant="outlined"
								options={departmentsDict}
								onChange={handleChangeAutoComplete}
								value={state.department}
							/>
							<TextField
								id="outlined-basic"
								label="Phone Number (Optional)"
								helperText=" "
								name="phone"
								variant="outlined"
								onChange={handleChange}
								value={state.phone}
							/>
							<TextField
								id="outlined-basic"
								label="Secondary Email (Optional)"
								name="email2"
								/* error={emailError} */
								helperText={email2HelpText}
								variant="outlined"
								onChange={handleChange}
								value={state.email2}
							/>

							<TextField
								id="outlined-basic"
								required
								label="Delivery Address"
								helperText={addressHelpText}
								error={addressError}
								name="address"
								variant="outlined"
								onChange={handleChange}
								value={state.address}
							/>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: "10px",
							width: "100%",
							pb: 1,
							justifyContent: "center",
						}}
					>
						<Button
							variant="contained"
							onClick={toggleGpScor}
							color="primary"
							className={isFormFilled ? "pulse" : ""}
							sx={{
								width: "20vh",
								height: "10vh",
							}}
						>
							<Typography component={"span"} variant="body2" fontSize={16}>
								Multiple Choice Exam{" "}
								<Typography variant="body2" fontSize={12}>
									(Single Answer)
								</Typography>
							</Typography>
						</Button>

						<Button
							onClick={toggleAssign2}
							variant="contained"
							color="primary"
							className={isFormFilled ? "pulse" : ""}
							sx={{
								width: "20vh",
								height: "10vh",
							}}
						>
							<Typography component={"span"} variant="body2" fontSize={16}>
								Written Exam{/*  Scan to PDF{" "} */}
								<Typography variant="body2" fontSize={12}>
									(Assign2)
								</Typography>
							</Typography>
						</Button>

						<Button
							onClick={toggleMrScor}
							disabled
							variant="contained"
							color="primary"
							sx={{
								width: "20vh",
								height: "10vh",
							}}
						>
							<Typography component={"span"} variant="body2" fontSize={16}>
								Multiple Choice Exam{" "}
								<Typography variant="body2" fontSize={12}>
									(Multiple Answer)
								</Typography>
							</Typography>
						</Button>
					</Box>
					{gpScor && (
						<Box sx={{ width: "100%" }}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: 2,
									width: "100%",
								}}
							>
								<QuestionText
									tooltip
									tooltipText="This is usually the number of questions on your exam, however in some cases the highest question number and total number of questions differ (e.g. the back side of a scantron sheet being utilized for a larger number of answer options)."
									className={styles.textfieldcomponent}
									defaultValue=""
									question="What is the highest question number on your exam?"
									onChange={handleGPScorChange}
									helperText={numQuestionsHelpText}
									error={numQuestionsError}
									name="valueNumQuestions"
									value={gpScorState.valueNumQuestions || ""}
								/>
								<QuestionDropDown
									tooltip
									tooltipText="ABCDE would be 5."
									className={styles.textfieldcomponent}
									question="How many answer options does each question have?"
									listValues={listOfAnswerOptions}
									helperText={numAnswersHelpText}
									error={numAnswersError}
									onChange={handleGPScorChange}
									value={gpScorState.valueNumAnswers || ""}
									name="valueNumAnswers"
									defaultValue="5"
								/>
								<NumKeysDropDown
									tooltip
									tooltipText="Please make sure that the exam keys have special codes (columns JKLMN) that correspond to the student scantron special codes."
									className={styles.textfieldcomponent}
									question={
										<span>
											How many <strong>answer keys</strong>/exam versions?
										</span>
									}
									listValues={listOfKeys}
									onChange={handleGPScorChange}
									helperText={numKeysHelpText}
									error={numKeysError}
									value={gpScorState.valueNumKeys || ""}
									name="valueNumKeys"
									defaultValue="1"
								/>
							</Box>
							<div className={styles.classandswitch}>
								<ClassDropDown
									/* className={styles.textfieldcomponent} */
									/* question="Select your class(es):" */
									listValues={classNumList}
									onChange={handleGPScorChange}
									multiple={multiple}
									inputlabel="Select Your Class(es):"
									onChangeSwitch={handleChangeClassDropdown}
									helperText={classNumHelpText}
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										width: "250px",
										maxWidth: "250px",
									}}
									error={classNumError}
									value={gpScorState.valueClassNum || ""}
									name="valueClassNum"
								/>
								<FormControlLabel
									sx={{
										position: "relative",
										display: "flex",
										/* justifyContent: "flex-end", */
									}}
									control={
										<Switch
											disabled
											color="secondary"
											checked={advancedChecked}
											onChange={toggleAdvanced}
										/>
									}
									label="Advanced"
									name="advanced"
								/>
								<Tooltip TransitionComponent={Zoom} title="Temporarily Disabled" placement="top">
									<HelpIcon
										sx={{
											marginLeft: "-25px",
											marginTop: "0px",
											color: "gray",
										}}
									/>
								</Tooltip>
							</div>

							<AdvancedChecked
								formulaScoringChecked={state.formulaScoringChecked}
								checked={examWeightsChecked}
								onChange={toggleExamWeights}
								numAnswerKeys={gpScorState.valueNumKeys}
								advancedChecked={advancedChecked}
								examWeightsChecked={examWeightsChecked}
								greaterThan={greaterThan}
							/>

							<div className={styles.commentsfield}>
								<TextField
									inputProps={inputProps}
									multiline
									value={state.notes}
									error={notesError}
									helperText={notesHelpText}
									onChange={handleChange}
									name="notes"
									placeholder={notesText}
									sx={{
										marginTop: "20px",
										marginLeft: { md: "0px", lg: "140px", xl: "160px" },

										paddingBottom: "20px",
										width: { sm: "100%", md: "100%", lg: "60%", xl: "60%" },
									}}
								/>
							</div>
						</Box>
					)}
					{assign2 && (
						<div className={styles.questionitemgroup}>
							<div className={styles.individualquestionitem}>
								<QuestionText
									className={styles.textfieldcomponent}
									question="How many exam booklets would you like to scan?"
									onChange={handleAssign2Change}
									name="valueNumBooklets"
									error={numBookletsError}
									helperText={numBookletsHelpText}
									value={assign2State.valueNumBooklets || ""}
									defaultValue=""
								/>
							</div>
							<div className={styles.individualquestionitem}>
								<QuestionText
									className={styles.textfieldcomponent}
									question="How many pages are in each booklet?"
									onChange={handleAssign2Change}
									value={assign2State.valueNumPages || ""}
									error={numPagesError}
									helperText={numPagesHelpText}
									name="valueNumPages"
									defaultValue=""
								/>
							</div>
							<div className={styles.assign2dropdowns}>
								<QuestionDropDown
									listValues={singleOrDoubleSided}
									onChange={handleAssign2Change}
									value={assign2State.singleOrDouble || ""}
									name="singleOrDouble"
								/>
								<ClassDropDown
									className={styles.assign2classdropdown}
									listValues={classNumList}
									onChangeSwitch={handleChangeClassDropdown}
									multiple={multiple}
									inputlabel="Select Your Class(es):"
									onChange={handleAssign2Change}
									helperText={classNumHelpText}
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										width: "200px",
										maxWidth: "200px",
									}}
									error={classNumError}
									value={assign2State.valueClassNum}
									name="valueClassNum"
								/>
							</div>
							<Assign2ResultsRadio
								value={assign2State.tsqsLoadOrNot}
								onChange={handleAssign2Change}
								name="tsqsLoadOrNot"
								error={assign2Error}
								helpText={assign2HelpText}
							/>
							<Assign2InstructorInstructions upload={assign2State.tsqsLoadOrNot} />
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								sx={{ width: "120px", height: "40px", marginBottom: "40px" }}
							>
								Submit
							</Button>
						</div>
					)}
					{mrScor /* change formatting for exception questions */ && (
						<div className={styles.questionitemgroup}>
							<div className={styles.individualquestionitem}>
								<QuestionText
									className={styles.textfieldcomponent}
									question="What is the highest question number on your exam?"
									onChange={handleMrScorChange}
									/* helperText={numQuestionsHelpText} */
									error={numQuestionsError}
									name="numQuestions"
									value={mrScorState.numQuestions || ""}
									defaultValue=""
								/>
							</div>
							<div className={styles.individualquestionitem}>
								<QuestionDropDown
									question="Is the entire exam multiple response?"
									listValues={yesOrNo}
									onChange={handleMrScorChange}
									value={mrScorState.yesOrNo || ""}
									error={yesOrNoError}
									/* helpText={yesOrNoHelpText} */
									name="yesOrNo"
								/>
							</div>
							{mrScorState.yesOrNo === "No" && ( //Table of clickable questions to select MR questions - select all/deselect all button, D=1, E, F later. Highest question # used, round to 50%. 20 questions - NOP = 010.
								<div className={styles.notAllMultiple}>
									<div className={styles.individualquestionitem}>
										<QuestionText
											className={styles.textfieldcomponent}
											question="Which questions are multiple response? (e.g. 6, 15, 23)"
											onChange={handleMrScorChange}
											value={mrScorState.notMultipleResponse || ""}
											error={notMultipleResponseError}
											/* helperText={notMultipleResponseHelpText} */
											name="notMultipleResponse"
											defaultValue=""
										/>
									</div>
									<div className={styles.individualquestionitem}>
										<div className={styles["question-text"]}>
											What weight would you like to assign to the single response questions?
										</div>

										<Select
											value={mrScorState.weightingSingle || [""]}
											name="weightingSingle"
											error={weightingSingleError}
											/* helperText={weightingHelpText} */
											onChange={handleMrScorChange}
											variant="outlined" //set width
											sx={{
												color: "theme.palette.secondary",
												marginRight: "20px",
												marginLeft: "10px",
												width: "100px",
											}}
										>
											{listOfKeys.map((option) => (
												<MenuItem key={option.key} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</div>
								</div>
							)}
							<div className={styles.individualquestionitem}>
								<div className={styles["question-text"]}>
									What weight would you like to assign to the multiple response questions?
								</div>

								<Select
									value={mrScorState.weightingMultiple || [""]}
									name="weightingMultiple"
									error={weightingMultipleError}
									/* helperText={weightingHelpText} */
									onChange={handleMrScorChange}
									variant="outlined" //set width
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										width: "100px",
									}}
								>
									{listOfKeys.map((option) => (
										<MenuItem key={option.key} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</div>

							<div className={styles.individualquestionitem}>
								<QuestionText
									className={styles.textfieldcomponent}
									question="How would you like to handle overanswering?"
									onChange={handleMrScorChange}
									value={mrScorState.overanswering || ""}
									error={overansweringError}
									/* helperText={overansweringHelpText} */
									name="overanswering" //If penalties no, then ask this question, else don't ask
									tooltip
									tooltipText="Example: A question has correct answers A and B. The student selects A, B, and C. Would you take off marks for this, or mark the question incorrect?"
									defaultValue=""
								/>
							</div>

							<div className={styles.individualquestionitem}>
								<QuestionText
									className={styles.textfieldcomponent}
									question="How would you like to handle partial answers?"
									onChange={handleMrScorChange}
									value={mrScorState.partial || ""}
									error={partialError}
									/* helperText={partialHelpText} */
									name="partial"
									tooltip
									tooltipText="Example: A question has correct answers A and B. The student selects only A. Would you award half a mark, or no marks?"
									defaultValue=""
								/>
							</div>
							<div className={styles.individualquestionitem}>
								<TextField
									inputProps={inputProps}
									multiline
									value={state.notes}
									error={notesError}
									helperText={notesHelpText}
									onChange={handleChange}
									name="notes"
									placeholder="Additional Comments:"
									sx={{ width: "80%" }}
								/>
							</div>

							<div className={styles.assign2dropdowns}>
								<ClassDropDown
									className={styles.assign2classdropdown}
									listValues={classNumList}
									onChangeSwitch={handleChangeClassDropdown}
									multiple={multiple}
									inputlabel="Select Your Class(es):"
									onChange={handleAssign2Change}
									helperText={classNumHelpText}
									sx={{
										color: "theme.palette.secondary",
										marginRight: "20px",
										marginLeft: "10px",
										width: "200px",
										maxWidth: "200px",
									}}
									error={classNumError}
									value={assign2State.valueClassNum}
									name="valueClassNum"
								/>
							</div>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								sx={{ width: "120px", height: "40px", marginBottom: "40px" }}
							>
								Submit
							</Button>
						</div>
					)}
					{gpScor && (
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmit}
							sx={{ width: "120px", height: "40px", marginBottom: "40px" }}
						>
							Submit
						</Button>
					)}
				</Box>
			</main>
			<Footer />
		</div>
	);
}
