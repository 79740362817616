import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { TokenContext } from "../../context/TokenContext";
import {
	RequisitionsContext,
	ClassIdsContext,
	WebClassesContext,
} from "../../context/RequisitionContext";
import {
	getRequisitionsAllData,
	getClassIdsByTerm,
	getClassesByTerm,
} from "../../components/Api/Getters";
import ClassSummaryTable from "./components/ClassSummaryTable";

export default function ClassSummary() {
	const token = useContext(TokenContext);
	const access = sessionStorage.getItem("access");
	const [requisitions, setRequisitions] = useState([]);
	const [, setOpen] = useState(false);
	const [rowData, setRowData] = useState({});
	const [itemListSet] = useState(false);
	const [classIds, setClassIds] = useState([]);
	const [classesClassIdsArray, setClassesClassIdsArray] = useState([]);
	const [classesWithReq, setClassesWithReq] = useState([]);
	const ccid = sessionStorage.getItem("ccid");
	const classIdsRef = useRef();
	classIdsRef.current = classIds;

	useEffect(() => {
		async function fetchClassIds() {
			if (token) {
				const classIds = await getClassIdsByTerm(token, "1850");
				setClassIds(classIds);
			}
		}
		fetchClassIds();
	}, [token]);

	useEffect(() => {
		async function fetchWebClasses() {
			if (token) {
				const classesClassIdsList = await getClassesByTerm(token, "1850");
				const classesClassIdArray = [];
				if (classesClassIdsList.length > 0) {
					classesClassIdsList.forEach((classId) => {
						classesClassIdArray.push(classId.classid);
						if (classId.comb_classes.length > 0) {
							const combClasses = classId.comb_classes.split(",");
							combClasses.forEach((combClass) => {
								classesClassIdArray.push(Number(combClass));
							});
						}
					});
					const uniqueClassesClassIdArray = [...new Set(classesClassIdArray)];
					setClassesWithReq(classesClassIdsList);
					setClassesClassIdsArray(uniqueClassesClassIdArray);
				}
			}
		}
		fetchWebClasses();
	}, [token]);

	useEffect(() => {
		if (classesWithReq && classesWithReq.length > 0) {
			classIds.forEach((classId) => {
				classesWithReq.forEach((classWithReq) => {
					if (classId.classid === classWithReq.classid) {
						classId.reqnum = classWithReq.reqnum;
						classId.cls_rel = classWithReq.cls_rel;
						classId.cls_rel_date = classWithReq.cls_rel_date;
						classId.survey_start = classWithReq.survey_start;
						classId.survey_end = classWithReq.survey_end;
					}
				});
			});
		}
	}, [classesWithReq, classIds]);

	useEffect(() => {
		if (classesClassIdsArray.length > 0) {
			const classIdsWithFlag = classIdsRef.current.map((classId) => {
				if (classesClassIdsArray.includes(classId.classid)) {
					classId.web_class = true;
				}
				return classId;
			});
			setClassIds(classIdsWithFlag);
		}
	}, [classesClassIdsArray]);

	const memoizedFetchRequisitionAllData = useMemo(() => {
		return async (token, ccid) => {
			if (token) {
				const response = await getRequisitionsAllData(token, ccid);
				if (response) {
					return response;
				}
			}
			return null;
		};
	}, []);

	useEffect(() => {
		async function fetchRequisitionNumbersByCCID() {
			if (token) {
				if (ccid !== null) {
					const data = await memoizedFetchRequisitionAllData(token, ccid);
					setRequisitions(data);
				}
			}
		}
		fetchRequisitionNumbersByCCID();
	}, [token, ccid, access, memoizedFetchRequisitionAllData]);

	return (
		<RequisitionsContext.Provider value={{ requisitions, setRequisitions }}>
			<ClassIdsContext.Provider value={{ classIds, setClassIds }}>
				<WebClassesContext.Provider value={{ classesClassIdsArray, setClassesClassIdsArray }}>
					<Grid
						container
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "flex-start",
							width: "100%",
						}}
					>
						<Typography variant="h4" sx={{ marginTop: "50px" }}>
							All Class IDs Summary
						</Typography>
						<Grid
							sx={{
								marginTop: "75px",
								display: "flex",
								flexDirection: "column",
								width: { xs: "100%", sm: "100%", md: "100%", lg: "1300px" },
								justifyContent: "center",
								gap: "5px",
								borderBottom: "none",
								borderRadius: "5px 5px 0px 0px",
								height: "500px",
							}}
						>
							<ClassSummaryTable
								requisitions={requisitions}
								setRequisitions={setRequisitions}
								setOpen={setOpen}
								rowData={rowData}
								setRowData={setRowData}
								itemListSet={itemListSet}
							/>

							<Box sx={{ margin: "5px", display: "flex", gap: "10px" }}></Box>
						</Grid>
						<Grid
							sx={{
								display: "flex",
								width: { xs: "100%", sm: "100%", md: "100%", lg: "1100px" },
								justifyContent: "center",
								gap: "10px",
							}}
						></Grid>
						<Grid
							sx={{
								marginTop: "2%",
								display: "flex",
								flexDirection: "column",
								width: { xs: "100%", sm: "100%", md: "100%", lg: "1100px" },
								justifyContent: "center",

								borderBottom: "none",
								borderRadius: "5px 5px 0px 0px",
							}}
						>
							<Box
								sx={{
									margin: "5px",
									display: "flex",
									justifyContent: "center",
								}}
							></Box>
						</Grid>
						<Grid
							sx={{
								display: "flex",
								width: { xs: "100%", sm: "100%", md: "100%", lg: "1100px" },
								height: "400px",
								justifyContent: "center",
							}}
						></Grid>

						<Grid
							sx={{
								marginTop: "5%",
								display: "flex",
								width: { xs: "100%", sm: "100%", md: "100%", lg: "800px" },
								justifyContent: "center",
							}}
						>
							<Grid sx={{ width: "33%" }}>
								<Grid item></Grid>
							</Grid>
						</Grid>
					</Grid>
				</WebClassesContext.Provider>
			</ClassIdsContext.Provider>
		</RequisitionsContext.Provider>
	);
}
