import * as React from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import { TeamTeachingContext } from "../../../context/RequisitionContext";
import AddUniqueItemsModal from "../Manual/AddRequisition/components/Modals/AddUniqueItemsModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GroupsIcon from "@mui/icons-material/Groups";
import ListSelect from "../../../components/Utils/ListSelect";
import { getItemCategories } from "../../../components/Api/Getters";
import ModifyAddItemsModal from "../Manual/ModifyRequisition/components/Modals/ModifyAddItemsModal";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import { useMutation, useQueryClient } from "react-query";
import { deleteItemList } from "../../../components/Api/Deletes";
import { postItemList } from "../../../components/Api/Post";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import { updateRequisition } from "../../../components/Api/Updates";

export default function ItemsTable(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const {
		chosenItems,
		setChosenItems,
		setModified,
		sortedChosenItems,
		setSortedChosenItems,
		modifiedItems,
		setModifiedItems,
		noModifications,
	} = props;
	const queryClient = useQueryClient();

	const [categories, setCategories] = useState([]);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({
		team_teaching: false,
		move_up_down: true,
		cat_group: true,
	});
	const apiRef = useGridApiRef();

	const shouldFetchCategories = useMemo(() => {
		return sortedChosenItems && sortedChosenItems.length > 0;
	}, [chosenItems]);

	const [openAddItemsModal, setOpenAddItemsModal] = useState(false);

	const categoriesList = [1, 2, 3, 4, 5, 6];

	const [reqnum, setReqnum] = useState(null);

	const [changesLoading, setChangesLoading] = useState(false);

	useEffect(() => {
		if (chosenItems && chosenItems.length > 0) {
			const updatedChosenItems = [...chosenItems];

			const spotInstrumentItems = updatedChosenItems.filter((item) =>
				categoriesList.includes(item.cat_id)
			);
			const supplementaryItems = updatedChosenItems.filter(
				(item) => !categoriesList.includes(item.cat_id)
			);

			const supplementaryRatingItems = supplementaryItems.filter((item) => item.itmtyp === "RATE");
			const supplementaryOpenItems = supplementaryItems.filter((item) => item.itmtyp === "OPEN");

			if (!teamTeachingAgree) {
				//non team teaching requisition
				if (props.inModifyFirst) {
					const unsortedChosenItemsList = [
						...spotInstrumentItems,
						...supplementaryRatingItems,
						...supplementaryOpenItems,
					];
					setSortedChosenItems(unsortedChosenItemsList);
				} else {
					const sortedSpotInstrumentItems = spotInstrumentItems.sort((a, b) => a.cat_id - b.cat_id);
					const sortedSupplementaryRatingItems = supplementaryRatingItems.sort(
						(a, b) => a.itemno - b.itemno
					);
					const sortedSupplementaryOpenItems = supplementaryOpenItems.sort(
						(a, b) => a.itemno - b.itemno
					);

					const sortedChosenItemsList = [
						...sortedSpotInstrumentItems,
						...sortedSupplementaryRatingItems,
						...sortedSupplementaryOpenItems,
					];
					setSortedChosenItems(sortedChosenItemsList);
				}
			}
			if (teamTeachingAgree) {
				//team teaching requisition

				const spotInstrumentRegularItems = spotInstrumentItems.filter(
					(item) => item.repeat === false || item.repeat === "No"
				);
				const spotInstrumentTTItems = spotInstrumentItems.filter(
					(item) => item.repeat === true || item.repeat === "Yes"
				);

				const supplementaryRegularItems = supplementaryItems.filter(
					(item) => item.repeat === false || item.repeat === "No"
				);
				const supplementaryTTItems = supplementaryItems.filter(
					(item) => item.repeat === true || item.repeat === "Yes"
				);

				const supplementaryRegularItemsRating = supplementaryRegularItems.filter(
					(item) => item.itmtyp === "RATE"
				);
				const supplementaryRegularItemsOpen = supplementaryRegularItems.filter(
					(item) => item.itmtyp === "OPEN"
				);
				const supplementaryTTItemsRating = supplementaryTTItems.filter(
					(item) => item.itmtyp === "RATE"
				);
				const supplementaryTTItemsOpen = supplementaryTTItems.filter(
					(item) => item.itmtyp === "OPEN"
				);

				if (props.inModifyFirst) {
					const unsortedChosenItemsList = [
						...spotInstrumentRegularItems,
						...spotInstrumentTTItems,
						...supplementaryRegularItemsRating,
						...supplementaryRegularItemsOpen,
						...supplementaryTTItemsRating,
						...supplementaryTTItemsOpen,
					];

					setSortedChosenItems(unsortedChosenItemsList);
				} else {
					const sortedSpotInstrumentRegularItems = spotInstrumentRegularItems.sort(
						(a, b) => a.cat_id - b.cat_id
					);

					const sortedSpotInstrumentTTItems = spotInstrumentTTItems.sort(
						(a, b) => a.cat_id - b.cat_id
					);

					const sortedSupplementaryRegularItemsRating = supplementaryRegularItemsRating.sort(
						(a, b) => a.itemno - b.itemno
					);

					const sortedSupplementaryRegularItemsOpen = supplementaryRegularItemsOpen.sort(
						(a, b) => a.itemno - b.itemno
					);

					const sortedSupplementaryTTItemsRating = supplementaryTTItemsRating.sort(
						(a, b) => a.itemno - b.itemno
					);

					const sortedSupplementaryTTItemsOpen = supplementaryTTItemsOpen.sort(
						(a, b) => a.itemno - b.itemno
					);

					const sortedChosenItemsList = [
						...sortedSpotInstrumentRegularItems,
						...sortedSpotInstrumentTTItems,
						...sortedSupplementaryRegularItemsRating,
						...sortedSupplementaryRegularItemsOpen,
						...sortedSupplementaryTTItemsRating,
						...sortedSupplementaryTTItemsOpen,
					];
					setSortedChosenItems(sortedChosenItemsList);
				}
			}
		}
	}, [chosenItems, teamTeachingAgree]);

	useEffect(() => {
		if (props.rowData) {
			setReqnum(props.rowData.reqnum);
		}
	}, [props.rowData]);

	useEffect(() => {
		setColumnVisibilityModel((prevState) => ({
			...prevState,
			team_teaching: teamTeachingAgree,
			move_up_down: noModifications ? false : prevState.move_up_down,
			cat_group: noModifications ? false : prevState.cat_group,
		}));
	}, [teamTeachingAgree]);

	useEffect(() => {
		if (shouldFetchCategories) {
			async function fetchCategories() {
				const categories = await getItemCategories(token);
				const newCategories = categories
					.filter((element) => element.cat_id > 10)
					.map((element) => element.cat_id + " - " + element.cat_name);
				setCategories(newCategories);
			}
			fetchCategories();
		}
	}, [shouldFetchCategories, token]);

	const updateRequisitionMutation = useMutation(
		({ reqnum, totrtng, totopn, uqrtng, uqopen, rptits }) =>
			updateRequisition(token, { reqnum, totrtng, totopn, uqrtng, uqopen, rptits })
	);

	const deleteItemsMutation = useMutation((reqnum) => deleteItemList(token, reqnum));

	const postItemsMutation = useMutation((itemList) => postItemList(token, itemList));

	const deleteItemsAndPostItems = (reqnum, itemList, totrtng, totopn, uqrtng, uqopen, rptits) => {
		setChangesLoading(true);

		updateRequisitionMutation.mutate(
			{ reqnum, totrtng, totopn, uqrtng, uqopen, rptits },
			{
				onSettled: (data, error) => {
					if (!error) {
						deleteItemsMutation.mutate(reqnum, {
							onSettled: (data, error) => {
								if (!error) {
									postItemsMutation.mutate(itemList, {
										onSettled: (data, error) => {
											if (!error) {
												queryClient.refetchQueries(["requisitions", token, ccid, props.termNumber]);
												props.setModifiedItems(false);
												setChangesLoading(false);
												props.setOpenEditItems(false);
											} else {
												console.error("post classes failed", error);
												setChangesLoading(false);
											}
										},
									});
								} else {
									console.error("delete classes failed", error);
									setChangesLoading(false);
								}
							},
						});
					}
				},
			}
		);
	};

	const confirmChanges = () => {
		const itemListTemp = [...sortedChosenItems];
		const itemList = [];
		const totrtng = itemListTemp.filter((item) => item.itmtyp === "RATE").length;
		const totopn = itemListTemp.filter((item) => item.itmtyp === "OPEN").length;
		const uqrtng = itemListTemp.filter(
			(item) => item.itmtyp === "RATE" && item.sub_type === "U"
		).length;
		const uqopen = itemListTemp.filter(
			(item) => item.itmtyp === "OPEN" && item.sub_type === "U"
		).length;
		const rptits = itemListTemp.filter((item) => item.repeat === "Yes").length;
		itemListTemp.forEach((item, index) => {
			const itemObj = {
				reqnum: reqnum,
				itemno: item.itemno,
				q_order: index + 1,
				blkmem1: item.blkmem1 ? item.blkmem1 : "No",
				blkmem2: item.blkmem2 ? item.blkmem2 : "No",
				blkmem3: item.blkmem3 ? item.blkmem3 : "No",
				//flag
				sub_type: item.sub_type,
				custom_cat: item.custom_cat,
				repeat:
					teamTeachingAgree === true
						? item.repeat === true || item.repeat === "Yes"
							? "Yes"
							: "No"
						: "No",
			};
			itemList.push(itemObj);
		});
		deleteItemsAndPostItems(reqnum, itemList, totrtng, totopn, uqrtng, uqopen, rptits);
	};

	const cancelChanges = () => {
		props.setOpenEditItems(false);
	};

	const handleAddItem = () => {
		//flag
		setOpenAddItemsModal(true);
	};

	const handleMoveUp = useCallback(
		(row) => {
			const index = sortedChosenItems.findIndex((r) => r.itemno === row.itemno);
			if (index - 1 >= 0) {
				const updatedRows = [...sortedChosenItems];
				const temp = updatedRows[index - 1];
				if (
					categoriesList.includes(temp.cat_id) ||
					(row.repeat === true && temp.team_teaching === "N" && teamTeachingAgree) ||
					(row.itmtyp === "OPEN" && temp.itmtyp === "RATE")
				) {
					//disallow putting between SPOT instrument questions
					return;
				}
				updatedRows[index - 1] = updatedRows[index];
				updatedRows[index] = temp;
				setModifiedItems(true);
				setSortedChosenItems(updatedRows);
			}
		},
		[sortedChosenItems, setSortedChosenItems, categoriesList, teamTeachingAgree]
	);

	const handleMoveDown = useCallback(
		(row) => {
			const index = sortedChosenItems.findIndex((r) => r.itemno === row.itemno);
			if (index + 1 < sortedChosenItems.length) {
				const updatedRows = [...sortedChosenItems];
				const temp = updatedRows[index + 1];
				if (
					(teamTeachingAgree && temp.repeat === true && row.team_teaching === "N") ||
					(row.itmtyp === "RATE" && temp.itmtyp === "OPEN")
				) {
					//disallow putting between SPOT instrument questions
					return;
				}
				updatedRows[index + 1] = updatedRows[index];
				updatedRows[index] = temp;
				setModifiedItems(true);
				setSortedChosenItems(updatedRows);
			}
		},
		[sortedChosenItems, setSortedChosenItems, teamTeachingAgree]
	);

	const handleTeamTeachingToggle = useCallback(
		(row) => {
			const updatedChosenItems = [...chosenItems];
			const index = updatedChosenItems.findIndex((r) => r.itemno === row.itemno);
			updatedChosenItems[index].repeat = !updatedChosenItems[index].repeat;
			setChosenItems(updatedChosenItems);
			setModifiedItems(true);
		},
		[chosenItems, setChosenItems]
	);

	const handleDeleteRows = useCallback(
		(row) => {
			if (row.sub_type === "U") {
				const itemNoToBeDeleted = row.itemno;
				const updatedRows = chosenItems.filter((r) => r.itemno !== row.itemno);

				const updatedRowsAdjusted = updatedRows.map((r) => {
					if (r.sub_type === "U" && r.itemno > itemNoToBeDeleted) {
						r.itemno = r.itemno - 1;
					}
					return r;
				});
				props.inModifySecond ? props.setModifiedItems(true) : setModifiedItems(true);
				setChosenItems(updatedRowsAdjusted);
			} else {
				const updatedRows = chosenItems.filter((r) => r.itemno !== row.itemno);
				props.inModifySecond ? props.setModifiedItems(true) : setModifiedItems(true);
				setChosenItems(updatedRows);
			}
		},
		[chosenItems, setChosenItems]
	);

	const handleChangeCategorySelect = useCallback(
		(event, rowIndex) => {
			const updatedList = [...chosenItems];
			updatedList[rowIndex].custom_cat = event.target.value || null;
			setModifiedItems(true);
			setChosenItems(updatedList);
		},
		[chosenItems, setChosenItems]
	);

	const renderDeleteButton = useCallback(
		(params) => {
			if (categoriesList.includes(params.row.cat_id)) {
				return <strong></strong>;
			} else {
				return (
					<strong>
						<Button
							variant="outlined"
							sx={{ marginLeft: "5px", padding: "2px" }}
							color="error"
							onClick={() => handleDeleteRows(params.row)}
						>
							Delete
						</Button>
					</strong>
				);
			}
		},
		[handleDeleteRows]
	);

	const renderCategorySelect = useCallback(
		(params) => {
			const { row } = params;
			const rowIndex = chosenItems.findIndex((r) => r.itemno === row.itemno);
			if (categoriesList.includes(params.row.cat_id)) {
				return <strong></strong>;
			} else if (params.row.sub_type === "C") {
				return (
					<strong>
						<ListSelect
							size="small"
							value={row.custom_cat || ""}
							list={categories}
							width="90px"
							marginBottom="0px"
							onChange={(event) => handleChangeCategorySelect(event, rowIndex)}
						/>
					</strong>
				);
			}
		},
		[handleChangeCategorySelect, categories, chosenItems]
	);

	const renderMoveUpDownButtons = useCallback(
		(params) => {
			if (categoriesList.includes(params.row.cat_id)) {
				return <strong></strong>;
			} else {
				return (
					<strong>
						<Button
							variant="outlined"
							size="small"
							sx={{ maxWidth: "30px", minWidth: "30px", marginRight: "5px" }}
							onClick={() => handleMoveUp(params.row)}
						>
							<ArrowUpwardIcon />
						</Button>
						<Button
							variant="outlined"
							size="small"
							sx={{ maxWidth: "30px", minWidth: "30px" }}
							onClick={() => handleMoveDown(params.row)}
						>
							<ArrowDownwardIcon />
						</Button>
					</strong>
				);
			}
		},
		[handleMoveUp, handleMoveDown]
	);
	const renderTeamTeachingToggle = (params) => {
		if (params.row.team_teaching === "Y") {
			return (
				<strong>
					<Checkbox
						checked={params.row.repeat === true || params.row.repeat === "Yes" ? true : false}
						sx={{}}
						onChange={() => handleTeamTeachingToggle(params.row)}
						disabled={categoriesList.includes(params.row.cat_id)}
						inputProps={{ "aria-label": "controlled" }}
					/>
				</strong>
			);
		} else {
			return <strong></strong>;
		}
	};

	const CustomFooter = () => {
		if (props.inModifyFirst) {
			return (
				<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							gap: "15px",
							marginLeft: "10px",
						}}
					>
						<Button variant="outlined" onClick={handleAddItem}>
							Add Items
						</Button>
						<AddUniqueItemsModal
							setModified={setModified}
							chosenItems={chosenItems}
							setChosenItems={setChosenItems}
							setModifiedItems={setModifiedItems}
						/>

						<Button variant="contained" disabled={!props.modifiedItems} onClick={confirmChanges}>
							{changesLoading ? (
								<Typography>
									{" "}
									Please wait...
									<CircularProgress size={20} />{" "}
								</Typography>
							) : (
								`Confirm Changes`
							)}
						</Button>
						<Button variant="contained" color="error" onClick={cancelChanges}>
							Cancel
						</Button>
					</Box>
					<Box
						sx={{
							outline: chosenItems.length > 100 ? "1px solid red" : "none",
							borderRadius: chosenItems.length > 100 ? "5px" : "0px",
						}}
					>
						<GridFooter
							sx={{
								display: "flex",
							}}
						/>
					</Box>
				</GridFooterContainer>
			);
		} else if (props.inModifySecond) {
			return (
				<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							gap: "10px",
							marginLeft: "10px",
						}}
					></Box>
					<Box
						sx={{
							outline: chosenItems.length > 100 ? "1px solid red" : "none",
							borderRadius: chosenItems.length > 100 ? "5px" : "0px",
						}}
					>
						<GridFooter
							sx={{
								display: "flex",
							}}
						/>
					</Box>
				</GridFooterContainer>
			);
		} else {
			return (
				<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
					{noModifications ? (
						<Box sx={{ marginLeft: "10px" }}></Box>
					) : (
						<Box sx={{ marginLeft: "10px" }}>
							<AddUniqueItemsModal
								setModified={setModified}
								chosenItems={chosenItems}
								setChosenItems={setChosenItems}
								setModifiedItems={setModifiedItems}
							/>
						</Box>
					)}

					<Box
						sx={{
							outline: chosenItems.length > 100 ? "1px solid red" : "none",
							borderRadius: chosenItems.length > 100 ? "5px" : "0px",
						}}
					>
						<GridFooter
							sx={{
								display: "flex",
							}}
						/>
					</Box>
				</GridFooterContainer>
			);
		}
	};

	const columns = [
		{
			field: "itemno",
			headerName: "Item",
			width: 60,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "text",
			headerName: "Item Text",
			flex: 1,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "cat_group",
			headerName: "Category",
			headerAlign: "center",
			width: 90,
			align: "center",
			renderCell: renderCategorySelect,
			disableClickEventBubbling: true,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "move_up_down",
			headerName: "Order",
			headerAlign: "center",
			width: 75,
			align: "center",
			renderCell: renderMoveUpDownButtons,
			disableClickEventBubbling: true,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "team_teaching",
			width: 30,
			align: "center",
			renderCell: renderTeamTeachingToggle,
			renderHeader: () => {
				return (
					<Tooltip title="Team teaching item." placement="right">
						<strong>
							<GroupsIcon sx={{ marginTop: "15px", marginLeft: "3px" }} />
						</strong>
					</Tooltip>
				);
			},
			disableClickEventBubbling: true,
			sortable: false,
			disableColumnMenu: true,
		},

		{
			field: "itmtyp",
			headerName: "Type",
			width: 60,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: " ",
			width: 100,
			renderCell: renderDeleteButton,
			sortable: false,
			disableColumnMenu: true,
		},
	];

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<DataGrid
				apiRef={apiRef}
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					border:
						props.greenBorder && sortedChosenItems && sortedChosenItems.length > 0
							? "1px solid #275d38"
							: "grey.300",
					borderTop:
						props.greenBorder && sortedChosenItems && sortedChosenItems.length > 0
							? "1px solid #E0E0E0"
							: "grey.300",
					borderRadius: props.greenBorder && "1px 1px 4px 4px",
				}}
				density="compact"
				components={{ Footer: CustomFooter }}
				disableRowSelectionOnClick={true}
				rows={sortedChosenItems}
				columns={columns}
				isRowSelectable={() => false}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) => {
					setColumnVisibilityModel(newModel);
				}}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.itemno}
			/>

			<ModifyAddItemsModal
				openAddItemsModal={openAddItemsModal}
				setOpenAddItemsModal={setOpenAddItemsModal}
				inModify={props.inModify}
				inModifyFirst={props.inModifyFirst}
				chosenItems={chosenItems}
				setChosenItems={setChosenItems}
				sortedChosenItems={sortedChosenItems}
				setSortedChosenItems={setSortedChosenItems}
				setModifiedItems={props.setModifiedItems}
				rowData={props.rowData}
				setOpenEditItems={props.setOpenEditItems}
			/>
		</div>
	);
}
