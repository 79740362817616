import { useQuery, useMutation, useQueryClient } from "react-query";
import {
	getClassIdsByDeptIdAndTerm,
	getRolesByClassIds,
	getClassesByTerm,
	getClassIdsByFetchRolesByEmpId,
	getCombinedClassIds,
	getClassIdsAutoGeneration,
} from "../components/Api/Getters";
import { updateClassIdTeamSize } from "../components/Api/Updates";
import { roles, rolesDescription } from "../components/Utils/Descriptions";

export const useClassIdObjectsByDeptAndTerm = (token, deptId, termValue) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token && !!deptId && !!termValue,
	};
	return useQuery(
		["classIdObjectsByDeptAndTerm", deptId, termValue],
		async () => {
			const classIdsObjects = await getClassIdsByDeptIdAndTerm(token, deptId, termValue);
			classIdsObjects.forEach((classid) => {
				classid.id = classid.classid;
				classid.combined = false;
				classid.comb_classes = [];
				if (classid.roles && classid.roles.length > 0) {
					classid.roles.forEach((role) => {
						role.checked = false;
						const roleDescription = rolesDescription.find((r) => r.id === role.class_role);
						role.role_description = roleDescription.name;
					});
					for (let role of roles) {
						const foundRole = classid.roles.find((r) => r.class_role === role);
						if (foundRole) {
							classid.name = foundRole.name;
							classid.class_role = foundRole.class_role;
							classid.empid = foundRole.empid;
							break;
						}
					}
				} else {
					classid.name = "";
					classid.class_role = "";
					classid.empid = "";
				}
			});
			return classIdsObjects;
		},
		queryOptions
	);
};

export const useClassIdsAutoGeneration = (token, deptId, termValue) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token && !!deptId && !!termValue,
	};
	return useQuery(
		["autoGenerationClassIds", deptId, termValue],
		async () => {
			const classIdsObjects = await getClassIdsAutoGeneration(token, deptId, termValue);
			classIdsObjects.forEach((classid) => {
				classid.id = classid.classid;

				if (classid.roles && classid.roles.length > 0) {
					classid.roles.forEach((role) => {
						/* role.checked = false; */
						const roleDescription = rolesDescription.find((r) => r.id === role.class_role);
						role.role_description = roleDescription.name;
					});
				}
			});
			return classIdsObjects;
		},
		queryOptions
	);
};

export const useClassIdListByDeptAndTerm = (token, deptId, termValue) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token && deptId !== null && termValue !== null,
	};
	return useQuery(
		["classIdListByDeptAndTerm", deptId, termValue],
		async () => {
			const classIdsList = await getClassIdsByDeptIdAndTerm(token, deptId, termValue);
			const classIdsArray = classIdsList.map((classid) => {
				classid.id = classid.classid;
				return classid.classid;
			});
			return classIdsArray;
		},
		queryOptions
	);
};

export const useClassIdByEmpId = (token, facultyRank) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token && facultyRank !== null && facultyRank !== undefined,
	};
	return useQuery(
		["classIdByEmpIdAndTerm", facultyRank],
		async () => {
			const classIds = await getClassIdsByFetchRolesByEmpId(token, facultyRank);
			if (classIds.length === 0) {
				/* console.log("Cannot find any assigned classes. Please contact TSS for assistance."); */
				return null;
			} else {
				return classIds;
			}
		},
		queryOptions
	);
};

export const useRolesFromClassIdList = (token, classIdList) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token && classIdList !== null,
	};
	return useQuery(
		["rolesFromClassIdList", classIdList],
		async () => {
			const roles = await getRolesByClassIds(token, classIdList);
			return roles;
		},
		queryOptions
	);
};

async function fetchClassesClassIds(token, termValue) {
	if (!token || !termValue) {
		return [];
	} else {
		const classesClassIdsList = await getClassesByTerm(token, termValue);
		const classesClassIdArray = [];
		if (classesClassIdsList.length > 0) {
			classesClassIdsList.forEach((classId) => {
				classesClassIdArray.push({
					classid: classId.classid,
					reqnum: classId.reqnum,
					dept_id: classId.dept_id,
				});
				if (classId.comb_classes.length > 0) {
					const combClasses = classId.comb_classes.split(",");
					combClasses.forEach((combClass) => {
						classesClassIdArray.push({
							classid: Number(combClass),
							reqnum: classId.reqnum,
							dept_id: classId.dept_id,
						});
					});
				}
			});
		}
		return [...new Set(classesClassIdArray)];
	}
}

export function useWebClasses(token, termValue) {
	const minutes = 1000 * 60;
	const queryOptions = {
		refetchInterval: 2 * minutes,
		enabled: !!token && !!termValue,
	};
	return useQuery(
		["webClassesQuery", token, termValue],
		() => fetchClassesClassIds(token, termValue),
		queryOptions
	);
}

export function useCombinedClassesQuery(token, deptId, termValue) {
	const minutes = 1000 * 60;
	const queryOptions = {
		refetchInterval: 2 * minutes,
		enabled: !!token && !!termValue && !!deptId,
	};
	return useQuery(
		["combinedClassesQuery", token, deptId, termValue],
		() => getCombinedClassIds(token, deptId, termValue),
		queryOptions
	);
}

export function useUpdateClassIdTeamSize(token) {
	return useMutation((data) => updateClassIdTeamSize(token, data.classid, data.teamsize));
}

export function usePostClassIdQuery(token) {
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(classIdToAdd) => {
			return postClassId(token, classIdToAdd);
		},
		{
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.refetchQueries("classIdObjectsByDeptAndTerm");
			},
		}
	);

	const postClassId = async (classIdToAdd) => {
		const response = await mutation.mutateAsync(classIdToAdd);
		return response.status === 201;
	};

	return { postClassId, status: mutation.status };
}
