import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, FormControl } from "@mui/material";
import TextInput from "../../../../components/Utils/TextInput";
import { RequisitionCalendar } from "../../../../components/Utils/RequisitionCalendar";
import GetTodayDate from "../../components/GetTodayDate";
import { FormLabel } from "@mui/material";
import dayjs from "dayjs";
import RequisitionModalBlock from "./components/Modals/RequisitionModalBlock";
import RequisitionModalItem from "./components/Modals/RequisitionModalItem";
import RequisitionModalClasses from "./components/Modals/RequisitionModalClasses";
import ItemsTable from "../../components/ItemsTable";
import ClassesTable from "../../components/ClassesTable";
import TeamTeachingDialog from "./components/Dialogs/TeamTeachingDialog";
import SubmitReqDialog from "./components/Dialogs/SubmitReqDialog";
import Box from "@mui/material/Box";
import BasicSwitch from "../../../../components/Utils/BasicSwitch";
import { getReqnum, getBlockItemsByBlockId1AndBlockId3 } from "../../../../components/Api/Getters";
import {
	postRequisitions,
	postItemList,
	postClasses,
	postItemsUnique,
} from "../../../../components/Api/Post";
import {
	ClassesContext,
	ClassIdsContext,
	TeamTeachingContext,
	StartDateContext,
	EndDateContext,
	DeptIdContext,
	TermContext,
	SmallRequisitionContext,
	WebClassesContext,
} from "../../../../context/RequisitionContext";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import { useAllItemsQuery } from "../../../../Hooks/useAllItemsQuery";
import { useBlockItemsQuery, useAllBlockItems } from "../../../../Hooks/useBlockItemsQuery";
import { useItemCategoriesQuery } from "../../../../Hooks/useItemCategoriesQuery";
import { useClassIdObjectsByDeptAndTerm } from "../../../../Hooks/useClassIdsQuery";
import { useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { useTermsQuery } from "../../../../Hooks/useTermsQuery";

export default function AddRequisition() {
	let auth = isAuthenticated();
	const token = auth.token;
	const userid = auth.ccid;

	const warningMessageModifiedItems =
		"You have modified the items chosen for this block. If you continue to choose a block or individual items, you will lose these changes. Any additions of custom questions, modifications to item order, team teaching status, or item category must take place after your list of items has been finalized.";

	const firstName = sessionStorage.getItem("first_name");
	const lastName = sessionStorage.getItem("last_name");
	const [date, setDate] = useState(GetTodayDate());
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [requisitionState, setRequisitionState] = useState({
		requisitionerName: "",
		requisitionDepartment: "",
	});
	const [requisitionError, setRequisitionError] = useState({
		requisitionerName: false,
	});
	const [chosenItems, setChosenItems] = useState([]);
	const [chosenClasses, setChosenClasses] = useState([]);
	const [displayTeamTeachingDialog, setDisplayTeamTeachingDialog] = useState(false);
	const [teamTeachingAgree, setTeamTeachingAgree] = useState(false);
	const [classIds, setClassIds] = useState([]);
	const [deptId, setDeptId] = useState(null);
	const [termValue, setTermValue] = useState(null);
	const [itemsForSingleQuestion, setItemsForSingleQuestion] = useState([]);
	const [classesClassIdsArray, setClassesClassIdsArray] = useState([]);
	const [raddr2, setRaddr2] = useState("");
	const [blkId1, setBlkId1] = useState("");
	const [blkId3, setBlkId3] = useState("");
	const [smallRequisition, setSmallRequisition] = useState({
		classes: false,
		items: false,
	});
	const [muiTableKey, setMuiTableKey] = React.useState(0);
	const [blockIdListValue, setBlockIdListValue] = useState("");
	const [blockItems, setBlockItems] = useState([]);
	const containerRef = useRef(null);
	const [blkItemsBlkIds, setBlkItemsBlkIds] = useState([]);
	const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
	const [reqnum, setReqnum] = useState(null);
	const [sortedChosenItems, setSortedChosenItems] = useState([]);
	const [modifiedItems, setModifiedItems] = useState(false);
	const [openConfirmItemsModifiedDialog, setOpenConfirmItemsModifiedDialog] = useState(false);
	const [modifiedItemsOrBlocks, setModifiedItemsOrBlocks] = useState(null);
	const [openItemModal, setOpenItemModal] = useState(false);
	const [openBlockModal, setOpenBlockModal] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const { data: termsList } = useTermsQuery(token);

	const { data: allItemsData } = useAllItemsQuery(token);
	const { data: itemCategoriesData, status: itemCategoriesStatus } = useItemCategoriesQuery(token, {
		enabled: !!token,
	});
	const { data: allBlockItemsData, status: allBlockItemsStatus } = useBlockItemsQuery(token);
	const requisitionsMutation = useMutation((requisition) => postRequisitions(token, requisition));
	const classesMutation = useMutation((classArray) => postClasses(token, classArray));
	const itemsListsMutation = useMutation((itemsArray) => postItemList(token, itemsArray));
	const itemsUniqueMutation = useMutation((uniqueItemsArray) =>
		postItemsUnique(token, uniqueItemsArray)
	);

	const {
		data: allBlockItems,
		isLoading: allBlockItemsLoading,
		isFetching: allBlockItemsFetching,
		status: blockItemsStatus,
		refetch: refetchBlockItems,
	} = useAllBlockItems(
		allBlockItemsStatus === "success" ? allBlockItemsData : null,
		itemCategoriesStatus === "success" ? allItemsData : null
	);

	const { data: fetchedClassIds, status: fetchedClassIdsStatus } = useClassIdObjectsByDeptAndTerm(
		token,
		deptId,
		termValue
	);

	const [addRequisitionDialogString, setAddRequisitionDialogString] = useState(
		"IMPORTANT: This survey will not be set up until classes in the survey have been released. To release your classes, go to Manage Requisitions."
	);

	useEffect(() => {
		if (termsList && termsList.length > 0) {
			//get max term

			const maxTerm = termsList.reduce((max, term) => {
				return term > max ? term : max;
			}, 0);

			setTermValue(maxTerm);
		}
	}, [termsList]);

	useEffect(() => {
		if (allItemsData && itemCategoriesData) {
			// Create a map of categories by cat_id
			const categoryMap = itemCategoriesData.reduce((map, category) => {
				map[category.cat_id] = category;
				return map;
			}, {});

			const groupedItems = allItemsData.reduce((r, a) => {
				if (a.cat_id !== 10 && a.itemno < 20000) {
					const category = categoryMap[a.cat_id];
					if (category) {
						const categoryName = category.cat_name;
						if (!r[categoryName]) {
							r[categoryName] = [];
						}
						// Insert the item in the correct position to keep the array sorted
						const index = r[categoryName].findIndex((item) => item.itemno > a.itemno);
						if (index === -1) {
							r[categoryName].push(a);
						} else {
							r[categoryName].splice(index, 0, a);
						}
					}
				}
				return r;
			}, {});

			setItemsForSingleQuestion(groupedItems);
		}
	}, [allItemsData, itemCategoriesData]);

	useEffect(() => {
		if (fetchedClassIdsStatus === "success") {
			setClassIds(fetchedClassIds);
		}
	}, [token, fetchedClassIds, fetchedClassIdsStatus]);

	useEffect(() => {
		//this must be an effect
		async function fetchBlockItemsByBlockId1AndBlockId3() {
			if (token) {
				if (blkId1 !== "" || blkId3 !== "") {
					const items = await getBlockItemsByBlockId1AndBlockId3(token, blkId1, blkId3);
					const groupedItems = items.reduce((r, a) => {
						r[a.blkid] = [...(r[a.blkid] || []), a];
						return r;
					}, {});
					if (Object.keys(groupedItems).length > 1) {
						const blkid1Items = groupedItems[blkId1];
						const blkid3Items = groupedItems[blkId3];
						const blkid1Itemnos = blkid1Items.map((item) => item.itemno);
						const blkid3Itemnos = blkid3Items.map((item) => item.itemno);
						const commonItemnos = blkid1Itemnos.filter((itemno) => blkid3Itemnos.includes(itemno));
						const updatedBlkid1Items = blkid1Items.filter(
							(item) => !commonItemnos.includes(item.itemno)
						);
						const updatedGroupedItems = {};
						updatedGroupedItems[blkId1] = updatedBlkid1Items;
						updatedGroupedItems[blkId3] = blkid3Items;
						setBlkItemsBlkIds(updatedGroupedItems);
					} else {
						setBlkItemsBlkIds(groupedItems);
					}
				}
			}
		}
		fetchBlockItemsByBlockId1AndBlockId3();
	}, [token, blkId1, blkId3]);

	const isChooseBlockOrItemDisabled = startDate === null || endDate === null;

	useEffect(() => {
		//scroll down if block chosen
		if (blockIdListValue !== "") {
			if (containerRef.current) {
				containerRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}
	}, [blockIdListValue]);

	useEffect(() => {
		//setting form name based on first and last name from login
		setRequisitionState((prevRequisitionState) => ({
			...prevRequisitionState,
			requisitionerName: `${firstName} ${lastName}`,
		}));
	}, [firstName, lastName]);

	useEffect(() => {
		//if no classes have more than 10 students, then it is smallRequisition.classes = true
		if (chosenClasses.length > 0) {
			const smallRequisitionCopy = { ...smallRequisition };
			if (chosenClasses.every((chosenClass) => chosenClass.csize < 10)) {
				smallRequisitionCopy.classes = true;
			} else {
				smallRequisitionCopy.classes = false;
			}
			setSmallRequisition(smallRequisitionCopy);
		}
	}, [chosenClasses]);

	const itemsChosenBool = chosenItems.length > 0;

	useEffect(() => {
		//if all items are type OPEN, then it is a small requisition
		if (itemsChosenBool) {
			const smallRequisitionCopy = { ...smallRequisition };
			if (chosenItems.every((chosenItem) => chosenItem.itmtyp === "OPEN")) {
				smallRequisitionCopy.items = true;
			} else {
				smallRequisitionCopy.items = false;
			}
			setSmallRequisition(smallRequisitionCopy);
		}
	}, [chosenItems, itemsChosenBool]);

	const submitDisabled =
		!(
			chosenItems.length > 0 &&
			chosenClasses.length > 0 &&
			requisitionState.requisitionerName !== "" &&
			startDate !== null &&
			endDate !== null
		) || submitLoading;

	const handleChange = (event) => {
		const value = event.target.value;
		setRequisitionState({ ...requisitionState, [event.target.name]: value });

		if (requisitionError[event.target.name] === true) {
			setRequisitionError({ ...requisitionError, [event.target.name]: false });
		}
	};

	const handleAgree = () => {
		if (teamTeachingAgree === false && chosenItems.length > 0) {
			chosenItems.forEach((item) => {
				if (item.team_teaching === "Y") {
					item.repeat = true;
				}
			});
		}
		setTeamTeachingAgree(!teamTeachingAgree);
		setDisplayTeamTeachingDialog(false);
		setChosenClasses([]);
	};

	const handleDisagree = () => {
		const updatedChosenItems = chosenItems.map((item) => {
			return {
				...item,
				repeat: false,
			};
		});
		setChosenItems(updatedChosenItems);
		setDisplayTeamTeachingDialog(false);
	};

	const handleTeamTeachingDialog = () => {
		setDisplayTeamTeachingDialog(true);
	};

	const handleSubmit = () => {
		const totalRating = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "RATE" && item.sub_type === "C") {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const totalOpen = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "OPEN" && item.sub_type === "C") {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const ttRating = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "RATE" && item.repeat === true) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const ttOpen = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "OPEN" && item.repeat === true) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const uqOpen = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "OPEN" && item.sub_type === "U") {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const uqRating = sortedChosenItems.reduce((acc, item) => {
			if (item.itmtyp === "RATE" && item.sub_type === "U") {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);
		const rptits = ttRating + ttOpen;
		let requisition = {};
		let classArray = [];
		let newClass = {};
		let itemsArray = [];
		let newItem = {};
		let uniqueItemsArray = [];

		getReqnum(token)
			.then((reqnum) => {
				setReqnum(reqnum);
				requisition = {
					reqnum: reqnum[0],
					catalog: "GFC",
					rqdate: dayjs(date).format("YYYY-MM-DD"),
					totrtg: totalRating,
					totopn: totalOpen,
					uqrtng: uqRating,
					uqopen: uqOpen,
					rptits: rptits,
					blkid1: blkId1,
					blkid2: "",
					blkid3: blkId3,
					raddr1: requisitionState.requisitionerName,
					raddr2: raddr2.substring(0, 30),
					raddr3: "",
					userid: userid,
					ref1: "",
					ref2: "",
					ref3: "",
					start_date: dayjs(startDate).format("YYYY-MM-DD"),
					end_date: dayjs(endDate).format("YYYY-MM-DD"),
				};
				//if start date is today, console.log a warning message
				if (dayjs(startDate).isSame(dayjs(date), "day")) {
					setAddRequisitionDialogString(
						"***IMPORTANT: You are submitting a survey the same day it is scheduled to open. If you want to ensure that this survey opens promptly, please email TSS directly. This survey will not be set up until classes in the survey have been released. To release your classes, go to Manage Requisitions."
					);
				}
				let classids = chosenClasses.map((classItem) => classItem.classid);
				let uniqueClassids = [...new Set(classids)];
				uniqueClassids.forEach((classid) => {
					let count = 0;
					chosenClasses.forEach((classItem) => {
						if (classItem.classid === classid) {
							count++;
							if (count > 1) {
								classItem.csize = 0;
							}
						}
					});
				});

				if (teamTeachingAgree) {
					let classids = chosenClasses.map((classItem) => classItem.classid);
					let uniqueClassids = [...new Set(classids)];
					let length = uniqueClassids.length;
					let clsnumArray = Array.from({ length: length }, (v, i) => (i + 1) * 10 + 1);
					let classidToIndex = {};
					for (let i = 0; i < length; i++) {
						classidToIndex[uniqueClassids[i]] = i;
					}
					let currentIndices = {};
					for (let i = 0; i < length; i++) {
						currentIndices[uniqueClassids[i]] = 0;
					}
					chosenClasses.forEach((classItem) => {
						let index = classidToIndex[classItem.classid];
						let clsnumOffset = clsnumArray[index] + currentIndices[classItem.classid];
						currentIndices[classItem.classid] += 1;
						classItem.clsnum = clsnumOffset;
					});
				}
				chosenClasses.forEach((classItem, index) => {
					let csize = classItem.csize;
					let gcSize =
						csize >= 1 && csize <= 15
							? 1
							: csize >= 16 && csize <= 35
							? 2
							: csize >= 36 && csize <= 100
							? 3
							: csize >= 100
							? 4
							: 0;
					newClass = {
						reqnum: Number(reqnum[0]),
						clsnum: teamTeachingAgree ? classItem.clsnum : index + 1,
						classid: classItem.classid,
						acyear: classItem.acyear,
						faculty: classItem.faculty,
						dept_id: classItem.dept,
						empid: classItem.empid,
						name: classItem.name,
						ctitle: classItem.ctitle,
						csize: classItem.csize,
						clevel: Array.from(classItem.cnumber)[0],
						gcsize: gcSize,
						tydate: null,
						qtally: null,
						status: "none",
						rfdate: null,
						rpdate: null,
						examdate: classItem.examdate,
						term: classItem.term,
						on_line: "Yes",
						rank: classItem.rank,
						rel_date: null,
						original: null,
						survey_start: classItem.survey_start
							? dayjs(classItem.survey_start).format("YYYY-MM-DD")
							: dayjs(startDate).format("YYYY-MM-DD"),
						survey_end: classItem.survey_end
							? dayjs(classItem.survey_end).format("YYYY-MM-DD")
							: dayjs(endDate).format("YYYY-MM-DD"),
						comb_classes: classItem.comb_classes.join(","),
						description: classItem.description,
						ctype: classItem.ctype,
					};
					classArray.push(newClass);
				});
				sortedChosenItems.forEach((item, index) => {
					if (item.sub_type === "C") {
						if (
							(item.itemno >= 1 && item.itemno <= 18) ||
							(item.itemno >= 105 && item.itemno <= 122)
						) {
							newItem = {
								reqnum: Number(reqnum[0]),
								itemno: item.itemno,
								q_order: index + 1,
								blkmem2: "No",
								repeat: teamTeachingAgree ? (item.repeat ? "Yes" : "No") : "No",
							};
						} else {
							newItem = {
								reqnum: Number(reqnum[0]),
								itemno: item.itemno,
								q_order: index + 1,
								blkmem2: "No",
								repeat: teamTeachingAgree ? (item.repeat ? "Yes" : "No") : "No",
								custom_cat: item.custom_cat ? item.custom_cat.split(" ")[0] : null,
							};
						}

						if (blkId1 !== "" && blkId1 !== null) {
							blkItemsBlkIds[blkId1].forEach((blkItem) => {
								if (blkItem.itemno === item.itemno) {
									newItem.blkmem1 = "Yes";
									newItem.blkmem3 = "No";
								}
							});
						}
						if (blkId3 !== "" && blkId3 !== null) {
							blkItemsBlkIds[blkId3].forEach((blkItem) => {
								if (blkItem.itemno === item.itemno) {
									newItem.blkmem1 = "No";
									newItem.blkmem3 = "Yes";
								}
							});
						}
						itemsArray.push(newItem);
					} else if (item.sub_type === "U") {
						newItem = {
							reqnum: Number(reqnum[0]),
							itemno: item.itemno,
							cat_id: 10,
							catalog: "GFC",
							sub_type: "U",
							itmtyp: item.itmtyp,
							addate: dayjs(date).format("YYYY-MM-DD"),
							text: item.text,
							team_teaching: "Y",
							repeat: teamTeachingAgree ? (item.repeat ? "Yes" : "No") : "No",
							blkmem1: "No",
							blkmem2: "No",
							blkmem3: "No",
							q_order: index + 1,
							scale: item.scale,
						};
						uniqueItemsArray.push(newItem);
					}
				});
			})
			.then(() => {
				postReqToDB(requisition, classArray, itemsArray, uniqueItemsArray);
			})
			.catch((error) => {
				console.error("Error in getReqnum:", error);
			});
	};

	const postReqToDB = (requisition, classArray, itemsArray, uniqueItemsArray) => {
		setSubmitLoading(true);
		requisitionsMutation.mutate(requisition, {
			onSuccess: () => {
				classesMutation.mutate(classArray, {
					onSuccess: () => {
						itemsListsMutation.mutate(itemsArray, {
							onSuccess: () => {
								if (uniqueItemsArray.length > 0) {
									itemsListsMutation.mutate(uniqueItemsArray, {
										onSuccess: () => {
											itemsUniqueMutation.mutate(uniqueItemsArray, {
												onSuccess: () => {
													setChosenClasses([]);
													setChosenItems([]);
													setBlkId1("");
													setBlkId3("");
													setBlockIdListValue("");
													setBlockItems([]);
													setTeamTeachingAgree(false);
													setStartDate(null);
													setEndDate(null);
													setOpenSubmitDialog(true);
													setSubmitLoading(false);
													setAddRequisitionDialogString(
														"IMPORTANT: This survey will not be set up until classes in the survey have been released. To release your classes, go to Manage Requisitions."
													);
												},
												onError: (error) => {
													console.error("Error in itemsUniqueMutation:", error);
													setSubmitLoading(false);
												},
											});
										},
										onError: (error) => {
											console.error("Error in itemsListsMutation for uniqueItemsArray:", error);
											setSubmitLoading(false);
										},
									});
								} else {
									setOpenSubmitDialog(true);
									setSubmitLoading(false);
								}
							},
							onError: (error) => {
								console.error("Error in itemsListsMutation for itemsArray:", error);
								setSubmitLoading(false);
							},
						});
					},
					onError: (error) => {
						console.error("Error in classesMutation:", error);
						setSubmitLoading(false);
					},
				});
			},
			onError: (error) => {
				console.error("Error in requisitionsMutation:", error);
				setSubmitLoading(false);
			},
		});
	};

	const handleAgreeAndContinueModifiedItems = () => {
		setModifiedItems(false);
		setOpenConfirmItemsModifiedDialog(false);
		if (
			modifiedItemsOrBlocks &&
			modifiedItemsOrBlocks !== null &&
			modifiedItemsOrBlocks !== undefined
		) {
			if (modifiedItemsOrBlocks === "items") {
				setOpenItemModal(true);
			} else if (modifiedItemsOrBlocks === "blocks") {
				setOpenBlockModal(true);
				setBlockItems([]);
				setBlockIdListValue("");
			}
		}
	};

	const handleCancelAndReturnModifiedItems = () => {
		setOpenConfirmItemsModifiedDialog(false);
	};

	return (
		<ClassesContext.Provider value={{ chosenClasses, setChosenClasses }}>
			<ClassIdsContext.Provider value={{ classIds, setClassIds }}>
				<TeamTeachingContext.Provider value={teamTeachingAgree}>
					<StartDateContext.Provider value={startDate}>
						<EndDateContext.Provider value={endDate}>
							<DeptIdContext.Provider value={{ deptId, setDeptId }}>
								<TermContext.Provider value={{ termValue, setTermValue }}>
									<SmallRequisitionContext.Provider
										value={{ smallRequisition, setSmallRequisition }}
									>
										<WebClassesContext.Provider
											value={{ classesClassIdsArray, setClassesClassIdsArray }}
										>
											<Grid
												container
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "flex-start",
													width: "90vw",
													margin: "auto",
												}}
											>
												<Grid
													sx={{
														marginTop: "5%",
														display: "flex",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "90%",
														},
														gap: "10px",
														justifyContent: "center",
													}}
												>
													<RequisitionCalendar
														label="Requisition Date"
														value={dayjs(date) || ""}
														onChange={(newValue) => setDate(newValue)}
														minDate={dayjs(date)}
														maxDate={dayjs(date)}
														disabled={true}
														width="100%"
													/>
													<FormControl>
														<FormLabel>Requisitioner's Name</FormLabel>
														<TextInput
															value={requisitionState.requisitionerName || ""}
															error={requisitionError.requisitionerName}
															name="requisitionerName"
															width="100%"
															onChange={handleChange}
														/>
													</FormControl>
													<RequisitionCalendar
														label="Survey Start Date"
														value={startDate || ""}
														width="100%"
														onChange={(newValue) => {
															setStartDate(newValue);
															const newValuePlus14 = dayjs(newValue).add(14, "day");
															setEndDate(newValuePlus14);
														}}
														minDate={dayjs(date)}
													/>

													<RequisitionCalendar
														label="Survey End Date"
														width="100%"
														value={endDate || ""}
														onChange={(newValue) => setEndDate(newValue)}
														minDate={startDate}
													/>
												</Grid>

												<Grid
													sx={{
														//add pointer change on hover
														marginTop: "20px",
														display: "flex",
														"&:hover": {
															cursor: "pointer",
															outline: "1px solid #275d38",
														},
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														justifyContent: "center",
														gap: "10px",
														border: "1px solid black",
														borderRadius: "5px 5px 5px 5px",
														borderColor: teamTeachingAgree ? "primary.main" : "grey.400",
													}}
													onClick={handleTeamTeachingDialog}
												>
													<BasicSwitch
														label="Team Teaching"
														checked={teamTeachingAgree}
														setChecked={setTeamTeachingAgree}
													/>
												</Grid>
												<Grid
													ref={containerRef}
													sx={{
														marginTop: "2%",
														display: "flex",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														justifyContent: "center",
														gap: "10px",
														border:
															chosenItems.length > 0 ? "1px solid #275d38" : "1px solid #E0E0E0",
														borderBottom: "none",
														borderRadius: "5px 5px 0px 0px",
													}}
												>
													<Box sx={{ margin: "5px", display: "flex", gap: "10px" }}>
														<RequisitionModalBlock
															blockItemsStatus={blockItemsStatus}
															listValue={blockIdListValue}
															setListValue={setBlockIdListValue}
															chosenItems={chosenItems}
															setChosenItems={setChosenItems}
															items={itemsForSingleQuestion}
															setItems={setItemsForSingleQuestion}
															blkId1={blkId1}
															setBlkId1={setBlkId1}
															blkId3={blkId3}
															setBlkId3={setBlkId3}
															blockItems={blockItems}
															setBlockItems={setBlockItems}
															blocksDisabled={isChooseBlockOrItemDisabled}
															allBlockItems={allBlockItems}
															allBlockItemsLoading={allBlockItemsLoading}
															allBlockItemsFetching={allBlockItemsFetching}
															refetchBlockItems={refetchBlockItems}
															modifiedItems={modifiedItems}
															setModifiedItems={setModifiedItems}
															openConfirmItemsModifiedDialog={openConfirmItemsModifiedDialog}
															setOpenConfirmItemsModifiedDialog={setOpenConfirmItemsModifiedDialog}
															open={openBlockModal}
															setOpen={setOpenBlockModal}
															setModifiedItemsOrBlocks={setModifiedItemsOrBlocks}
														/>
														<RequisitionModalItem
															chosenItems={chosenItems}
															setChosenItems={setChosenItems}
															setTeamTeachingAgree={setTeamTeachingAgree}
															items={itemsForSingleQuestion}
															setItems={setItemsForSingleQuestion}
															itemsDisabled={isChooseBlockOrItemDisabled}
															sortedChosenItems={sortedChosenItems}
															setSortedChosenItems={setSortedChosenItems}
															modifiedItems={modifiedItems}
															setModifiedItems={setModifiedItems}
															openConfirmItemsModifiedDialog={openConfirmItemsModifiedDialog}
															setOpenConfirmItemsModifiedDialog={setOpenConfirmItemsModifiedDialog}
															open={openItemModal}
															setOpen={setOpenItemModal}
															setModifiedItemsOrBlocks={setModifiedItemsOrBlocks}
														/>
													</Box>
												</Grid>

												<Grid
													sx={{
														display: "flex",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														justifyContent: "center",
														gap: "10px",
														height: "400px",
													}}
												>
													<ItemsTable
														setOpen={setDisplayTeamTeachingDialog}
														open={displayTeamTeachingDialog}
														chosenItems={chosenItems}
														setChosenItems={setChosenItems}
														setTeamTeachingAgree={setTeamTeachingAgree}
														teamTeachingAgree={teamTeachingAgree}
														greenBorder={true}
														sortedChosenItems={sortedChosenItems}
														setSortedChosenItems={setSortedChosenItems}
														modifiedItems={modifiedItems}
														setModifiedItems={setModifiedItems}
													/>
												</Grid>

												<Grid
													sx={{
														marginTop: "2%",
														display: "flex",
														flexDirection: "column",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														justifyContent: "center",
														border:
															chosenClasses.length > 0 ? "1px solid #275d38" : "1px solid #E0E0E0",
														borderBottom: "none",
														borderRadius: "5px 5px 0px 0px",
													}}
												>
													<Box
														sx={{
															margin: "5px",
															display: "flex",
															justifyContent: "center",
														}}
													>
														<RequisitionModalClasses
															itemsChosenBool={itemsChosenBool}
															teamTeachingAgree={teamTeachingAgree}
															setTeamTeachingAgree={setTeamTeachingAgree}
															setDeptId={setDeptId}
															setTermValue={setTermValue}
															termValue={termValue}
															termsList={termsList}
															filterKey={muiTableKey}
															setFilterKey={setMuiTableKey}
															startValueDefault={startDate}
															endValueDefault={endDate}
															minDate={startDate}
															maxDate={endDate}
															setRaddr2={setRaddr2}
														/>
													</Box>
												</Grid>
												<Grid
													sx={{
														display: "flex",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														height: "400px",
														justifyContent: "center",
													}}
												>
													<ClassesTable
														greenBorder={true}
														startValueDefault={startDate}
														endValueDefault={endDate}
														minDate={startDate}
														maxDate={endDate}
													/>
												</Grid>

												<Grid
													sx={{
														marginTop: "1%",
														display: "flex",
														width: {
															xs: "100%",
															sm: "100%",
															md: "100%",
															lg: "1100px",
														},
														justifyContent: "center",
													}}
												>
													<Grid sx={{ width: "100%" }}>
														<Button
															disabled={submitDisabled}
															name="submitButton"
															onClick={handleSubmit}
															variant="contained"
															sx={{
																width: "100%",
																marginTop: "0px",
																height: "50px",
																marginBottom: "50px",
															}}
														>
															{submitLoading ? (
																<CircularProgress size={20} sx={{ marginRight: "10px" }} />
															) : (
																`Submit Requisition`
															)}
														</Button>
													</Grid>
												</Grid>
												<TeamTeachingDialog
													open={displayTeamTeachingDialog}
													handleAgree={handleAgree}
													handleDisagree={handleDisagree}
													setTeamTeachingAgree={setTeamTeachingAgree}
												/>
												<SubmitReqDialog
													confirmItemsModified
													open={openConfirmItemsModifiedDialog}
													setOpen={setOpenConfirmItemsModifiedDialog}
													dialogTitleString={"Confirmation Required"}
													dialogMessageString={warningMessageModifiedItems}
													handleAgreeAndContinue={handleAgreeAndContinueModifiedItems}
													handleCancelAndReturn={handleCancelAndReturnModifiedItems}
												/>
												<SubmitReqDialog
													requisitionSubmission
													open={openSubmitDialog}
													setOpen={setOpenSubmitDialog}
													dialogTitleString={"Requisition " + reqnum + " Submitted"}
													dialogMessageString={addRequisitionDialogString}
												/>
											</Grid>
										</WebClassesContext.Provider>
									</SmallRequisitionContext.Provider>
								</TermContext.Provider>
							</DeptIdContext.Provider>
						</EndDateContext.Provider>
					</StartDateContext.Provider>
				</TeamTeachingContext.Provider>
			</ClassIdsContext.Provider>
		</ClassesContext.Provider>
	);
}
