import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishIcon from "@mui/icons-material/Publish";
import SingleRequisitionModal from "./Modals/SingleRequisitionModal";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function RequisitionCard(props) {
	const [openSingleRequisitionModal, setOpenSingleRequisitionModal] = useState(false);

	return (
		<div>
			<Card sx={{ minWidth: 275 }} variant="outlined">
				<CardContent>
					<Typography variant="h5" component="div" gutterBottom>
						Requisition #{props.reqnum}
					</Typography>
					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							Start Date:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div">
							{props.startDate}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							End Date:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div" gutterBottom>
							{props.endDate}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							Block:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div" gutterBottom>
							{props.block}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							Items in Block:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div" gutterBottom>
							{props.items.length}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							Classes in Requisition:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div" gutterBottom>
							{props.classes.length}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", gap: "5px" }}>
						<Typography sx={{ fontSize: 16 }} component="div" color="text.secondary">
							Team Teaching:
						</Typography>
						<Typography sx={{ fontSize: 16 }} component="div" gutterBottom>
							{props.rptits > 0 ? "Yes" : "No"}
						</Typography>
					</Box>
				</CardContent>
				<CardActions
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Button
						/* disabled */
						size="small"
						/* onClick={setOpenSingleRequisitionModal(true)} */ name="view"
						onClick={() => setOpenSingleRequisitionModal(true)}
					>
						<VisibilityIcon /* sx={{ color: "orange" }} */ />
					</Button>
					<Button disabled size="small">
						<EditIcon /* sx={{ color: "orange" }} */ />
					</Button>
					<Button disabled size="small">
						<PublishIcon />
					</Button>
					<Button size="small" onClick={() => props.deleteRequisition(props.reqnum)}>
						<DeleteIcon />
					</Button>
				</CardActions>
				<SingleRequisitionModal
					open={openSingleRequisitionModal}
					setOpen={setOpenSingleRequisitionModal}
					requisition={props.requisition}
					updateRequisition={props.updateRequisition}
					deleteClasses={props.deleteClasses}
				/>
			</Card>
		</div>
	);
}
