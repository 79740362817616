import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Button  } from '@mui/material';
import { createSearchParams, useNavigate } from "react-router-dom";
import {BsSearch} from "react-icons/bs";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';


function SearchSPOTReports() {

  const [instData, setInstData] = useState([]);
  const [didFetch, setDidFetch] = useState(false);

  const [subject,setSubject] = useState("")
  const [catalogue,setCatalogue] = useState("")
  const [instName,setInstName] = useState("");

  var [subjectError, setSubjectError] = useState(false)
  var [nameError, setNameError] = useState(false)
  const navigate = useNavigate();


  useEffect(() => {

    async function getInstructors(){
      let url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/all_inst/")
      const response = await fetch(url,{ headers: {'Authorization': `Token ${sessionStorage.token}`}})
      const data = await response.json()
      setInstData(data)
    }
    
    if(!didFetch){
      setDidFetch(true)
      getInstructors()
    }
  },[didFetch,instData]);



function formatName(name) {
  if (name === '') {return ''}
  const nameArray = name.trim().split(/\s+/); // Split the name into an array of words
  if (nameArray.length === 1) {return nameArray[0]; } 
  else if (nameArray.length === 2) {return `${nameArray[1]}, ${nameArray[0]}`} 
  else {
    const lastName = nameArray[nameArray.length - 1];  // Get the last word as the last name
    const firstName = nameArray.slice(0, -1).join(' '); // Join all but the last word as the first name
    return `${lastName}, ${firstName}`; // Return the formatted name
  }
}


function getEMPID(name){
  const employee = instData.find((item) => item.NAME === name);
  return employee ? employee.EMPID : '?'; 
}


function courseSearch(){
  if (subject.length > 1) {
    setSubjectError(false)
    navigate({
      pathname: "/SPOTreportSearch",
      search: createSearchParams({
        course: subject.concat(' ',catalogue).toString(),
        inst: '?',
        empid: '?'
      }).toString()
    })
  }
  else{
    setSubjectError(true)
    setNameError(false)
  }
}

function instSearch(){
  if (instName.length > 1){
    setNameError(false)
    navigate({
      pathname: "/SPOTreportSearch",
      search: createSearchParams({
        course: '?',
        inst: formatName(instName).toString(),
        empid: getEMPID(instName).toString()
      }).toString()
    })
  }else{
    setNameError(true)
    setSubjectError(false)
  }
}

   
  return (
    <div style ={{ height: '100%', maxHeight: 'calc(100% - 160px)'}}>

      <div className='Bread Crumbs' style ={{paddingLeft: '10px',}}>
        <Breadcrumbs separator=">" style={{marginTop:'10px',marginBottom:'20px'}}>
            <Link underline="always" color='#275d38' href='/'>
              <Typography color="#275d38" sx={{display: 'flex', alignItems: 'center' }}> 
                <HomeIcon sx={{ mr: 0.5 }} />
                Home
              </Typography>
            </Link>
            <Typography color="text.primary">Reporting</Typography>
            <Typography color="text.primary">Search SPOT Reports</Typography>
          </Breadcrumbs>
      </div>


      <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>

        <Typography variant='h2'>Search SPOT Reports</Typography>
        <Typography variant='subtitle1' style={{width:'50%', paddingTop:'20px'}}>
          Explore all publicly available SPOT reports administered by the University of Alberta. 
          Easily search through by subjects and catalog combinations, as well as by an instructor's 
          name.
        </Typography>

        <Box style={{padding:'50px'}}>

          <Typography variant='h5'>Specific Subject or Course</Typography>

          <Box sx={{display: 'flex', flexDirection: 'row',padding:'20px'}}>
            <TextField label="Subject" value={subject} helperText="ex: ''MATH''" size="small"
              style={{width:'200px',height:'40px',paddingRight:'5px'}}
              onChange={(e) => {setSubject(e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase());}}
            />
            <TextField label="Catalogue #" value={catalogue} helperText="ex: ''100''" size="small"
              style={{width:'200px',height:'40px',paddingRight:'5px'}}
              onChange={(e) => {setCatalogue(e.target.value.replace(/\D/g, ''));}}
            />
            <Button variant="contained"
              style={{backgroundColor: "#275d38", fontSize: "1.5rem", height:'40px',padding:'5px'}}
              onClick={courseSearch}
            >
              <BsSearch />
            </Button>
          </Box>
          {subjectError && (
                <p style={{ color: "red", textAlign: "center", paddingTop:'10px'}} >
                  Please fill out the subject field.
                </p>
            )}

          <Divider orientation="horizontal" flexItem style={{padding:'20px'}}>
            OR
          </Divider>

          <Typography variant='h5'>Specific Instructor</Typography>

          <Box sx={{display: 'flex', flexDirection: 'row',padding:'20px'}}>
            <Autocomplete freeSolo
              style={{width:'400px',height:'40px',paddingRight:'5px'}}
              options={[...new Set(instData.map((option) => option.NAME))]}
              ListboxProps={{style: {maxHeight: 200, overflowY: 'scroll'}}}
              onChange={(event) => {setInstName(event.target.textContent);}}
              renderInput={(params) => (
                <TextField {...params} value={instName} helperText="ex: ''John Doe''" 
                  label='Instructor Name' size="small"
                  onChange={(event) => {setInstName(event.target.value)}}
                />
              )}
            />
              <Button variant="contained"
                style={{backgroundColor: "#275d38", fontSize: "1.5rem",height:'40px',padding:'5px'}}
                onClick={instSearch}
              >
                <BsSearch />
              </Button>
          </Box>
          {nameError && (
                <p style={{ color: "red", textAlign: "center", paddingTop:'10px'}} >
                  Please fill out the Instructor field.
                </p>
            )}
        </Box>
      </div>
    </div>
  )
}

export default SearchSPOTReports