import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { DialogContentText, Typography } from "@mui/material";
import { getLDAPUserByEmpId } from "../../../../../components/Api/Getters";
import isAuthenticated from "../../../../../components/Utils/isAuthenticated";

const filter = createFilterOptions();

export default function FacultyRankAutocomplete(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [options, setOptions] = React.useState([]);
	const [value, setValue] = React.useState(null);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [inputValue, setInputValue] = useState("");
	const [openSearch, setOpenSearch] = useState(false);
	const [checked, setChecked] = React.useState(false);
	const [readyToAdd, setReadyToAdd] = React.useState(false);

	useEffect(() => {
		if (props.options && props.options.length > 0 && props.currentRow) {
			const roles = props.currentRow.roles;
			const unique = [...new Set(props.options)];
			unique.forEach((element, index) => {
				const newElement = {
					key: index,
					empid: element.empid,
					name: element.name,
					ccid: element.ccid,
					rank: element.rank,
				};
				unique[index] = newElement;
			});
			if (roles && roles.length > 0) {
				const existingRolesList = [];
				roles.forEach((role) => {
					const index = unique.findIndex((element) => element.empid === role.empid);
					const existingRole = unique[index];
					if (index > -1) {
						existingRole.key = index;
						existingRole.role = role.class_role;
						existingRolesList.push(existingRole);
						unique.splice(index, 1);
					}
					props.setExistingRoles(existingRolesList);
				});
			}
			setOptions(unique);
		}
	}, [props.options, props.currentRow]);

	const [dialogValue, setDialogValue] = React.useState({
		firstName: "",
		lastName: "",
		empid: "",
		ccid: "",
		role: "",
	});

	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	async function checkExistingFacultyRank() {
		if (dialogValue.empid.length !== 7) {
			setReadyToAdd(false);
			setErrorMessage("Employee ID must be 7 digits. Prepend with 0s if necessary.");
		} else {
			const existingFacultyRank = props.checkExistingFacultyRank(dialogValue.empid);
			if (existingFacultyRank !== false) {
				setReadyToAdd(false);
				setErrorMessage("Faculty member with this employee ID already exists in our database.");
			} else {
				getLDAPUserByEmpId(token, dialogValue.empid).then((res) => {
					if (res) {
						if (
							res.first_name &&
							res.last_name &&
							res.ccid &&
							res.first_name.length > 0 &&
							res.last_name.length > 0 &&
							res.ccid.length > 0
						) {
							setSearchedLDAP(true);
							setDialogValue({
								...dialogValue,
								firstName: res.first_name,
								lastName: res.last_name,
								ccid: res.ccid,
							});
							console.log(res);

							setReadyToAdd(true);
							setChecked(true);
							setErrorMessage("");
							setSuccessMessage("Ready to add instructor.");
						} else {
							setErrorMessage("Employee ID not found in LDAP.");
						}
					} else {
						setErrorMessage("Employee ID not found in LDAP.");
					}
				});

				//success
			}
		}
	}

	const handleChangeAddInstructor = (fieldName) => (event) => {
		setReadyToAdd(false);
		setChecked(false);
		setErrorMessage("");
		setSuccessMessage("");
		if (fieldName === "empid") {
			setSearchedLDAP(false);
		}
		setDialogValue({
			...dialogValue,
			[fieldName]: event.target.value,
		});
	};

	const handleSubmit = () => {
		setValue({
			name: dialogValue.lastName.trim() + ", " + dialogValue.firstName.trim(),
			ccid: dialogValue.ccid,
			empid: dialogValue.empid,
		});
		setChecked(false);
		setErrorMessage("");
		setReadyToAdd(false);
		setSuccessMessage("");
		handleClose();
	};

	const handleAddInstructorToFacultyRank = async () => {
		const newInstructor = {
			name: dialogValue.lastName.trim() + ", " + dialogValue.firstName.trim(),
			empid: dialogValue.empid,
			ccid: dialogValue.ccid,
			rank: "INSTRUCTOR",
		};
		const success = await props.addInstructorToFacultyRank(newInstructor);
		if (success) {
			handleSubmit();
		} else {
			setSuccessMessage("");
		}
	};

	const handleClose = () => {
		setSearchedLDAP(false);
		setChecked(false);
		setErrorMessage("");
		setSuccessMessage("");
		setDialogValue({
			firstName: "",
			lastName: "",
			empid: "",
			ccid: "",
			role: "",
		});
		setOpenDialog(false);
	};

	const [searchedLDAP, setSearchedLDAP] = useState(false);

	return (
		<React.Fragment>
			<Autocomplete
				value={value}
				onChange={(event, newValue) => {
					if (typeof newValue === "string") {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							setOpenSearch(false);
							setOpenDialog(true);
							setDialogValue({
								name: newValue,
								empid: "",
							});
						});
					} else if (newValue && newValue.inputValue) {
						setOpenSearch(false);
						setOpenDialog(true);
						setDialogValue({
							name: newValue.inputValue,
							empid: "",
						});
					} else {
						setValue(newValue);
						setOpenSearch(false);
						props.setNewRoleValue(newValue);
					}
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					setOpenSearch(newInputValue.length > 2);
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					if (params.inputValue !== "") {
						filtered.push({
							inputValue: params.inputValue,
							name: `Add "${params.inputValue}" as a new instructor?`,
						});
					}

					return filtered;
				}}
				id="free-solo-dialog-demo"
				options={options}
				getOptionLabel={(option) => {
					if (typeof option === "string") {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option.name + " - " + option.empid + " - " + option.ccid;
				}}
				disableClearable
				filterSelectedOptions
				selectOnFocus
				clearOnBlur
				renderOption={(props, option) => (
					<li {...props}>
						{option.name ? option.name : ""}
						{option.empid ? " - " + option.empid + " - " : ""}
						{option.ccid ? option.ccid : ""}
					</li>
				)}
				sx={{ width: "100%" }}
				freeSolo
				open={openSearch}
				renderInput={(params) => <TextField {...params} label="Enter Name, Employee ID, or CCID" />}
			/>
			<Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
				<form>
					<DialogTitle>Add New Instructor</DialogTitle>
					<DialogContent
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: "10px",
						}}
					>
						<DialogContentText>
							{" "}
							NOTE: This form will NOT add this instructor to the course. After adding the
							instructor to our database through this form, you will need to continue on in the
							previous step and add them to the course.{" "}
						</DialogContentText>
						<TextField
							required
							margin="dense"
							id="empid"
							value={dialogValue.empid || ""}
							onChange={handleChangeAddInstructor("empid")}
							label="Employee ID"
							variant="standard"
						/>
						{searchedLDAP && (
							<>
								<TextField
									autoFocus
									required
									margin="dense"
									id="lastName"
									value={dialogValue.lastName || ""}
									onChange={handleChangeAddInstructor("lastName")}
									label="Last Name"
									type="text"
									variant="standard"
								/>
								<TextField
									required
									margin="dense"
									id="firstName"
									value={dialogValue.firstName || ""}
									onChange={handleChangeAddInstructor("firstName")}
									label="First Name"
									type="text"
									variant="standard"
								/>

								<TextField
									required
									margin="dense"
									id="ccid"
									disabled
									value={dialogValue.ccid || ""}
									/* onChange={handleChangeAddInstructor("ccid")} */
									label="CCID"
									type="text"
									variant="standard"
								/>
							</>
						)}
					</DialogContent>
					<DialogActions sx={{ display: "flex", justifyContent: "flex-start", pl: 2 }}>
						{checked ? (
							readyToAdd ? (
								<Button
									disabled={
										(dialogValue.lastName && dialogValue.lastName.length === 0) ||
										(dialogValue.firstName && dialogValue.firstName.length === 0) ||
										(dialogValue.empid && dialogValue.empid.length === 0) ||
										(dialogValue.ccid && dialogValue.ccid.length === 0)
									}
									onClick={handleAddInstructorToFacultyRank}
								>
									{" "}
									Add
								</Button>
							) : (
								<Button disabled>Add</Button>
							)
						) : (
							<Button
								variant="outlined"
								disabled={!dialogValue.empid}
								onClick={checkExistingFacultyRank}
							>
								Check
							</Button>
						)}
						<Button onClick={handleClose}>Cancel</Button>
						<Typography sx={{ color: "green" }}>{successMessage}</Typography>
						<Typography sx={{ color: "red" }}>{errorMessage}</Typography>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
}
