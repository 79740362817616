export const rolesDescription = [
	{
		id: "AI",
		name: "Assistant Instructor",
	},
	{
		id: "CC",
		name: "Course Coordinator",
	},
	{
		id: "CO",
		name: "Co-Instructor",
	},
	{
		id: "DA",
		name: "Department Administrator",
	},
	{
		id: "GL",
		name: "Guest Lecturer",
	},
	{
		id: "LA",
		name: "Lab Coordinator",
	},
	{
		id: "PG",
		name: "Primary Grade Approver",
	},
	{
		id: "PI",
		name: "Primary Instructor",
	},
	{
		id: "SG",
		name: "Secondary Grade Approver",
	},
	{
		id: "TA",
		name: "Teaching Assistant",
	},
	{
		id: "TC",
		name: "Technician",
	},
];

export const roles = ["PI", "CO", "AI", "TA", "PG", "SG", "CC", "LA", "GL", "TC", "DA"];
