import { useQuery } from "react-query";
import { getAllBlockItems } from "../components/Api/Getters";
import { getBlockIdsByDept } from "../components/Api/Getters";

export const useBlockItemsQuery = (token, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		...options,
	};

	return useQuery(["allBlockItemsQuery", token], () => getAllBlockItems(token), queryOptions);
};

export const useBlockIdsByDeptQuery = (token, dept_id, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		...options,
	};

	return useQuery(
		["useBlockIdsByDeptQuery", token],
		() => getBlockIdsByDept(token, dept_id),
		queryOptions
	);
};

export const useAllBlockItems = (allBlockItemsData, allItemsData) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!allBlockItemsData && !!allItemsData,
	};
	return useQuery(
		"allBlockItems",
		async () => {
			if (allBlockItemsData && allItemsData) {
				// Create a map of allItems by itemno
				const allItemsMap = allItemsData.reduce((map, item) => {
					map[item.itemno] = item;
					return map;
				}, {});

				const updatedAllBlockItems = allBlockItemsData.reduce((r, blockItem) => {
					const item = allItemsMap[blockItem.itemno];
					const updatedBlockItem = item ? { ...blockItem, ...item } : blockItem;
					r[blockItem.blkid] = [...(r[blockItem.blkid] || []), updatedBlockItem];
					return r;
				}, {});

				return updatedAllBlockItems;
			}
		},
		queryOptions
	);
};
