import { useQuery } from "react-query";
import { getTerms } from "../components/Api/Getters";
import { useEffect, useState } from "react";

export const useTermsQuery = (token) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token,
	};
	return useQuery(
		"terms",
		async () => {
			const terms = await getTerms(token);
			const termArray = [];
			if (terms.length > 0) {
				terms.forEach((term) => {
					termArray.push(term.term);
				});
			}

			return termArray;
		},
		queryOptions
	);
};

export const useTermsQueryFull = (token) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token,
	};
	return useQuery(
		"terms",
		async () => {
			const terms = await getTerms(token);
			const termArray = [];
			if (terms.length > 0) {
				terms.forEach((term) => {
					let termObj = {
						name: term.desc_term + "(" + term.term + ")",
						term: term.term,
						desc: term.desc_term,
						start: term.s_date,
						end: term.e_date,
					};
					termArray.push(termObj);
				});
			}

			return termArray;
		},
		queryOptions
	);
};
