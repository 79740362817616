import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import { useState } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	height: "50%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
};

export default function ExamplesModal(props) {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div>
			<Button
				variant="contained"
				onClick={handleOpen}
				disabled={props.itemsChosenBool}
				sx={{ width: "100%", height: "60px" }}
			>
				VIEW ALL QUESTION SETS
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ display: "flex" }}
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "10px",
							margin: "10px",
						}}
					>
						<Box>
							<Typography variant="h6">Suggested Questions</Typography>
							<Typography sx={{ width: "100%" }}>
								1. The instructor is helpful in answering questions. <br /> 2.
								Class time is used effectively.
								<br /> 3. The classroom is a comfortable learning environment.
								<br /> 4. The instructor presents the material in an interesting
								and helpful manner. <br />
								5. The instructor gives clear explanations. <br />
								6. The instructor stresses important points in lectures or
								discussions. <br />
								7. How can the instructor improve the teaching of this course?{" "}
								<br />
								8. Which aspects of the course do you like the best? <br />
								9. Which aspects of the course do you like the least? <br />
								10. Additional comments for the instructor?
							</Typography>
						</Box>
						<Box>
							<Typography variant="h6">Start-Stop-Continue</Typography>
							<Typography sx={{ width: "100%" }}>
								1. What should I (the instructor) start doing? <br />
								2. What should I (the instructor) stop doing? <br />
								3. What should I (the instructor) continue doing?
							</Typography>
							<Box sx={{ marginTop: "46px" }}>
								<Typography variant="h6">One Question</Typography>
								<Typography sx={{ width: "100%" }}>
									Please share any feedback on how the course is going so far.
								</Typography>
							</Box>
						</Box>
						<Box>
							<Typography variant="h6">Three Open Questions</Typography>
							<Typography sx={{ width: "100%" }}>
								1. Which aspects of the course do you like the best? <br />
								2. Which aspects of the course do you like the least?
								<br />
								3. How can the instructor improve the teaching of this course?
							</Typography>
							<Box sx={{ marginTop: "20px" }}>
								<Typography variant="h6">Custom</Typography>
								<Typography sx={{ width: "100%" }}>
									Choose your own questions! <br />
									You may also set custom scales for each question.
								</Typography>
							</Box>
						</Box>
					</Box>
					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							width: "98%",
							alignSelf: "center",
							marginTop: "auto",
							marginBottom: "10px",
						}}
					>
						Close
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
