import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Modal, Typography } from "@mui/material";
import { RequisitionCalendar } from "../../../../../components/Utils/RequisitionCalendar";
import dayjs from "dayjs";
import { useState } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	height: 650,
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 1,
	display: "flex",
	flexDirection: "column",
};

export default function SingleRequisitionModal(props) {
	const handleClose = (event, reason) => {
		/* if (reason && reason == "backdropClick") return; */
		props.setOpen(false);
	};

	const handleEvent = (params, event) => {
		event.stopPropagation();
	};

	const handleStartDateChange = (newValue) => {
		props.updateRequisition(props.requisition.reqnum, newValue, props.requisition.end_date);
	};

	const handleEndDateChange = (newValue) => {
		props.updateRequisition(props.requisition.reqnum, props.requisition.start_date, newValue);
	};

	console.log(props.requisition);

	const [expandedGroups, setExpandedGroups] = useState([]);

	const toggleGroup = (group) => {
		setExpandedGroups((prevExpandedGroups) =>
			prevExpandedGroups.includes(group)
				? prevExpandedGroups.filter((g) => g !== group)
				: [...prevExpandedGroups, group]
		);
	};

	const groupedClasses = props.requisition.classes.reduce((groups, classObj) => {
		const group = classObj.ctitle.split(" ").slice(0, 2).join(" ");
		if (!groups[group]) {
			groups[group] = [];
		}
		groups[group].push(classObj);
		return groups;
	}, {});

	const groupedClassesEntries = Object.entries(groupedClasses);

	groupedClassesEntries.sort((a, b) => {
		// Compare the keys (a[0] and b[0]) alphabetically
		const nameComparison = a[0].localeCompare(b[0]);

		if (nameComparison !== 0) {
			// If the keys are not the same, return the comparison result
			return nameComparison;
		} else {
			// If the keys are the same, compare the second index of the keys
			const aSecondIndex = parseInt(a[0].split(" ")[1], 10);
			const bSecondIndex = parseInt(b[0].split(" ")[1], 10);

			return aSecondIndex - bSecondIndex;
		}
	});

	const getClassIdsForGroup = (group) => {
		return groupedClasses[group].map((classObj) => classObj.classid);
	};

	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
						{props.requisition.reqnum}
					</Typography>
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // This will create a responsive 2-column grid
							gap: 2,
							height: 400,
							overflowY: "scroll",
							border: "4px solid black",
							p: 2,
							borderRadius: 2,
						}}
					>
						{groupedClassesEntries.map(([group, classes], index) => (
							<Box key={index}>
								<Button onClick={() => toggleGroup(group)}>
									{group} ({expandedGroups.includes(group) ? "Collapse" : "Expand"})
								</Button>
								<Button
									onClick={() => {
										const classIdsForGroup = getClassIdsForGroup(group);
										props.deleteClasses(props.requisition.reqnum, classIdsForGroup);
									}}
								>
									Delete
								</Button>
								{expandedGroups.includes(group) &&
									classes.map((classObj, index) => (
										<Box
											key={index}
											sx={{
												display: "flex",
												gap: 2,
											}}
										>
											<Typography variant="body1" component="div">
												{classObj.classid}
											</Typography>

											<Typography variant="body1" component="div">
												{classObj.ctitle}
											</Typography>
											<Typography variant="body1" component="div">
												{classObj.name} : {classObj.rank}
											</Typography>
										</Box>
									))}
							</Box>
						))}
					</Box>
					<RequisitionCalendar
						label="Survey Start Date"
						value={dayjs(props.requisition.start_date) || ""}
						width="100%"
						onChange={handleStartDateChange}
						/* minDate={dayjs()} */
					/>
					<RequisitionCalendar
						label="Survey End Date"
						value={dayjs(props.requisition.end_date) || ""}
						width="100%"
						onChange={handleEndDateChange}
						/* minDate={dayjs()} */
					/>
				</Box>
			</Modal>
		</div>
	);
}
