import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent } from "@mui/material";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { Typography } from "@mui/material";
import { TeamTeachingContext } from "../../../../../../context/RequisitionContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export default function ChooseInstructorDialog(props) {
	const [, setOpen] = React.useState(false);
	const [validChoices, setValidChoices] = useState(true);
	const teamTeachingAgree = useContext(TeamTeachingContext);

	const handleClose = () => {
		setOpen(false);
	};

	const onChange = (event) => {
		const updatedRoles = [...props.currentRow.roles];
		const roleIndex = updatedRoles.findIndex(
			(role) => role.name === event.target.name
		);
		updatedRoles[roleIndex].checked = event.target.checked;
		props.setCurrentRow({ ...props.currentRow, roles: updatedRoles });
	};

	const handleChange = (event) => {
		props.setSelectedRole(event.target.value);
		const updatedRoles = [...props.currentRow.roles];
		/* const roleIndex = updatedRoles.findIndex(
			(role) => role.name === event.target.value
		); */
		updatedRoles.forEach((role) => {
			if (role.name === event.target.value) {
				role.checked = true;
			} else {
				role.checked = false;
			}
		});
		/* updatedRoles[roleIndex].checked = true; */
		props.setCurrentRow({ ...props.currentRow, roles: updatedRoles });
	};

	if (teamTeachingAgree) {
		return (
			<div>
				<Dialog open={props.open} onClose={handleClose} maxWidth="md">
					<FormControl component="fieldset" sx={{ p: 2 }}>
						<Typography
							textAlign={"center"}
							fontSize={18}
							fontWeight={500}
							sx={{ marginBottom: "10px" }}
						>
							Choose Instructors
						</Typography>
						<FormGroup>
							{props.currentRow.roles &&
								props.currentRow.roles.map((role) => {
									return (
										<FormControlLabel
											id={role.empid}
											control={
												<Checkbox
													checked={role.checked}
													onChange={onChange}
													name={role.name}
												/>
											}
											key={role.empid}
											label={role.name + " (" + role.class_role + ")"}
										/>
									);
								})}
						</FormGroup>
					</FormControl>
					<DialogActions>
						<Button color="error" onClick={props.handleInstructorCancel}>
							Cancel
						</Button>
						{teamTeachingAgree && (
							<Button
								disabled={!validChoices}
								onClick={props.handleInstructorSubmit}
								autoFocus
								sx={{ display: teamTeachingAgree }}
							>
								Finish
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</div>
		);
	} else {
		return (
			<div>
				<Dialog open={props.open} onClose={handleClose} maxWidth="md">
					<DialogTitle textAlign={"center"}>
						{"Choose One Instructor"}
					</DialogTitle>

					<DialogContent>
						<FormControl component="fieldset">
							<RadioGroup value={props.selectedRole} onChange={handleChange}>
								{props.currentRow.roles &&
									props.currentRow.roles.map((role) => {
										return (
											<FormControlLabel
												control={<Radio value={role.name} />}
												key={role.empid}
												label={role.name + " (" + role.class_role + ")"}
											/>
										);
									})}
							</RadioGroup>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={props.handleInstructorCancel}>
							Cancel
						</Button>

						<Button
							onClick={props.handleInstructorSubmit}
							autoFocus /*  disabled={!validChoices} */
						>
							Finish
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
