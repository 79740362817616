import axios from "axios";

const url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/");

export async function updateRequisition(
	token,
	{ reqnum, totrtng, totopn, uqrtng, uqopen, rptits }
) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url:
				url +
				"update_requisition"
					.concat(`/${reqnum}`)
					.concat(`/${totrtng}`)
					.concat(`/${totopn}`)
					.concat(`/${uqrtng}`)
					.concat(`/${uqopen}`)
					.concat(`/${rptits}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleClassReleased(token, reqnum, cls_uqid, rel_date) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url:
				url +
				"toggle_class_released".concat(`/${reqnum}`).concat(`/${cls_uqid}`).concat(`/${rel_date}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleBulkClassReleased(token, reqnum, rel_date) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_bulk_class_released".concat(`/${reqnum}`).concat(`/${rel_date}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleBulkGradesSubmitted(token, reqnum, rel_date) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_bulk_grades_submitted".concat(`/${reqnum}`).concat(`/${rel_date}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleExcludeClass(token, classid) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_exclude_class".concat(`/${classid}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateConfirmExamDate(token, classid, examdate) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_examdates_confirm".concat(`/${classid}`).concat(`/${examdate}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleGradesSubmitted(token, reqnum, cls_uqid, rel_date) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url:
				url +
				"toggle_grades_submitted"
					.concat(`/${reqnum}`)
					.concat(`/${cls_uqid}`)
					.concat(`/${rel_date}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleRequisitionLoaded({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_loaded".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleRequisitionReport({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_report".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleRequisitionComments({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_comments".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleRequisitionContEmailed({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_cont_emailed".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleRequisitionInstEmailed({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_inst_emailed".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleTSSDone({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_tss_done".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleTSSIA({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_tss_ia".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleTSSSA({ token, reqnum }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_requisition_tss_sa".concat(`/${reqnum}/`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleMidtermDone(token, mt_id) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_midterm_done".concat(`/${mt_id}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleMidtermIA(token, mt_id) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_midterm_ia".concat(`/${mt_id}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function toggleMidtermSA(token, mt_id) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "toggle_midterm_sa".concat(`/${mt_id}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateMidtermAssigned(token, mt_id, assignee) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_midterm_assigned".concat(`/${mt_id}`).concat(`/${assignee}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateRequisitionDates(token, reqnum, startDate, endDate) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url:
				url +
				"update_requisition_dates"
					.concat(`/${reqnum}`)
					.concat(`/${startDate}`)
					.concat(`/${endDate}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateReqAssigned({ token, reqnum, req_assigned, req_assigned_date }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url:
				url +
				"update_req_assigned"
					.concat(`/${reqnum}`)
					.concat(`/${req_assigned}`)
					.concat(`/${req_assigned_date}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateRepAssigned({ token, reqnum, rep_assigned }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_rep_assigned".concat(`/${reqnum}`).concat(`/${rep_assigned}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateClassIdTeamSize(token, classid, teamsize) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_classid_teamsize".concat(`/${classid}`).concat(`/${teamsize}/`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateTrackToggles({ token, changes }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_track_toggles/",
			data: changes,
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateClasses(token, postClass) {
	return new Promise((resolve, reject) => {
		axios({
			method: "post",
			url: url + "post_classes_update",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			data: postClass,
		})
			.then((response) => {
				if (response.status === 201) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function updateMidtermToggles({ token, changes }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "put",
			url: url + "update_midterm_toggles/",
			data: changes,
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}
