import { useState } from "react";

export default function useToken(setDisabled, setLoginError) {
	async function fetchData(url, token) {
		const response = await fetch(url, {
			headers: { Authorization: `Token ${token}` },
		});
		const data = await response.json();
		return data;
	}

	const getToken = () => {
		const token = sessionStorage.getItem("token");
		return token;
	};

	const [token, setToken] = useState(getToken());

	const saveToken = async (data) => {
		const url = `${process.env.REACT_APP_BACKEND_HOST}/spot/userAuthLevel/${data.user.username}/`;
		try {
			const apiData = await fetchData(url, data.token);
			if (
				data.user.username.includes("gnassar") ||
				data.user.username.includes("babic") ||
				data.user.username.includes("ms4") ||
				data.user.username.includes("mmohseni")
			) {
				sessionStorage.setItem("access", "ADMIN");
			} else {
				sessionStorage.setItem("access", apiData.access);
			}
			sessionStorage.setItem("viewable_empids", apiData.empids);
			sessionStorage.setItem("id", data.user.id);
			sessionStorage.setItem("ccid", data.user.username);
			sessionStorage.setItem("first_name", data.user.first_name);
			sessionStorage.setItem("last_name", data.user.last_name);
			sessionStorage.setItem("email", data.user.email);
			sessionStorage.setItem("token", data.token);
			sessionStorage.setItem("dept_ids", apiData.dept_ids);
			setLoginError("");
			setToken(data.token);
		} catch (err) {
			console.log(err);
			setDisabled(false);
			setLoginError("Error retrieving user information. Please contact TSS.");
		}
	};

	return {
		setToken: saveToken,
		token,
	};
}
