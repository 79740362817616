import * as React from "react";
import {
	Button,
	Box,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormControl,
	FormLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import TableHeader from "../Tables/TableHeader";
import dayjs from "dayjs";
import { getReqnum } from "../../../../../components/Api/Getters";
import LoggingList from "../LoggingList";
import RequisitionDisplay from "../RequisitionDisplay";
import { CircularProgress } from "@mui/material";

//setting to set default dates despite having no exam dates
//get rid of scrolling in classids table
//auto open to dept in modify add classes
//in a req/not in a req column in table classids for sorting/filtering
//release dates and the whole way releasing is handled

export default function GroupClasses(props) {
	const token = sessionStorage.getItem("token");
	const [classIdsWithDates, setClassIdsWithDates] = useState([]);
	const [includeCourseTypes, setIncludeCourseTypes] = useState({
		LEC: false,
		LAB: false,
		SEM: false,
		CLN: false,
		LBL: false,
		LCL: false,
		THE: false,
		CSA: false,
	});
	const courseSpecificBlocks = props.deptBlockIds.filter(
		(blockId) => blockId.course.length > 0
	);
	const blocksWithColon = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype.includes(":") &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null
	);
	const blocksWithSlash = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype.includes("/") &&
			!blockId.ctype.includes(":") &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null
	);
	const deptLECOnlyBlocksLarge = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LEC" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "LARGE"
	);
	const deptLABOnlyBlocksLarge = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LAB" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "LARGE"
	);
	const deptSEMOnlyBlocksLarge = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "SEM" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "LARGE"
	);
	const deptCLNOnlyBlocksLarge = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "CLN" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "LARGE"
	);
	const deptLECOnlyBlocksEither = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LEC" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "EITHER"
	);
	const deptLABOnlyBlocksEither = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LAB" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "EITHER"
	);
	const deptSEMOnlyBlocksEither = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "SEM" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "EITHER"
	);
	const deptCLNOnlyBlocksEither = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "CLN" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "EITHER"
	);
	const deptLECOnlyBlocksSmall = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LEC" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "SMALL"
	);
	const deptLABOnlyBlocksSmall = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "LAB" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "SMALL"
	);
	const deptSEMOnlyBlocksSmall = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "SEM" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "SMALL"
	);
	const deptCLNOnlyBlocksSmall = props.deptBlockIds.filter(
		(blockId) =>
			blockId.ctype === "CLN" &&
			blockId.course.length === 0 &&
			blockId.dept_id !== null &&
			blockId.csize === "SMALL"
	);
	const basic3SPOTBlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3SPOT"
	);
	const basic3OPNBlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3OPN"
	);
	const basic3STABlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3STA"
	);
	const basic3CSJBlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3CSJ"
	);
	const basic3CSJOBlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3CSJO"
	);
	const basic3SLABBlock = props.deptBlockIds.filter(
		(blockId) => blockId.blkid === "3SLAB"
	);
	const blocksDict = {
		large: {
			LEC: deptLECOnlyBlocksLarge,
			LAB: deptLABOnlyBlocksLarge,
			SEM: deptSEMOnlyBlocksLarge,
			CLN: deptCLNOnlyBlocksLarge,
		},
		either: {
			LEC: deptLECOnlyBlocksEither,
			LAB: deptLABOnlyBlocksEither,
			SEM: deptSEMOnlyBlocksEither,
			CLN: deptCLNOnlyBlocksEither,
		},
		small: {
			LEC: deptLECOnlyBlocksSmall,
			LAB: deptLABOnlyBlocksSmall,
			SEM: deptSEMOnlyBlocksSmall,
			CLN: deptCLNOnlyBlocksSmall,
		},
		basic: {
			"3SPOT": basic3SPOTBlock,
			"3OPN": basic3OPNBlock,
			"3STA": basic3STABlock,
			"3CSJ": basic3CSJBlock,
			"3CSJO": basic3CSJOBlock,
			"3SLAB": basic3SLABBlock,
		},
		colon: blocksWithColon,
		slash: blocksWithSlash,
		course: courseSpecificBlocks,
	};
	const customBlocks = props.deptBlockIds.some(
		(blockId) => blockId.dept_id === props.deptId
	);
	const [specialCTypeHandling, setSpecialCTypeHandling] = useState([]);
	const [orderOfProcessing, setOrderOfProcessing] = useState([]);
	const [groupedClasses, setGroupedClasses] = useState([]);
	const [colonBlockBool, setColonBlockBool] = useState(false);
	const [finalizedBlocks, setFinalizedBlocks] = useState([]);
	const [preGeneration, setPreGeneration] = useState([]);
	const [finalClasses, setFinalClasses] = useState([]);
	const [slashBlocks, setSlashBlocks] = useState([]);
	const [beenProcessed, setBeenProcessed] = useState([]);
	const [notBeenProcessed, setNotBeenProcessed] = useState([]);
	const courseBlocks = blocksDict.course;
	const courseBlocksExist = courseBlocks.length > 0;
	const [numberOfIgnoredSections, setNumberOfIgnoredSections] = useState(0);
	const [listOfCtypesForDept, setListOfCtypesForDept] = useState([]);
	const [requisitionsForPost, setRequisitionsForPost] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openRequisitionDisplay, setOpenRequisitionDisplay] = useState(false);

	const userFirstName = sessionStorage.getItem("first_name");
	const userLastName = sessionStorage.getItem("last_name");

	useEffect(() => {
		const newBlocksList = [];
		Object.keys(props.allBlockItems).forEach((key) => {
			const listOfBlockItems = props.allBlockItems[key];
			let totrtg = 0;
			let totopn = 0;
			let uqrtng = 0;
			let uqopen = 0;
			let potentialRptits = 0;
			const deptBlock = props.deptBlockIds.filter(
				(blockId) => blockId.blkid === key
			)[0];
			listOfBlockItems.forEach((blockItem) => {
				if (blockItem.itmtyp === "RATE") {
					totrtg++;
				} else if (blockItem.itmtyp === "OPEN") {
					totopn++;
				}
				if (blockItem.team_teaching === "Y") {
					potentialRptits++;
				}
			});
			if (deptBlock) {
				const newBlock = {
					blkid: key,
					blkid2: null,
					blkid3: deptBlock.blkid3,
					dept_id: deptBlock.dept_id ? deptBlock.dept_id : null,
					catalog: deptBlock.catalog,
					totrtg: totrtg,
					totopn: totopn,
					uqrtng: uqrtng,
					uqopen: uqopen,
					potentialRptits: potentialRptits,
					items: listOfBlockItems,
					raddr1: deptBlock.baddr1,
					raddr2: deptBlock.baddr2,
				};

				newBlocksList.push(newBlock);
			} else {
			}
		});
		setFinalizedBlocks(newBlocksList);
	}, [props.allBlockItems, props.deptBlockIds]);

	useEffect(() => {
		const classIdsWithDatesList = [];
		const classIdsPostRolesAndDates = [];
		props.suitableClassIds.forEach((classId) => {
			const classIdWithDate = assignDate(classId);
			classIdsWithDatesList.push(classIdWithDate);
		});
		const listOfRemoved = [];
		classIdsWithDatesList.forEach((classId) => {
			if (classId.roles.length > 1) {
				classId.teamTeaching = true;
				classIdsPostRolesAndDates.push(classId);
			} else if (classId.roles.length === 1) {
				classId.teamTeaching = false;
				classIdsPostRolesAndDates.push(classId);
			} else if (classId.roles.length === 0) {
				listOfRemoved.push(classId);
			}
		});
		if (listOfRemoved.length > 0) {
			const loggingDictCopy = { ...props.loggingDict };
			listOfRemoved.sort((a, b) => {
				if (a.ctitle < b.ctitle) {
					return -1;
				}
				if (a.ctitle > b.ctitle) {
					return 1;
				}
				return 0;
			});
			loggingDictCopy["No Roles"] = listOfRemoved;
			props.setLoggingDict(loggingDictCopy);
		}
		setClassIdsWithDates(classIdsPostRolesAndDates);
	}, [props.suitableClassIds]);

	console.log(blocksDict);

	useEffect(() => {
		const listOfUsableBlocks = [];
		if (customBlocks) {
			if (blocksDict["colon"].length > 0) {
				setColonBlockBool(true);
				const listOfCustomRules = [];
				blocksDict["colon"].forEach((block) => {
					const pair = block.ctype.split(":");
					const left = pair[0];
					const right = pair[1];
					let total = [];
					if (right.includes("/")) {
						const pair2 = right.split("/");
						const right1 = pair2[0];
						const right2 = pair2[1];
						total[0] = left;
						total[1] = [right1, right2];
					} else {
						total[0] = left;
						total[1] = right;
					}
					total[3] = block;
					listOfCustomRules.push(total);
				});
				setSpecialCTypeHandling(listOfCustomRules);
			}
			if (blocksDict["slash"].length > 0) {
				if (colonBlockBool) {
					alert(
						"You cannot have both colon and slash blocks in the same department. Please contact TSS for assistance."
					);
				} else {
					const listOfSlashBlocks = [];
					blocksDict["slash"].forEach((block) => {
						listOfSlashBlocks.push(block);
					});
					setSlashBlocks(listOfSlashBlocks);
				}
			}
		} else {
			if (props.language === "EN") {
				listOfUsableBlocks.push(basic3SPOTBlock);
				listOfUsableBlocks.push(basic3OPNBlock);
				listOfUsableBlocks.push(basic3STABlock);
			} else {
				listOfUsableBlocks.push(basic3CSJBlock);
				listOfUsableBlocks.push(basic3CSJOBlock);
			}
		}
	}, [customBlocks]);

	useEffect(() => {
		if (specialCTypeHandling.length > 0) {
			const listOfRules = [];
			specialCTypeHandling.forEach((rule) => {
				if (Array.isArray(rule[1])) {
					rule[2] = 1;
				} else {
					rule[2] = 2;
				}
				listOfRules.push(rule);
			});
			listOfRules.sort((a, b) => a[2] - b[2]);
			setOrderOfProcessing(listOfRules);
		}
	}, [specialCTypeHandling]);

	useEffect(() => {
		const tempClassIdsWithDates = [...classIdsWithDates];
		const groupedClasses = tempClassIdsWithDates.reduce((result, classId) => {
			const key = `${classId.subject_id} ${classId.cnumber}`;
			if (!result[key]) {
				result[key] = {
					courses: [],
					LEC: 0,
					LAB: 0,
					SEM: 0,
					CLN: 0,
					LBL: 0,
					LCL: 0,
					THE: 0,
					CSA: 0,
				};
			}
			result[key].courses.push(classId);
			const ctype = classId.ctype;
			if (ctype === "LEC") {
				result[key].LEC++;
			} else if (ctype === "LAB") {
				result[key].LAB++;
			} else if (ctype === "SEM") {
				result[key].SEM++;
			} else if (ctype === "CLN") {
				result[key].CLN++;
			} else if (ctype === "LBL") {
				result[key].LBL++;
			} else if (ctype === "LCL") {
				result[key].LCL++;
			} else if (ctype === "THE") {
				result[key].THE++;
			} else if (ctype === "CSA") {
				result[key].CSA++;
			}
			return result;
		}, {});

		setGroupedClasses(groupedClasses);
	}, [classIdsWithDates]);

	useEffect(() => {
		const notProcessedList = [];
		if (orderOfProcessing.length > 0) {
			//if colon blocks exist
			const tempClasses = [];

			orderOfProcessing.forEach((rule) => {
				const baseCtype = rule[0];
				const lookingForCtypes = rule[1];
				const block = rule[3];
				Object.keys(groupedClasses).forEach((key) => {
					const groupedClass = groupedClasses[key];
					const lecInstructorEmpId = groupedClass.courses.filter(
						(classId) => classId.ctype === "LEC"
					)[0].roles[0].empid;
					let remove = false;
					groupedClass.courses.forEach((classId) => {
						const roles = classId.roles;
						roles.forEach((role) => {
							if (role.empid !== lecInstructorEmpId) {
								remove = true;
							}
						});
						if (
							classId.ctype === "LEC" &&
							classId.csize < 10 &&
							props.deptId !== 79
						) {
							remove = true;
						}
					});
					if (remove) {
						groupedClass.courses.forEach((classId) => {
							notProcessedList.push(classId);
						});
						delete groupedClasses[key];
					}
					if (Array.isArray(lookingForCtypes)) {
						const [first, second] = lookingForCtypes;
						if (groupedClass[first] > 0 && groupedClass[second] > 0) {
							const numberRemoved = groupedClass[first] + groupedClass[second];
							const listOfRemovedClasses = [];
							groupedClass.courses.forEach((classId) => {
								if (classId.ctype !== baseCtype) {
									listOfRemovedClasses.push(classId);
								}
							});
							groupedClass.courses.forEach((classId) => {
								if (classId.ctype === baseCtype) {
									classId.block = block;
									classId.sections_ignored_count = numberRemoved;
									classId.sections_ignored = listOfRemovedClasses.slice(); // Store the list of removed classes
									tempClasses.push(classId);
								}
							});
							delete groupedClasses[key];
						}
					} else {
						if (groupedClass[lookingForCtypes] > 0) {
							const numberRemoved = groupedClass[lookingForCtypes];
							const listOfRemovedClasses = [];
							groupedClass.courses.forEach((classId) => {
								if (classId.ctype !== baseCtype) {
									listOfRemovedClasses.push(classId);
								}
							});
							groupedClass.courses.forEach((classId) => {
								if (classId.ctype === baseCtype) {
									classId.block = block;
									classId.sections_ignored_count = numberRemoved;
									classId.sections_ignored = listOfRemovedClasses.slice(); // Store the list of removed classes
									tempClasses.push(classId);
								}
							});
							delete groupedClasses[key];
						}
					}
				});
			});
			Object.keys(groupedClasses).forEach((key) => {
				const groupedClass = groupedClasses[key];
				groupedClass.courses.forEach((classId) => {
					notProcessedList.push(classId);
				});
			});
			if (notProcessedList.length > 0) {
				notProcessedList.forEach((classId) => {
					const normalBlockAssignment = assignBlockToClass(classId);
					tempClasses.push(normalBlockAssignment);
				});
			}
			setBeenProcessed(tempClasses);
			setNotBeenProcessed(notProcessedList);
		} else if (slashBlocks.length > 0) {
			const tempClasses = [];
			slashBlocks.forEach((block) => {
				const blockCtype = block.ctype;
				const blockCsize = block.csize;
				const ctypesSplit = blockCtype.split("/");
				if (!courseBlocksExist) {
					//if no course specific blocks exist
					Object.keys(groupedClasses).forEach((key) => {
						const groupedClass = groupedClasses[key];
						groupedClass.courses.forEach((classId) => {
							if (
								ctypesSplit.includes(classId.ctype) &&
								checkClassSizeBlockSize(classId.csize, blockCsize)
							) {
								classId.block = block;
								tempClasses.push(classId);
							} else {
								notProcessedList.push(classId);
							}
						});
					});
				} else {
					//if course specific blocks exist
					courseBlocks.forEach((block) => {
						const blockCourse = block.course;
						const blockSize = block.csize;
						if (blockCourse.includes(",")) {
							const courses = blockCourse.split(",");
							const listOfCourses = [];
							courses.forEach((course) => {
								listOfCourses.push(course.trim());
							});

							listOfCourses.forEach((course) => {
								Object.keys(groupedClasses).forEach((key) => {
									if (key.includes("/")) {
										const splitKey = key.split("/");
										const first = splitKey[0];
										const second = splitKey[1];
										if (course.includes(first) || course.includes(second)) {
											const groupedClass = groupedClasses[key];
											groupedClass.courses.forEach((classId) => {
												if (
													classId.ctitle.includes(course) &&
													checkClassSizeBlockSize(classId.csize, blockSize)
												) {
													classId.block = block;
													tempClasses.push(classId);
												} else {
													notProcessedList.push(classId);
												}
											});
											delete groupedClasses[key];
										}
									} else {
										if (course.includes(key)) {
											const groupedClass = groupedClasses[key];
											groupedClass.courses.forEach((classId) => {
												if (
													classId.ctitle.includes(course) &&
													checkClassSizeBlockSize(classId.csize, blockSize)
												) {
													classId.block = block;
													tempClasses.push(classId);
												} else {
													notProcessedList.push(classId);
												}
											});
											delete groupedClasses[key];
										}
									}
								});
							});
						} else {
							Object.keys(groupedClasses).forEach((key) => {
								if (blockCourse.includes(key)) {
									const groupedClass = groupedClasses[key];
									groupedClass.courses.forEach((classId) => {
										if (
											classId.ctitle.includes(blockCourse) &&
											checkClassSizeBlockSize(classId.csize, blockSize)
										) {
											classId.block = block;
											tempClasses.push(classId);
										} else {
											notProcessedList.push(classId);
										}
									});
									delete groupedClasses[key];
								}
							});
						}
					});
					Object.keys(groupedClasses).forEach((key) => {
						const groupedClass = groupedClasses[key];
						groupedClass.courses.forEach((classId) => {
							if (
								ctypesSplit.includes(classId.ctype) &&
								checkClassSizeBlockSize(classId.csize, blockCsize)
							) {
								classId.block = block;
								tempClasses.push(classId);
							} else {
								notProcessedList.push(classId);
							}
						});
					});
				}
				if (notProcessedList.length > 0) {
					notProcessedList.forEach((classId) => {
						const normalBlockAssignment = assignBlockToClass(classId);
						tempClasses.push(normalBlockAssignment);
					});
				}
				setBeenProcessed(tempClasses);
				setNotBeenProcessed(notProcessedList);
			});
		} else {
			//if colon blocks and slash blocks dont exist
			const tempClasses = [];
			if (!courseBlocksExist) {
				//if no course specific blocks exist

				Object.keys(groupedClasses).forEach((key) => {
					const groupedClass = groupedClasses[key];
					groupedClass.courses.forEach((classId) => {
						const normalBlockAssignment = assignBlockToClass(classId);
						tempClasses.push(normalBlockAssignment);
					});
				});
			} else {
				//if course specific blocks exist
				console.log("gclasses", groupedClasses);
				courseBlocks.forEach((block) => {
					const blockCourse = block.course;
					const blockSize = block.csize;
					if (blockCourse.includes(",")) {
						const courses = blockCourse.split(",");
						const listOfCourses = [];
						courses.forEach((course) => {
							listOfCourses.push(course.trim());
						});
						listOfCourses.forEach((course) => {
							Object.keys(groupedClasses).forEach((key) => {
								if (key.includes("/")) {
									const splitKey = key.split("/");
									const first = splitKey[0];
									const second = splitKey[1];
									if (course.includes(first) || course.includes(second)) {
										const groupedClass = groupedClasses[key];
										groupedClass.courses.forEach((classId) => {
											if (
												classId.ctitle.includes(course) &&
												checkClassSizeBlockSize(classId.csize, blockSize)
											) {
												classId.block = block;
												tempClasses.push(classId);
											} else {
												notProcessedList.push(classId);
											}
										});
										delete groupedClasses[key];
									}
								} else {
									if (course.includes(key)) {
										const groupedClass = groupedClasses[key];

										if (course.split(" ").length > 2) {
											groupedClass.courses.forEach((classId) => {
												const classSubjectId = classId.subject_id;
												const classCnumber = classId.cnumber;
												const classCtype = classId.ctype;
												const courseSubjectId = course.split(" ")[0];
												const courseCnumber = course.split(" ")[1];
												const courseCtype = course.split(" ")[2];

												if (
													classSubjectId === courseSubjectId &&
													classCnumber === courseCnumber &&
													classCtype === courseCtype &&
													checkClassSizeBlockSize(classId.csize, blockSize)
												) {
													classId.block = block;
													tempClasses.push(classId);
												} else {
													notProcessedList.push(classId);
												}
											});
											delete groupedClasses[key];
										} else {
											groupedClass.courses.forEach((classId) => {
												const classSubjectId = classId.subject_id;
												const classCnumber = classId.cnumber;

												const courseSubjectId = blockCourse.split(" ")[0];
												const courseCnumber = blockCourse.split(" ")[1];

												if (
													classSubjectId === courseSubjectId &&
													classCnumber === courseCnumber &&
													checkClassSizeBlockSize(classId.csize, blockSize)
												) {
													classId.block = block;
													tempClasses.push(classId);
												} else {
													notProcessedList.push(classId);
												}
											});
											delete groupedClasses[key];
										}
									}
								}
							});
						});
					} else {
						Object.keys(groupedClasses).forEach((key) => {
							if (blockCourse.includes(key)) {
								const groupedClass = groupedClasses[key];
								groupedClass.courses.forEach((classId) => {
									if (
										classId.ctitle.includes(blockCourse) &&
										checkClassSizeBlockSize(classId.csize, blockSize)
									) {
										classId.block = block;
										tempClasses.push(classId);
									} else {
										notProcessedList.push(classId);
									}
								});
								delete groupedClasses[key];
							}
						});
					}
				});
				Object.keys(groupedClasses).forEach((key) => {
					const groupedClass = groupedClasses[key];
					groupedClass.courses.forEach((classId) => {
						const normalBlockAssignment = assignBlockToClass(classId);
						tempClasses.push(normalBlockAssignment);
					});
				});
				if (notProcessedList.length > 0) {
					notProcessedList.forEach((classId) => {
						const normalBlockAssignment = assignBlockToClass(classId);
						tempClasses.push(normalBlockAssignment);
					});
				}
			}
			setBeenProcessed(tempClasses);
			setNotBeenProcessed(notProcessedList);
		}
	}, [orderOfProcessing, groupedClasses, courseBlocksExist]);

	useEffect(() => {
		if (beenProcessed.length > 0) {
			let sectionsIgnoredCount = 0;
			let ignoredSections = [];
			beenProcessed.forEach((classId) => {
				if (classId.sections_ignored_count) {
					sectionsIgnoredCount += classId.sections_ignored_count;
				}
				if (Array.isArray(classId.sections_ignored)) {
					classId.sections_ignored.forEach((section) => {
						if (section !== null && section !== undefined) {
							ignoredSections.push(section);
						}
					});
				} else {
					if (
						classId.sections_ignored !== null &&
						classId.sections_ignored !== undefined
					) {
						ignoredSections.push(classId.sections_ignored);
					}
				}
			});

			const uniqueIgnoredSections = [...new Set(ignoredSections)];

			setNumberOfIgnoredSections(uniqueIgnoredSections.length);
		}
	}, [beenProcessed]);

	useEffect(() => {
		if (beenProcessed.length > 0) {
			const tempClasses = [];
			const groupedByCtype = beenProcessed.reduce((result, classId) => {
				const key = classId.ctype;
				if (!result[key]) {
					result[key] = [];
				}
				result[key].push(classId);
				return result;
			}, {});

			const listOfCtypes = [];
			Object.keys(groupedByCtype).forEach((key) => {
				listOfCtypes.push(key);
			});
			setListOfCtypesForDept(listOfCtypes);
			Object.keys(groupedByCtype).forEach((key) => {
				const groupedClass = groupedByCtype[key];
				if (includeCourseTypes[key]) {
					groupedClass.forEach((classId) => {
						tempClasses.push(classId);
					});
				}
			});

			setPreGeneration(tempClasses);
		}
	}, [beenProcessed, includeCourseTypes]);

	useEffect(() => {
		const groupedData = {};
		preGeneration.forEach((classId) => {
			const teamTeaching = classId.teamTeaching;
			const blockid =
				classId.block && classId.block.length === 1
					? classId.block[0].blkid
					: (classId.block && classId.block.blkid) || null;
			const surveyStart = dayjs(classId.survey_start).format("YYYY-MM-DD");
			const surveyEnd = dayjs(classId.survey_end).format("YYYY-MM-DD");
			if (!groupedData[teamTeaching]) {
				groupedData[teamTeaching] = {};
			}
			if (!groupedData[teamTeaching][blockid]) {
				groupedData[teamTeaching][blockid] = {};
			}
			if (!groupedData[teamTeaching][blockid][surveyStart]) {
				groupedData[teamTeaching][blockid][surveyStart] = {};
			}
			if (!groupedData[teamTeaching][blockid][surveyStart][surveyEnd]) {
				groupedData[teamTeaching][blockid][surveyStart][surveyEnd] = [];
			}
			groupedData[teamTeaching][blockid][surveyStart][surveyEnd].push(classId);
		});
		setFinalClasses(groupedData);
		const totalClasses = Object.keys(groupedData).reduce(
			(total, teamTeaching) => {
				const blockIds = Object.keys(groupedData[teamTeaching]);
				return (
					total +
					blockIds.reduce((blockTotal, blockId) => {
						return (
							blockTotal +
							Object.keys(groupedData[teamTeaching][blockId]).reduce(
								(startTotal, surveyStart) => {
									return (
										startTotal +
										Object.keys(
											groupedData[teamTeaching][blockId][surveyStart]
										).reduce((endTotal, surveyEnd) => {
											return (
												endTotal +
												groupedData[teamTeaching][blockId][surveyStart][
													surveyEnd
												].length
											);
										}, 0)
									);
								},
								0
							)
						);
					}, 0)
				);
			},
			0
		);
		props.setStagedClasses(totalClasses);
	}, [includeCourseTypes, preGeneration]);

	function getLastNonHyphenIndex(schedule) {
		for (let i = schedule.length - 1; i >= 0; i--) {
			if (schedule[i] !== "-") {
				return i;
			}
		}
		return -1;
	}

	const calcEveningClassEndDate = (classId) => {
		const schedule = classId.schedule;
		const scheduleArray = schedule.split("");
		scheduleArray.forEach((char) => {
			if (char === "-") {
				scheduleArray.pop();
			}
		});
		const scheduleArrayNoEmpty = scheduleArray.filter((char) => char !== " ");
		const lastDayOfWeek = getLastNonHyphenIndex(scheduleArrayNoEmpty);
		const classEndDate = classId.c_edate;
		const classEndDateDay = dayjs(classEndDate).day();
		if (lastDayOfWeek >= classEndDateDay) {
			return classEndDate;
		} else {
			const difference = classEndDateDay - lastDayOfWeek;
			const newEndDate = dayjs(classEndDate).subtract(difference, "day");
			return newEndDate;
		}
	};

	const assignDate = (classId) => {
		const regularEndOfTerm = dayjs(props.regularEndOfTerm).format("YYYY-MM-DD");
		const regularSurveyStart = dayjs(regularEndOfTerm)
			.subtract(14, "day")
			.format("YYYY-MM-DD");
		const regularSurveyEnd = dayjs(regularEndOfTerm)
			.add(2, "day")
			.format("YYYY-MM-DD");
		const examDate = dayjs(classId.examdate).format("YYYY-MM-DD");
		const examDateMinus1 = dayjs(classId.examdate)
			.subtract(1, "day")
			.format("YYYY-MM-DD");
		const endDate = dayjs(classId.c_edate).format("YYYY-MM-DD");
		const endDateMinus1 = dayjs(classId.c_edate)
			.subtract(1, "day")
			.format("YYYY-MM-DD");
		const examDateMinus15 = dayjs(classId.examdate)
			.subtract(15, "day")
			.format("YYYY-MM-DD");

		const endDateMinus15 = dayjs(classId.c_edate)
			.subtract(15, "day")
			.format("YYYY-MM-DD");
		if (endDate === regularEndOfTerm) {
			if (classId.examdate !== null) {
				if (regularSurveyEnd < examDate) {
					classId.survey_start = regularSurveyStart;
					classId.survey_end = regularSurveyEnd;
				} else {
					classId.survey_start = examDateMinus15;
					classId.survey_end = examDateMinus1;
				}
			} else {
				if (classId.section[0] === "X") {
					const eveningClassEndDate = calcEveningClassEndDate(classId);
					const formattedEveningClassEndDate =
						dayjs(eveningClassEndDate).format("YYYY-MM-DD");
					const eveningClassSurveyStart = eveningClassEndDate.subtract(
						14,
						"day"
					);
					const formattedEveningClassSurveyStart = dayjs(
						eveningClassSurveyStart
					).format("YYYY-MM-DD");
					classId.survey_start = formattedEveningClassSurveyStart;
					classId.survey_end = formattedEveningClassEndDate;
				} else {
					classId.survey_start = endDateMinus15;
					classId.survey_end = endDateMinus1;
				}
			}
		} else {
			const classEndOfTerm = dayjs(classId.c_edate).format("YYYY-MM-DD");
			const classSurveyStart = dayjs(classEndOfTerm).subtract(14, "day");
			const classSurveyEnd = dayjs(classEndOfTerm).add(2, "day");
			if (classId.examdate !== null) {
				if (classSurveyEnd < examDate) {
					classId.survey_start = classSurveyStart;
					classId.survey_end = classSurveyEnd;
				} else {
					classId.survey_start = examDateMinus15;
					classId.survey_end = examDateMinus1;
				}
			} else {
				if (classId.section[0] === "X") {
					const eveningClassEndDate = calcEveningClassEndDate(classId);
					const formattedEveningClassEndDate =
						dayjs(eveningClassEndDate).format("YYYY-MM-DD");
					const eveningClassSurveyStart = eveningClassEndDate.subtract(
						14,
						"day"
					);
					const formattedEveningClassSurveyStart = dayjs(
						eveningClassSurveyStart
					).format("YYYY-MM-DD");
					classId.survey_start = formattedEveningClassSurveyStart;
					classId.survey_end = formattedEveningClassEndDate;
				} else {
					classId.survey_start = endDateMinus15;
					classId.survey_end = endDateMinus1;
				}
			}
		}
		return classId;
	};

	const checkClassSizeBlockSize = (csize, blockCSize) => {
		if (csize >= 10) {
			return true;
		} else if (csize < 10) {
			if (blockCSize === "LARGE") {
				return false;
			} else {
				return true;
			}
		}
	};

	const assignBlockToClass = (classId) => {
		const largeClass = classId.csize >= 10;
		const smallClass = classId.csize < 10;
		const ctype = classId.ctype;
		const blocks = blocksDict;

		const basicBlocks = blocks.basic;
		const eitherBlocks = blocks.either;
		const allTAs = classId.roles.every((role) => role.class_role === "TA");
		if (allTAs) {
			if (largeClass) {
				if (blocks.large[ctype] && blocks.large[ctype].length > 0) {
					classId.block = blocks.large[ctype][0];
					return classId;
				} else if (eitherBlocks[ctype] && eitherBlocks[ctype].length > 0) {
					classId.block = eitherBlocks[ctype][0];
					return classId;
				} else {
					classId.block = basicBlocks["3SLAB"][0];
					return classId;
				}
			} else if (smallClass) {
				classId.block = basicBlocks["3STA"][0];
				return classId;
			}
		} else {
			if (largeClass) {
				const largeBlocks = blocks.large;
				const largeBlock = largeBlocks[ctype];
				if (largeBlock && largeBlock.length > 0) {
					classId.block = largeBlock[0];
					return classId;
				} else if (eitherBlocks[ctype] && eitherBlocks[ctype].length > 0) {
					classId.block = eitherBlocks[ctype][0];
					return classId;
				} else {
					if (props.language === "EN") {
						classId.block = basicBlocks["3SPOT"][0];
					} else {
						classId.block = basicBlocks["3CSJ"][0];
					}
					return classId;
				}
			} else if (smallClass) {
				const smallBlocks = blocks.small;
				const smallBlock = smallBlocks[ctype];

				if (smallBlock && smallBlock.length > 0) {
					classId.block = smallBlock[0];
					return classId;
				} else if (eitherBlocks[ctype] && eitherBlocks[ctype].length > 0) {
					classId.block = eitherBlocks[ctype][0];
					return classId;
				} else {
					if (props.language === "EN") {
						classId.block = basicBlocks["3OPN"][0];
					} else {
						classId.block = basicBlocks["3CSJO"][0];
					}
					return classId;
				}
			}
		}
	};

	const [generateLoading, setGenerateLoading] = useState(false);
	const handleGenerate = async () => {
		const teamTeaching = Object.keys(finalClasses);
		const requisitionList = [];
		setGenerateLoading(true);
		try {
			for (let teamTeachingKey of teamTeaching) {
				if (teamTeachingKey === "true" || teamTeachingKey === "false") {
					const teamTeachingData = finalClasses[teamTeachingKey];
					for (let blockId of Object.keys(teamTeachingData)) {
						const finalBlock = finalizedBlocks.find(
							(block) => block.blkid === blockId
						);
						for (let surveyStart of Object.keys(teamTeachingData[blockId])) {
							for (let surveyEnd of Object.keys(
								teamTeachingData[blockId][surveyStart]
							)) {
								const classes =
									teamTeachingData[blockId][surveyStart][surveyEnd];
								const classesList = [];
								const itemsList = [];
								let requisitionForPost = {};
								const reqnum = await getReqnum(token);
								classes.forEach((classId, index1) => {
									let csize = classId.csize;
									let gcSize =
										csize >= 1 && csize <= 15
											? 1
											: csize >= 16 && csize <= 35
											? 2
											: csize >= 36 && csize <= 100
											? 3
											: csize >= 100
											? 4
											: 0;
									classId.roles.forEach((role, index2) => {
										const newClass = {
											reqnum: reqnum[0],
											clsnum:
												teamTeachingKey === "true"
													? (index1 + 1) * 10 + (index2 + 1)
													: index1 + 1,

											classid: classId.classid,
											acyear: classId.acyear,
											faculty: classId.faculty,
											dept_id: classId.dept,
											empid: role.empid,
											name: role.name,
											rank: role.class_role,
											ctitle: classId.ctitle,
											csize:
												teamTeachingKey === "true"
													? index2 === 0
														? classId.csize
														: 0
													: classId.csize,
											clevel: Array.from(classId.cnumber)[0],
											gcsize: gcSize,
											tydate: null,
											qtally: null,
											status: "none",
											rfdate: null,
											rpdate: null,
											examdate: classId.examdate,
											term: classId.term,
											on_line: "Yes",
											rel_date: null,
											original: null,
											survey_start: surveyStart,
											survey_end: surveyEnd,
											description: classId.description,
											ctype: classId.ctype,
											comb_classes:
												classId.comb_classes && classId.comb_classes.length > 0
													? classId.comb_classes
													: null,
										};
										classesList.push(newClass);
									});
								});
								const blkid1 = finalBlock.blkid;
								const blkid3 = finalBlock.blkid3;
								finalBlock.items.forEach((item) => {
									const newItem = {
										reqnum: reqnum[0],
										itemno: item.itemno,
										q_order: item.q_order,
										blkmem1: item.blkid === blkid1 ? "Yes" : "No",
										blkmem2: "No",
										blkmem3:
											(item.itemno >= 1 &&
												item.itemno <= 18 &&
												blkid3 === "3SPOT") ||
											(item.itemno >= 105 &&
												item.itemno <= 122 &&
												blkid3 === "3CSJ") ||
											(item.itemno >= 900 &&
												item.itemno <= 903 &&
												blkid3 === "3OPN") ||
											(item.itemno >= 930 &&
												item.itemno <= 933 &&
												blkid3 === "3CSJO") ||
											(item.itemno >= 137 &&
												item.itemno <= 140 &&
												blkid3 === "3STA")
												? "Yes"
												: "No",
										repeat:
											teamTeachingKey === "true"
												? item.team_teaching === "Y"
													? "Yes"
													: "No"
												: "No",
										custom_cat: item.custom_cat
											? item.custom_cat.split(" ")[0]
											: null,
									};
									itemsList.push(newItem);
								});
								const requisition = {
									reqnum: reqnum[0],
									catalog: finalBlock.catalog,
									rqdate: dayjs().format("YYYY-MM-DD"),
									totrtg: finalBlock.totrtg,
									totopn: finalBlock.totopn,
									uqrtng: finalBlock.uqrtng,
									uqopen: finalBlock.uqopen,
									rptits:
										teamTeachingKey === "true" ? finalBlock.potentialRptits : 0,
									blkid1: finalBlock.blkid,
									blkid2: finalBlock.blkid2,
									blkid3: finalBlock.blkid3,
									raddr1: userFirstName + " " + userLastName,
									raddr2: props.deptName,
									raddr3: "",
									userid: sessionStorage.getItem("ccid"),
									start_date: surveyStart,
									end_date: surveyEnd,
									block: finalBlock,
									classes: classesList,
									items: itemsList,
								};
								requisitionForPost = {
									reqnum: reqnum[0],
									catalog: finalBlock.catalog,
									rqdate: dayjs().format("YYYY-MM-DD"),
									totrtg: finalBlock.totrtg,
									totopn: finalBlock.totopn,
									uqrtng: finalBlock.uqrtng,
									uqopen: finalBlock.uqopen,
									rptits: finalBlock.potentialRptits,
									blkid1: finalBlock.blkid,
									blkid2: finalBlock.blkid2,
									blkid3: finalBlock.blkid3,
									raddr1: userFirstName + " " + userLastName,
									raddr2: props.deptName,
									raddr3: "",
									userid: sessionStorage.getItem("ccid"),
									start_date: surveyStart,
									end_date: surveyEnd,
								};
								requisitionList.push(requisition);
							}
						}
					}
				}
			}
			setRequisitionsForPost(requisitionList);
			setGenerateLoading(false);
			setOpenRequisitionDisplay(true);
		} catch (err) {
			console.error(err);
			alert("An error occurred during API calls");
		}
	};
	const handleChange = (event) => {
		setIncludeCourseTypes({
			...includeCourseTypes,
			[event.target.name]: event.target.checked,
		});
	};

	const checkIfGenerateDisabled = Object.values(includeCourseTypes).every(
		(value) => value === false
	);

	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "75%",
				}}
			>
				<TableHeader
					classIds={props.classIds}
					webClasses={props.webClasses}
					goodClassIds={props.goodClassIds}
					noEndDates={props.noEndDates}
					suitableClassIds={props.suitableClassIds}
					stagedClasses={props.stagedClasses}
					setStagedClasses={props.setStagedClasses}
					numberOfIgnoredSections={numberOfIgnoredSections}
					groupClasses
					countOfCombinedClasses={props.countOfCombinedClasses}
					removedForCombine={props.removedForCombine}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-evenly",
					}}
				>
					<Box
						sx={{
							outline: "1px solid #E0E0E0",
							display: "flex",
							justifyContent: "center",
							marginBottom: "5px",
							borderRadius: "0px 0px 5px 5px",
						}}
					>
						<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
							<FormLabel component="legend">
								INCLUDE COURSE TYPES IN AUTO REQUISITION
							</FormLabel>
							<FormGroup row>
								{listOfCtypesForDept.map((ctype) => {
									return (
										<FormControlLabel
											control={
												<Checkbox
													name={ctype}
													checked={includeCourseTypes[ctype]}
													onChange={handleChange}
												/>
											}
											label={ctype}
											labelPlacement="bottom"
										/>
									);
								})}
							</FormGroup>
						</FormControl>
					</Box>
					<Box
						sx={{
							outline: "1px solid #E0E0E0",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							marginBottom: "5px",
							borderRadius: "5px",
						}}
					>
						<Box
							sx={{
								backgroundColor: "greysDark.dark",
								p: 1,
								borderRadius: "5px 5px 0px 0px",
							}}
						>
							<Typography variant="h6" color="white">
								WARNING LOG: (If empty, that is good)
							</Typography>
						</Box>
						<LoggingList loggingDict={props.loggingDict} />
					</Box>
				</Box>
			</Box>

			<Box sx={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
				<Button
					variant="outlined"
					fullWidth
					sx={{ marginTop: "5px", maxHeight: "40px", minHeight: "40px" }}
					onClick={() => props.setStep4(false)}
				>
					{"<"}-Back to Resolve Dates
				</Button>
				<Button
					disabled={checkIfGenerateDisabled}
					variant="contained"
					fullWidth
					sx={{
						minHeight: "40px",
						display: "flex",
						justifySelf: "flex-end",
					}}
					onClick={handleGenerate}
				>
					{generateLoading && <CircularProgress />}
					GENERATE
				</Button>
			</Box>
			<RequisitionDisplay
				open={openRequisitionDisplay}
				setOpen={setOpenRequisitionDisplay}
				requisitions={requisitionsForPost}
				setRequisitions={setRequisitionsForPost}
				setAutocompleteValue={props.setAutocompleteValue}
				setStep2={props.setStep2}
				setStep3={props.setStep3}
				setStep4={props.setStep4}
			/>
		</Box>
	);
}
