import * as React from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import { TeamTeachingContext } from "../../../context/RequisitionContext";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

export default function SingleItemCatalogTable(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const { chosenItems, setChosenItems } = props;
	const [rows, setRows] = useState([]);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({
		team_teaching: false,
	});
	const apiRef = useGridApiRef();

	const { data: allItemsData } = useAllItemsQuery(token);

	const categories = [1, 2, 3, 4, 5, 6];

	useEffect(() => {
		if (allItemsData && allItemsData.length > 0) {
			const noUniqueItems = allItemsData.filter((item) => item.sub_type !== "U");
			const noCommentItems = noUniqueItems.filter((item) => item.itemno < 1000);
			//sort by cat_Id
			noCommentItems.sort((a, b) => {
				if (a.cat_id !== b.cat_id) {
					return a.cat_id > b.cat_id ? 1 : -1;
				}
				return a.itemno > b.itemno ? 1 : -1;
			});

			if (chosenItems && chosenItems.length > 0) {
				const chosenItemsWithCatId = chosenItems.map((item) => {
					const foundItem = noCommentItems.find((i) => i.itemno === item.itemno);
					const catId = foundItem ? foundItem.cat_id : undefined;
					return { ...item, cat_id: catId };
				});
				//remove all items from noCommentItems that share categories with chosenItems
				const noCommentItemsFiltered = noCommentItems.filter((item) => {
					return !chosenItemsWithCatId.some((chosenItem) => chosenItem.cat_id === item.cat_id && categories.includes(item.cat_id));
				});
				setRows(noCommentItemsFiltered);
			} else {
				setRows(noCommentItems);
			}
		}
	}, [allItemsData, chosenItems]);

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
				<GridFooter />
			</GridFooterContainer>
		);
	};

	const handleAddItem = (row) => {
		const updatedChosenItems = [...chosenItems];
		const newItem = {
			...row,
			repeat: teamTeachingAgree === true ? (row.team_teaching === "Y" ? "Yes" : "No") : "No",
		};
		updatedChosenItems.push(newItem);
		setChosenItems(updatedChosenItems);
	};

	const renderAddButton = useCallback(
		(params) => {
			if (chosenItems.some((item) => item.itemno === params.row.itemno)) {
				return (
					<strong>
						<Button variant="outlined" sx={{ marginLeft: "5px", padding: "2px" }} color="greys">
							Added
						</Button>
					</strong>
				);
			} else {
				return (
					<strong>
						<Button variant="outlined" sx={{ marginLeft: "5px", padding: "2px" }} onClick={() => handleAddItem(params.row)}>
							Add
						</Button>
					</strong>
				);
			}
		},
		[handleAddItem]
	);

	const columns = [
		/* {
			field: "cat_id",
			headerName: "Category",
			width: 80,
		}, */
		{
			field: "itemno",
			headerName: "Item #",
			width: 60,
		},
		{
			field: "itmtyp",
			headerName: "Type",
			width: 60,
		},
		{
			field: "text",
			headerName: "Item Text",
			flex: 1,
		},

		{
			field: " ",
			width: 100,
			renderCell: renderAddButton,
			sortable: false,
			disableColumnMenu: true,
		},
	];

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<DataGrid
				apiRef={apiRef}
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					border: props.greenBorder && chosenItems.length > 0 ? "1px solid #275d38" : "grey.300",
					borderTop: props.greenBorder && chosenItems.length > 0 ? "1px solid #E0E0E0" : "grey.300",
					borderRadius: props.greenBorder && "1px 1px 4px 4px",
				}}
				density="compact"
				components={{ Footer: CustomFooter }}
				disableRowSelectionOnClick={true}
				rows={rows}
				columns={columns}
				isRowSelectable={() => false}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) => {
					setColumnVisibilityModel(newModel);
				}}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.itemno}
			/>
		</div>
	);
}
