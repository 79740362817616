//All imports happen at the top of the file
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TokenContext } from "../../../context/TokenContext";
import ListSelect from "../../../components/Utils/ListSelect";
import AutocompleteComponent from "../../../components/Utils/AutocompleteComponent";
import { fetchInstancedClassIds, fetchRolesByClassIds } from "../../../components/Utils/Fetchers";
import { GridFooterContainer, GridFooter, DataGrid } from "@mui/x-data-grid";
import { Typography, Button } from "@mui/material";
import InstanceModal from "./components/InstanceModal";
import { getRolesByClassId } from "../../../components/Api/Getters";
import { ClassIdsContext } from "../../../context/RequisitionContext";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import { useTermsQuery } from "../../../Hooks/useTermsQuery";
import { useClassIdObjectsByDeptAndTerm } from "../../../Hooks/useClassIdsQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

export default function InstancedClasses() {
	const auth = isAuthenticated();
	const token = auth.token;
	const [open, setOpen] = useState(false);
	const [selectedTerm, setSelectedTerm] = useState("");
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [instancedClasses, setInstancedClasses] = useState([]);
	const [roles, setRoles] = useState([]);
	const [classIds, setClassIds] = useState([]);

	const { data: classIdsObjects } = useClassIdObjectsByDeptAndTerm(
		token,
		autocompleteValue?.key,
		selectedTerm
	);

	useEffect(() => {
		if (classIdsObjects) {
			const classIdsArray = classIdsObjects.map((classid) => {
				classid.id = classid.classid;
				return classid;
			});
			setClassIds(classIdsArray);
		}
	}, [classIdsObjects]);

	const [selectedRow, setSelectedRow] = useState({
		classid: "",
		ctitle: "",
		csize: "",
		name: "",
		addinst: "",
		c_edate: "",
		examdate: "",
		c_location: "",
		teamsize: "",
	});

	const { data: departments } = useDepartmentsQuery(token);

	const { data: terms } = useTermsQuery(token);

	useEffect(() => {
		if (open && selectedRow?.classid) {
			const classId = selectedRow.classid;
			fetchInstancedClassIds(token, classId).then((instancedClasses) => {
				if (instancedClasses && instancedClasses.length > 0) {
					fetchRolesByClassIds(token, instancedClasses[0], instancedClasses[1]).then((roles) => {
						setInstancedClasses(roles);
					});
				}
			});
		}
	}, [selectedRow, open]);

	useEffect(() => {
		async function getRolesBySingleClassId() {
			if (open && selectedRow.classid) {
				const classid = selectedRow.classid;
				const result = await getRolesByClassId(token, classid);
				setRoles(result);
			}
		}
		getRolesBySingleClassId();
	}, [selectedRow]);

	const handleChangeListSelect = (event) => {
		setSelectedTerm(event.target.value);
	};

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start" }}>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const handleSelectedRow = (row) => {
		setSelectedRow(row);
		setOpen(true);
	};

	const renderAddInstancedClasses = (params) => {
		return (
			<div>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						handleSelectedRow(params.row);
					}}
				>
					Add
				</Button>
			</div>
		);
	};

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => "'" + role.name + "'");
		const teamName = teamNames.join(", ");

		const updatedRows = [...classIds];
		const index = updatedRows.findIndex((r) => r.id === row.id);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = true;

		setClassIds(updatedRows);
	};

	const renderInstructorName = (params) => {
		const { row } = params;
		if (row.roles && row.roles.length === 1) {
			return (
				<strong>
					<Typography sx={{ marginRight: "10px" }}>{row.name}</Typography>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
			return (
				<strong>
					<Button onClick={() => handleTeamVisibility(row)}>
						<Typography sx={{ marginRight: "10px" }}>(Several)</Typography>
						<VisibilityIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
			return (
				<strong>
					<Typography sx={{ marginRight: "10px" }}>{row.teamName}</Typography>
				</strong>
			);
		} else {
			return (
				<strong>
					<Typography sx={{ marginRight: "10px" }} color="red">
						(No Instructor Found)
					</Typography>
				</strong>
			);
		}
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 100,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			width: 180,
		},

		{
			field: "csize",
			headerName: "Size",
			width: 60,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 180,
			flex: 1,
			renderCell: renderInstructorName,
		},
		{
			field: "addinst",
			headerName: "",
			width: 80,
			renderCell: renderAddInstancedClasses,
		},
		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "c_location",
			headerName: "Location",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			width: 80,
			disableClickEventBubbling: true,
		},
	];

	return (
		<ClassIdsContext.Provider value={{ classIds, setClassIds }}>
			<Grid
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "100vw",
				}}
			>
				<Typography
					variant="h6"
					textAlign={"center"}
					sx={{ marginTop: "3%", marginBottom: "3%", width: "80%" }}
				>
					Instanced Classes are used in situations where you need a duplicate of a class. These are
					very specific use cases, such as when two instructors teach a course together, but at
					different points in the term. Please only create an instanced class if you are sure that
					you need one.
				</Typography>
				<Grid
					xs={12}
					direction="row"
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						alignItems: "center",
						marginBottom: "3%",
					}}
				>
					<AutocompleteComponent
						label="Department"
						options={departments ? departments : []}
						/* disabled */
						variant="standard"
						marginTop="0px"
						marginBottom="18px"
						value={autocompleteValue}
						setValue={setAutocompleteValue}
						inputValue={autocompleteInputValue}
						setInputValue={setAutocompleteInputValue}
					/>
					<ListSelect
						/* disabled */
						label="Term"
						list={terms}
						variant="standard"
						marginTop="0px"
						width="300px"
						onChange={handleChangeListSelect}
						value={selectedTerm ? selectedTerm : ""}
					/>
					<InstanceModal
						open={open}
						setOpen={setOpen}
						selectedRow={selectedRow}
						handleSelectedRow={handleSelectedRow}
						instancedClasses={instancedClasses}
						setInstancedClasses={setInstancedClasses}
						roles={roles}
					/>
				</Grid>
				<Grid
					spacing={2}
					sx={{
						margin: "auto",
						height: "600px",
						width: "90%",
					}}
				>
					<DataGrid
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "None !important",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "0.4em",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f1f1f1",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#888",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								background: "#555",
							},

							borderRadius: "1px 1px 4px 4px",
						}}
						autoPageSize
						disableSelectionOnClick
						components={{ Footer: CustomFooter /* Toolbar: CustomToolbar */ }}
						rows={classIds}
						density="compact"
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 100, page: 0 },
							},
						}}
						pageSizeOptions={[5, 10]}
						getRowId={(row) => row.classid}
					/>
				</Grid>
			</Grid>
		</ClassIdsContext.Provider>
	);
}
