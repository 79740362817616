import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { Zoom } from "@mui/material";

function QuestionText(props) {
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
					width: "400px",
				}}
			>
				<Tooltip TransitionComponent={Zoom} title={props.tooltipText} placement="right-start">
					<Box sx={{ width: "100%" }}>
						<InputLabel sx={{ display: "flex", justifyContent: "space-between", color: "black" }}>
							{props.question}
							<HelpIcon
								sx={{
									color: "gray",
								}}
							/>
						</InputLabel>
						<TextField
							defaultValue=""
							helperText={props.helperText}
							placeholder="50..."
							value={props.value}
							onChange={props.onChange}
							error={props.error}
							name={props.name}
							required
							variant="outlined"
							sx={{
								width: "100%",
								height: "55px",
							}}
						/>
					</Box>
				</Tooltip>
			</Box>
		</div>
	);
}

export default QuestionText;
