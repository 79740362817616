//All imports happen at the top of the file
import React, { useEffect, useState, useRef } from "react";
import { Typography, Grid, Button, Tooltip } from "@mui/material";
import ListSelect from "../../../components/Utils/ListSelect";
import TextInput from "../../../components/Utils/TextInput";
import { getHighestItemNo, getHighestItemNo20000 } from "../../../components/Api/Getters";
import { postItem } from "../../../components/Api/Post";
import ToggleButtonsAddItem from "./components/ToggleButtonsAddItem";
import ToolTipHelp from "../../../components/Utils/ToolTipHelp";
import SimilarItemTable from "./components/SimilarItemTable";
import CustomScale from "./components/CustomScale";
import { useItemCategoriesQuery } from "../../../Hooks/useItemCategoriesQuery";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

const itemTypes = ["Rating(Numerical)", "Open-ended"];

export default function AddItems() {
	const auth = isAuthenticated();
	const token = auth.token;
	const [checked, setChecked] = React.useState(null);
	const [grabbedItemNo, setGrabbedItemNo] = useState(0);
	const [submitted, setSubmitted] = useState(false);
	const [itemTypeState, setItemTypeState] = useState("");
	const [itemState, setItemState] = useState({
		category: "",
		itemType: "",
		itemText: "",
		itemNumber: "",
		scale: "",
	});
	const [itemError, setItemError] = useState({
		category: false,
		itemType: false,
		itemText: false,
		itemNumber: false,
	});
	const [itemTextHelperText, setItemTextHelperText] = useState(" ");
	const [tooSimilar, setTooSimilar] = useState(false);
	const [itemChecked, setItemChecked] = useState(false);
	const [alignment, setAlignment] = React.useState("");
	const [openCustomScale, setOpenCustomScale] = useState(false);
	const [scaleValues, setScaleValues] = useState({ scale1: "", scale2: "" });
	const [scalePoints, setScalePoints] = useState([]);
	const containerRef = useRef(null);
	const [categoriesArray, setCategoriesArray] = useState([]);
	const [date, setDate] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [similarQuestions, setSimilarQuestions] = useState([]);

	const { data: itemCategoriesData, status: itemCategoriesStatus } = useItemCategoriesQuery(token);
	const { data: allItemsData } = useAllItemsQuery(token);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [scalePoints, openCustomScale]);

	useEffect(() => {
		if (scaleValues.scale1 !== "" && scaleValues.scale2 !== "") {
			const scaleValuesArray = Object.values(scaleValues);
			let itemScale = "";
			scaleValuesArray.forEach((scale, index) => {
				if (scale.includes(";")) {
					scale = scale.replace(/;/g, "");
				}
				if (index !== scaleValuesArray.length - 1) {
					itemScale += scale + ";";
				} else {
					itemScale += scale;
				}
			});

			setItemState({
				...itemState,
				scale: itemScale,
			});
		}
	}, [scaleValues, itemState]);

	useEffect(() => {
		setDate(getDate());
	}, []);

	useEffect(() => {
		async function fetchHighestItemNo() {
			//grabbing for all NON-SPOT instrument items
			if (token) {
				if (
					itemTypeState !== "" &&
					itemState.category !== "Category 1 - Design" &&
					itemState.category !== "Category 2 - Utility of Course Resources" &&
					itemState.category !== "Category 3 - Graded Work" &&
					itemState.category !== "Category 4 - Course Delivery" &&
					itemState.category !== "Category 5 - Instructional Approach" &&
					itemState.category !== "Category 6 - Class Climate"
				) {
					const highestItemNo = await getHighestItemNo(token, itemTypeState);
					setItemState((prevItemState) => ({
						...prevItemState,
						itemNumber: highestItemNo + 1,
					}));
					setGrabbedItemNo(highestItemNo + 1);
					setSubmitted(false);
				}
			}
		}
		async function fetchHighestItemNo20000() {
			//grabbing for all SPOT instrument items (OPEN ENDED MAINLY)
			if (token) {
				if (
					itemTypeState !== "" &&
					(itemState.category === "Category 1 - Design" ||
						itemState.category === "Category 2 - Utility of Course Resources" ||
						itemState.category === "Category 3 - Graded Work" ||
						itemState.category === "Category 4 - Course Delivery" ||
						itemState.category === "Category 5 - Instructional Approach" ||
						itemState.category === "Category 6 - Class Climate")
				) {
					const highestItemNo = await getHighestItemNo20000(token, itemTypeState);
					setItemState((prevItemState) => ({
						...prevItemState,
						itemNumber: highestItemNo + 1,
					}));
					setGrabbedItemNo(highestItemNo + 1);
					setSubmitted(false);
				}
			}
		}
		fetchHighestItemNo();
		fetchHighestItemNo20000();
	}, [token, itemTypeState, submitted, itemState.category]);

	useEffect(() => {
		if (itemState.itemType === "Rating(Numerical)") {
			setItemTypeState("RATE");
		} else if (itemState.itemType === "Open-ended") {
			setItemTypeState("OPEN");
		}
	}, [itemState.itemType]);

	useEffect(() => {
		const categoriesArray = [];
		if (itemCategoriesData && itemCategoriesData.length > 0) {
			itemCategoriesData.forEach((category) => {
				if (category.cat_id !== 10) {
					categoriesArray.push(`Category ${category.cat_id} - ${category.cat_name}`);
				}
			});
			categoriesArray.sort((a, b) => {
				return a.split(" ")[1] - b.split(" ")[1];
			});
		}
		setCategoriesArray(categoriesArray);
	}, [itemCategoriesData]);

	useEffect(() => {
		if (itemState.category !== "" && itemState.itemType !== "" && itemState.itemNumber !== "") {
			setDisabled(false);
		}
	}, [itemState.category, itemState.itemType, itemState.itemNumber]);

	const getDate = () => {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + "-" + mm + "-" + dd;
		return today;
	};

	const handleChange = (event) => {
		setTooSimilar(false);
		setItemChecked(false);
		setItemTextHelperText(" ");
		setSimilarQuestions([]);
		const value = event.target.value;
		setItemState({ ...itemState, [event.target.name]: value });
		if (itemError[event.target.name] === true) {
			setItemError({ ...itemError, [event.target.name]: false });
		}
	};

	const checkForSimilarity = () => {
		const allItemsNoCustom = allItemsData.filter((item) => (item.itemno > 0 && item.itemno < 1000) || item.itemno > 2000);

		if (allItemsNoCustom.length > 0) {
			allItemsNoCustom.forEach((item) => {
				if (compareStrings(item.text, itemState.itemText, 0.5, 6)) {
					setItemError({ ...itemError, itemText: true });
					setTooSimilar(true);
					setItemChecked(true);
					setItemTextHelperText("Existing Item '(" + item.itemno + ") " + item.text + "' is too similar");
					setSimilarQuestions([...similarQuestions, item]);
				} else {
					setItemChecked(true);
					setTooSimilar(false);
				}
			});
		}
	};

	function getNGrams(str, n) {
		const nGrams = [];
		for (let i = 0; i < str.length - n + 1; i++) {
			const nGram = str.slice(i, i + n);
			nGrams.push(nGram);
		}
		return nGrams;
	}

	function jaccardSimilarity(str1, str2, n) {
		const set1 = new Set(getNGrams(str1, n));
		const set2 = new Set(getNGrams(str2, n));
		const intersection = new Set([...set1].filter((gram) => set2.has(gram)));
		const union = new Set([...set1, ...set2]);
		const similarity = intersection.size / union.size;
		return similarity;
	}

	function compareStrings(str1, str2, threshold, n) {
		const similarity = jaccardSimilarity(str1, str2, n);
		if (similarity >= threshold) {
			return true;
		} else {
			return false;
		}
	}

	const handleSubmit = () => {
		if (itemState.category === "") {
			setItemError({ ...itemError, category: true });
		}
		if (itemState.itemType === "") {
			setItemError({ ...itemError, itemType: true });
		}
		if (itemState.itemNumber === "" || itemState.itemNumber < grabbedItemNo) {
			setItemError({ ...itemError, itemNumber: true });
		}
		if (itemState.itemText === "") {
			setItemError({ ...itemError, itemText: true });
		}
		if (itemState.category !== "" && itemState.itemType !== "" && itemState.itemNumber !== "" && itemState.itemNumber >= grabbedItemNo && itemState.itemText !== "") {
			const newItem = {
				cat_id: Number(itemState.category.split(" ")[1]),
				catalog: "GFC",
				sub_type: "C",
				itmtyp: itemState.itemType === "Rating(Numerical)" ? "RATE" : "OPEN",
				itemno: Number(itemState.itemNumber),
				text: itemState.itemText,
				addate: date,
				team_teaching: checked ? "Y" : "N",
				scale: itemState.scale === "" ? null : itemState.scale,
			};
			postItem(token, newItem);
			setItemState({ ...itemState, itemNumber: "", itemText: "", scale: "" });
			setScaleValues({ scale1: "", scale2: "" });
			setScalePoints([]);
			setChecked(false);
			setSubmitted(true);
			setTooSimilar(false);
			setItemChecked(false);
			setItemTextHelperText(" ");
			setSimilarQuestions([]);
		}
	};

	const handleAlignment = (event, newAlignment) => {
		setAlignment(newAlignment);
		if (newAlignment === "yes") {
			setChecked(true);
		}
		if (newAlignment === "no") {
			setChecked(false);
		}
	};

	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start",
				width: "100%",
			}}
		>
			<Typography variant="h4" sx={{ marginTop: "2%" }}>
				Add Item
			</Typography>
			<Grid
				sx={{
					marginTop: "2%",
					display: "flex",
				}}
			>
				<Grid sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "800px" } }}>
					<Grid item>
						<ListSelect value={itemState.category || ""} error={itemError.category} name="category" label="Item Category" onChange={handleChange} list={categoriesArray} />
					</Grid>
					<Grid item>
						<ListSelect value={itemState.itemType || ""} error={itemError.itemType} name="itemType" label="Item Type" onChange={handleChange} list={itemTypes} />
					</Grid>
					<Grid item>
						<TextInput
							disabled={itemState.itemType === ""}
							type="number"
							value={itemState.itemNumber || ""}
							error={itemError.itemNumber}
							name="itemNumber"
							label="Item Number"
							onChange={handleChange}
						/>
					</Grid>
					<Grid item sx={{ marginLeft: "5px" }}>
						<Typography variant="h7">Team Teaching? (required) </Typography>
						<ToolTipHelp
							title="Team teaching refers to courses that are taught by multiple instructors. Questions that have this setting enabled will be asking for each respective instructor when put on a survey."
							placement="top"
						/>
						<ToggleButtonsAddItem alignment={alignment} handleAlignment={handleAlignment} />
					</Grid>
					<Grid item sx={{ marginTop: "20px" }} ref={containerRef}>
						<TextInput
							multiline
							scrollable="scrollable"
							inputProps={{ maxLength: 750 }}
							minRows={4}
							maxRows={4}
							marginBottom="0px"
							disabled={disabled}
							value={itemState.itemText || ""}
							error={itemError.itemText}
							name="itemText"
							label="Item Text"
							onChange={handleChange}
							helperText={itemTextHelperText}
						/>

						<Button disabled={itemState.itemText === "" || itemChecked} variant="contained" sx={{ display: "flex", width: "100%", marginBottom: "20px" }} onClick={checkForSimilarity}>
							Check
						</Button>
						{similarQuestions.length > 0 ? (
							<Grid
								sx={{
									display: similarQuestions.length > 0 ? "flex" : "none",
									height: "100%",
								}}
							>
								<SimilarItemTable rows={similarQuestions} />
							</Grid>
						) : null}
						{!openCustomScale && (
							<Button variant="outlined" sx={{ marginTop: "10px" }} onClick={() => setOpenCustomScale(!openCustomScale)}>
								Custom Scale
							</Button>
						)}
						{openCustomScale && (
							<CustomScale
								scaleValues={scaleValues}
								setScaleValues={setScaleValues}
								setOpenCustomScale={setOpenCustomScale}
								openCustomScale={openCustomScale}
								scalePoints={scalePoints}
								setScalePoints={setScalePoints}
							/>
						)}
						{!openCustomScale && itemState.scale !== "" && (
							<Grid sx={{ marginTop: "10px" }}>
								<Typography variant="h7">Scale: {itemState.scale}</Typography>
							</Grid>
						)}
					</Grid>

					{itemChecked && !tooSimilar && checked === null ? (
						<Tooltip title="You must indicate whether this question is viable for team teaching or not." placement="bottom">
							<span>
								<Button disabled name="submitButton" onClick={handleSubmit} variant="contained" sx={{ width: "100%", marginTop: "20px" }}>
									Submit
								</Button>
							</span>
						</Tooltip>
					) : (
						<Button disabled={(!itemChecked && !tooSimilar) || openCustomScale} name="submitButton" onClick={handleSubmit} variant="contained" sx={{ width: "100%", marginTop: "28px" }}>
							Submit
						</Button>
					)}
					<Grid sx={{ height: "100px" }}></Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
