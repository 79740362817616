import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Grid, Modal, Typography } from "@mui/material";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useContext, useState, useEffect } from "react";
import { TokenContext } from "../../../../../../context/TokenContext";
import {
	toggleClassReleased,
	toggleGradesSubmitted,
	toggleBulkClassReleased,
	toggleBulkGradesSubmitted,
} from "../../../../../../components/Api/Updates";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	height: 800,
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export function ClassesReleasedModal(props) {
	const token = useContext(TokenContext);
	const [open, setOpen] = React.useState(false);
	const [chosenClasses, setChosenClasses] = useState([]);
	const [reqnum] = useState(props.rowData.reqnum);

	useEffect(() => {
		if (open) {
			let tempChosenClasses = [];
			props.requisitions.forEach((req) => {
				if (req.classes !== null && req.reqnum === props.rowData.reqnum) {
					req.classes.forEach((classObj) => {
						tempChosenClasses.push(classObj);
					});
				}
			});
			setChosenClasses(tempChosenClasses);
		}
	}, [open, props.requisitions, props.rowData.reqnum]);

	const handleClose = (event, reason) => {
		setOpen(false);
	};

	const rowReleased = (row) => {
		let reqnum = props.rowData.reqnum;
		const updatedClasses = [...chosenClasses];
		const index = updatedClasses.findIndex((r) => r.cls_uqid === row.cls_uqid);
		updatedClasses[index].cls_rel = updatedClasses[index].cls_rel === "Y" ? "N" : "Y";
		updatedClasses[index].cls_rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		toggleClassReleased(
			token,
			reqnum,
			updatedClasses[index].cls_uqid,
			updatedClasses[index].cls_rel_date
		);
		setChosenClasses(updatedClasses);
		//get props.requisition that matches reqnum, replace classes with updatedClasses
		let tempRequisitions = [...props.requisitions];
		const reqIndex = tempRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		tempRequisitions[reqIndex].classes = updatedClasses;
		props.setRequisitions(tempRequisitions);
	};

	async function releaseAll() {
		let reqnum = props.rowData.reqnum;
		const updatedClasses = [...chosenClasses];
		let rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		updatedClasses.forEach((classObj) => {
			if (classObj.cls_rel === "N" || classObj.cls_rel === null || classObj.cls_rel === "") {
				classObj.cls_rel = "Y";
			}
		});
		await toggleBulkClassReleased(token, reqnum, rel_date);
		setChosenClasses(updatedClasses);
		let tempRequisitions = [...props.requisitions];
		const reqIndex = tempRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		tempRequisitions[reqIndex].classes = updatedClasses;
		props.setRequisitions(tempRequisitions);
	}

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box
					sx={{
						outline: chosenClasses.length > 100 ? "1px solid red" : "none",
						borderRadius: chosenClasses.length > 100 ? "5px" : "0px",
						margin: "-1px",
					}}
				>
					<GridFooter
						sx={{
							display: "flex",
						}}
					/>
				</Box>
				<Button
					disabled={chosenClasses.every((classObj) => classObj.cls_rel === "Y") ? true : false}
					variant="contained"
					sx={{ p: 1, marginRight: "10px" }}
					color="primary"
					onClick={releaseAll}
				>
					Release All
				</Button>
			</GridFooterContainer>
		);
	};

	const renderReleaseButton = (params) => {
		return (
			<strong>
				<Button
					disabled={params.row.cls_rel === "Y" ? true : false}
					variant="contained"
					sx={{ marginLeft: "5px", padding: "2px" }}
					onClick={() => {
						rowReleased(params.row);
					}}
				>
					Release
				</Button>
			</strong>
		);
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 80,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			type: "string",
			width: 170,
			flex: 1,
		},
		{
			field: "csize",
			headerName: "Size",
			type: "number",
			width: 60,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			type: "string",
			width: 155,
		},
		{
			field: "comb_classes",
			headerName: "Combined",
			type: "string",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "survey_start",
			headerName: "Survey Start",
			type: "date",
			width: 100,
			renderCell: (params) => {
				return dayjs(params.row.survey_start).format("DD/MM/YY");
			},
		},
		{
			field: "survey_end",
			headerName: "Survey End",
			type: "date",
			width: 100,
			renderCell: (params) => {
				return dayjs(params.row.survey_end).format("DD/MM/YY");
			},
		},
		{
			field: "release",
			headerName: " ",
			width: 100,
			renderCell: renderReleaseButton,
		},
	];

	const handleEvent = (params, event) => {
		event.stopPropagation();
	};

	return (
		<div>
			<Button
				variant="contained"
				sx={{ padding: "2px" }}
				color="primary"
				onClick={() => setOpen(true)}
			>
				Release
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography textAlign={"center"} variant="h6" sx={{ marginBottom: "10px" }}>
						Requisition {reqnum}
					</Typography>
					<Typography
						gutterBottom
						textAlign={"center"}
						variant="h7"
						sx={{
							marginBottom: "10px",
							marginLeft: "40px",
							marginRight: "40px",
						}}
					>
						When you are ready to release this survey to TSQS for set up and scheduling, click the
						release button associated with the class, or "Release All". If you have released a class
						by mistake, please contact TSQS for assistance.
					</Typography>
					<DataGrid
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "0.4em",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f1f1f1",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#888",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								background: "#555",
							},
							marginBottom: "50px",
						}}
						disableRowSelectionOnClick={true}
						components={{ Footer: CustomFooter }}
						rows={chosenClasses}
						density="compact"
						columns={columns}
						isRowSelectable={() => false}
						onRowClick={handleEvent}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 5 },
							},
							sorting: {
								sortModel: [{ field: "ctitle", sort: "asc" }],
							},
						}}
						pageSizeOptions={[5, 10]}
						getRowId={(row) => row.cls_uqid}
					/>
					<Button variant="contained" onClick={handleClose}>
						Done
					</Button>
				</Box>
			</Modal>
		</div>
	);
}

export function GradesSubmittedModal(props) {
	const token = useContext(TokenContext);
	const [open, setOpen] = React.useState(false);
	const [chosenClasses, setChosenClasses] = useState([]);
	const [reqnum] = useState(props.rowData.reqnum);

	useEffect(() => {
		if (open) {
			let tempChosenClasses = [];
			props.requisitions.forEach((req) => {
				if (req.classes !== null && req.reqnum === props.rowData.reqnum) {
					req.classes.forEach((classObj) => {
						tempChosenClasses.push(classObj);
					});
				}
			});
			setChosenClasses(tempChosenClasses);
		}
	}, [open, props.requisitions, props.rowData.reqnum]);

	const handleClose = (event, reason) => {
		setOpen(false);
	};

	const submitGrades = (row) => {
		let reqnum = props.rowData.reqnum;
		const updatedClasses = [...chosenClasses];
		const index = updatedClasses.findIndex((r) => r.cls_uqid === row.cls_uqid);
		updatedClasses[index].rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		toggleGradesSubmitted(
			token,
			reqnum,
			updatedClasses[index].cls_uqid,
			updatedClasses[index].rel_date
		);
		setChosenClasses(updatedClasses);
		let tempRequisitions = [...props.requisitions];
		const reqIndex = tempRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		tempRequisitions[reqIndex].classes = updatedClasses;
		props.setRequisitions(tempRequisitions);
	};

	async function releaseAll() {
		let reqnum = props.rowData.reqnum;
		const updatedClasses = [...chosenClasses];
		let rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		updatedClasses.forEach((classObj) => {
			if (classObj.cls_rel === "N" || classObj.cls_rel === null || classObj.cls_rel === "") {
				classObj.cls_rel = "Y";
			}
		});
		await toggleBulkClassReleased(token, reqnum, rel_date);
		setChosenClasses(updatedClasses);
		let tempRequisitions = [...props.requisitions];
		const reqIndex = tempRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		tempRequisitions[reqIndex].classes = updatedClasses;
		props.setRequisitions(tempRequisitions);
	}

	async function releaseAll() {
		let reqnum = props.rowData.reqnum;
		const updatedClasses = [...chosenClasses];
		let rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		updatedClasses.forEach((classObj) => {
			if (classObj.rel_date === null) {
				classObj.rel_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
			}
		});
		toggleBulkGradesSubmitted(token, reqnum, rel_date);
		setChosenClasses(updatedClasses);
		let tempRequisitions = [...props.requisitions];
		const reqIndex = tempRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		tempRequisitions[reqIndex].classes = updatedClasses;
		props.setRequisitions(tempRequisitions);
	}

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box
					sx={{
						outline: chosenClasses.length > 100 ? "1px solid red" : "none",
						borderRadius: chosenClasses.length > 100 ? "5px" : "0px",
						margin: "-1px",
					}}
				>
					<GridFooter
						sx={{
							display: "flex",
						}}
					/>
				</Box>
				<Button
					disabled={chosenClasses.every((classObj) => classObj.rel_date !== null) ? true : false}
					variant="contained"
					sx={{ marginRight: "5px" }}
					color="primary"
					onClick={releaseAll}
				>
					Submit All
				</Button>
			</GridFooterContainer>
		);
	};

	const renderGradesButton = (params) => {
		if (params.row.rel_date !== null) {
			return (
				<strong>
					<Button
						disabled
						variant="contained"
						sx={{ marginLeft: "5px", padding: "2px" }}
						onClick={() => {
							submitGrades(params.row);
						}}
					>
						Submitted
					</Button>
				</strong>
			);
		} else {
			return (
				<strong>
					<Button
						disabled={params.row.rel_date === null ? false : true}
						variant="contained"
						sx={{ marginLeft: "5px", padding: "2px" }}
						onClick={() => {
							submitGrades(params.row);
						}}
					>
						Submit
					</Button>
				</strong>
			);
		}
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 80,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			width: 170,
			flex: 1,
		},
		{
			field: "csize",
			headerName: "Size",
			width: 60,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 155,
		},
		{
			field: "comb_classes",
			headerName: "Combined",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "survey_start",
			headerName: "Survey Start",
			type: "date",
			width: 100,
			renderCell: (params) => {
				return dayjs(params.row.survey_start).format("DD/MM/YY");
			},
		},
		{
			field: "survey_end",
			headerName: "Survey End",
			type: "date",
			width: 100,
			renderCell: (params) => {
				return dayjs(params.row.survey_end).format("DD/MM/YY");
			},
		},
		{
			field: "release",
			headerName: " ",
			width: 100,
			renderCell: renderGradesButton,
		},
	];

	const handleEvent = (params, event) => {
		event.stopPropagation();
	};

	return (
		<div>
			<Button
				variant="contained"
				sx={{ padding: "2px" }}
				color="primary"
				onClick={() => setOpen(true)}
			>
				Grades
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography textAlign={"center"} variant="h6" sx={{ marginBottom: "10px" }}>
						Requisition {reqnum}
					</Typography>
					<Typography
						gutterBottom
						textAlign={"center"}
						variant="h7"
						sx={{
							marginBottom: "10px",
							marginLeft: "40px",
							marginRight: "40px",
						}}
					>
						When you have confirmed that grades are finalized for each course, press the individual
						course "Submit" button or if they have all been finalized "Submit All" to make survey
						results available to instructors. If this option is unavailable, it means that TSS has
						not yet generated reports for this survey.
					</Typography>
					<DataGrid
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "0.4em",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f1f1f1",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#888",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								background: "#555",
							},
							marginBottom: "50px",
						}}
						disableRowSelectionOnClick={true}
						components={{ Footer: CustomFooter }}
						rows={chosenClasses}
						onRowClick={handleEvent}
						density="compact"
						columns={columns}
						isRowSelectable={() => false}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 5 },
							},
							sorting: {
								sortModel: [{ field: "ctitle", sort: "asc" }],
							},
						}}
						pageSizeOptions={[5, 10]}
						getRowId={(row) => row.cls_uqid}
					/>
					<Button variant="contained" onClick={handleClose}>
						Done
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
