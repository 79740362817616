import styles from "../styles/info.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Zoom } from "@mui/material";

function Questiondropdown(props) {
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
					width: "400px",
				}}
			>
				<Tooltip TransitionComponent={Zoom} title={props.tooltipText} placement="right-start">
					<Box sx={{ width: "100%" }}>
						<InputLabel sx={{ display: "flex", justifyContent: "space-between", color: "black" }}>
							{props.question}{" "}
							<HelpIcon
								sx={{
									color: "gray",
								}}
							/>
						</InputLabel>
						<Select
							defaultValue={props.defaultValue}
							value={props.value || ""}
							name={props.name}
							onChange={props.onChange}
							error={props.error}
							helperText={props.helperText}
							variant="outlined" //set width
							sx={{
								color: "theme.palette.secondary",
								width: "100%",
							}}
						>
							{props.listValues.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Box>
				</Tooltip>
			</Box>
		</div>
	);
}

export default Questiondropdown;
