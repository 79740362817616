import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { TextField, Typography, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import CustomScaleUnique from "../../../../components/CustomScaleUnique";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	height: 500,
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
};

const itemTypes = ["RATE", "OPEN"];

export default function AddUniqueItemsModal(props) {
	const [openCustomScale, setOpenCustomScale] = useState(false);
	const [scaleValues, setScaleValues] = useState({ scale1: "", scale2: "" });
	const [scalePoints, setScalePoints] = useState([]);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [itemState, setItemState] = useState({
		itemType: "",
		questionText: "",
		questionTextError: false,
		questionTextHelperText: "",
		questionTextErrorText: "",
		scale: "",
	});

	useEffect(() => {
		if (scaleValues.scale1 !== "" && scaleValues.scale2 !== "") {
			const scaleValuesArray = Object.values(scaleValues);
			let itemScale = "";
			scaleValuesArray.forEach((scale, index) => {
				if (scale.includes(";")) {
					scale = scale.replace(/;/g, "");
				}
				if (index !== scaleValuesArray.length - 1) {
					itemScale += scale + ";";
				} else {
					itemScale += scale;
				}
			});

			setItemState({
				...itemState,
				scale: itemScale,
			});
		}
	}, [scaleValues, itemState]);

	useEffect(() => {
		if (itemState.questionText.length > 0 && itemState.itemType !== "") {
			setReadyToSubmit(true);
		} else {
			setReadyToSubmit(false);
		}
	}, [itemState.questionText, itemState.itemType]);

	const handleChange = (event) => {
		setItemState({
			...itemState,
			[event.target.name]: event.target.value,
		});
	};

	const handleOpen = () => setOpen(true);
	const handleClose = (event, reason) => {
		setOpen(false);
	};

	const handleAddUniqueItem = (event) => {
		event.preventDefault();
		let uniqueCount = 0;
		let uniqueItemNo = 0;
		props.chosenItems.forEach((item) => {
			if (item.sub_type === "U") {
				uniqueCount++;
			}
		});
		if (uniqueCount > 0) {
			uniqueItemNo = 1000 + uniqueCount + 1;
		} else {
			uniqueItemNo = 1001;
		}
		const newItem = {
			itemno: uniqueItemNo,
			itmtyp: itemState.itemType,
			text: itemState.questionText,
			sub_type: "U",
			team_teaching: "Y",
			repeat: false,
			scale: itemState.scale,
		};
		//add to
		props.setChosenItems([...props.chosenItems, newItem]);
		props.setModifiedItems(true);
		if (props.fromModify === true) {
			props.setModified(true);
		}
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleOpen}>
				Add Unique Items
			</Button>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Box
						sx={{
							height: openCustomScale ? "800px" : "400px",
							overflow: "none",
							marginBottom: "20px",
						}}
					>
						<Typography variant="h5" textAlign="center" sx={{}}>
							Add Unique Item
						</Typography>
						<Typography variant="h6" textAlign="center" sx={{ marginTop: "10px" }}>
							Unique items are items that are not added to the permanent catalog of questions. Unique items should be used sparingly, as reference data does not exist for them, and as such, no
							validity exists for them either.
						</Typography>
						<Typography variant="h6" textAlign="center" sx={{ marginTop: "10px", marginBottom: "50px" }}>
							The most common use case for unique items is courses that have a large number of instructors who teach the course in tandem.
						</Typography>
						<Box
							sx={{
								height: "100px",
								borderRadius: "5px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<TextField sx={{ marginLeft: "10px", width: "120px" }} label="Item Type" select SelectProps={{}} onChange={handleChange} name="itemType" value={itemState.itemType || ""}>
								{itemTypes.map((option) => (
									<MenuItem key={option} value={option}>
										{" "}
										{option}{" "}
									</MenuItem>
								))}
							</TextField>
							<TextField
								sx={{ marginLeft: "10px", flex: "1", marginRight: "5px" }}
								label="Question Text"
								error={itemState.questionTextError}
								helperText={itemState.questionTextHelperText}
								onChange={handleChange}
								name="questionText"
							/>
							{!openCustomScale && (
								<Button variant="outlined" sx={{ marginRight: "5px", height: "55px" }} onClick={() => setOpenCustomScale(!openCustomScale)}>
									Custom Scale
								</Button>
							)}
							<Button variant="contained" disabled={!readyToSubmit} sx={{ marginRight: "10px", height: "55px" }} onClick={handleAddUniqueItem}>
								Add
							</Button>
						</Box>
						<Box
							sx={{
								height: "400px",
								width: "100%",
							}}
						>
							{openCustomScale && (
								<CustomScaleUnique
									scaleValues={scaleValues}
									setScaleValues={setScaleValues}
									setOpenCustomScale={setOpenCustomScale}
									openCustomScale={openCustomScale}
									scalePoints={scalePoints}
									setScalePoints={setScalePoints}
								/>
							)}
							{!openCustomScale && itemState.scale !== "" && (
								<Box
									sx={{
										marginTop: "10px",
										height: "55px",
										width: "100%",
									}}
								>
									<Typography variant="h7">Scale: {itemState.scale}</Typography>
								</Box>
							)}
						</Box>
					</Box>

					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							width: "400px",
							alignSelf: "center",
						}}
					>
						Cancel
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
